import React, { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'
import queryString from 'query-string'
import { TTaskRequestProps } from './TaskPayment.model'
import { Button } from '../../../components/UiKit/Button'
import { ReactComponent as DefaultAvatar } from 'assets/images/default_avatar.svg'
import moment from 'moment'
import styles from './TaskPayment.module.scss'
import { activateMetaMask } from '../Web3/web3.thunk'
import toastr, { EToastrTypeMessage } from 'utils/toastr'
import { getProposal, getProposalRequest } from '../Subcontract/Subcontract.actions'
import { createInvoice } from '../Subcontract/Subcontract.thunk'
import Spinner from 'components/Spinner'
import { ETransactionMessage } from 'components/Spinner/types'

const TaskPayment: FC<TTaskRequestProps> = ({
  history,
  task,
  acceptProposalThunk,
  isTransaction,
  changeExecutor,
  proposalInfo,
  loading,
  getTask,
  transferTokensToContract
}) => {
  console.log('🚀 ~ task:', task)
  const words = useSelector((state: TState) => state.global.language.words)
  const dispatch = useDispatch()
  const parsedQuery = queryString.parse(history.location.search)
  const taskId = Number(parsedQuery.taskId)
  const userId = Number(parsedQuery.userId)
  const proposal = Number(parsedQuery.proposal)
  window.scrollTo(0, 0)
  const [isActiveWallet, setIsActiveWallet] = useState(false)
  const [isTaskAccepted, setIsTaskAccepted] = useState(false)

  const handleAcceptProposal = async (): Promise<void> => {
    if (task.acceptProposalSignature) {
      toastr('error', 'Task already accepted')
      return
    }
    try {
      await acceptProposalThunk(task, proposalInfo)
      await changeExecutor(taskId, userId)
      dispatch(createInvoice({ dateOfPayment: moment(Date.now()).format('L'), taskId }))
      await getTask(taskId, true)

      setIsTaskAccepted(true)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getTask(taskId, true)
    dispatch(getProposalRequest())
    if (task && task.taskResponses) {
      const findedProposal = task.taskResponses.find((item: any) => item.id === proposal)
      dispatch(getProposal(findedProposal))
    }
  }, [loading, dispatch])

  const formatDate = proposalInfo && moment(proposalInfo.deadline).format('DD.MM.YYYY')

  const isConnectedToMetaMask = async () => {
    if (window.ethereum) {
      try {
        const accounts = (await window.ethereum.request({ method: 'eth_accounts' })) as string[]
        if (accounts.length > 0) {
          setIsActiveWallet(true)
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    isConnectedToMetaMask()

    if (task.executor) {
      setIsTaskAccepted(true)
    }
  }, [task])

  const handleTransferTokens = async () => {
    try {
      if (!task.executor) {
        toastr('error', 'Task not found')
        return
      }

      await transferTokensToContract(task, history)
    } catch (err) {
      toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
      console.log(err)
    }
  }

  return (
    <>
      {isTransaction && <Spinner isBackground={true} isTransaction={isTransaction} />}
      {!loading && proposalInfo ? (
        <div className={styles.container}>
          <header className={styles.header}>
            <h1>{words['user.header.taskPayment']}</h1>
          </header>
          <div className={styles.main}>
            <section className={styles.task}>
              <h2>{task.title}</h2>
              <div className={styles.line} />
              <div className={styles.description}>
                <div className={styles.performer}>
                  <span>{words['user.taskPayment.performer']}:</span>
                  <div className={styles.info}>
                    <DefaultAvatar className={styles.avatar} />
                    <div className={styles.title}>
                      <h2>{proposalInfo.author.fullName}</h2>
                      <div className={styles.reviews}>
                        {words['user.subcontract.reviews']}
                        <span> -{proposalInfo.author.countNegativeReviews}</span>
                        <span> +{proposalInfo.author.countPositiveReviews}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.amount}>
                  <span>{words['user.taskPayment.amountPayable']}:</span>
                  <div className={styles.total}>
                    <h2>{proposalInfo.budget} TRL</h2>
                  </div>
                </div>
                <div className={styles.term}>
                  <span>{words['user.taskPayment.executionPeriod']}:</span>
                  <div className={styles.period}>
                    <h2>
                      {words['user.taskPayment.upTo']} {formatDate}
                    </h2>
                  </div>
                </div>
              </div>
            </section>
            <section className={styles.buttons}>
              <div className={styles.actionsButton}>
                <Button outline={true} size={'lgs'} onClick={() => history.goBack()}>
                  <span>{words['back']}</span>
                </Button>
                {!isActiveWallet ? (
                  <Button
                    size={'lgs'}
                    children={words['user.taskPayment.activate']}
                    onClick={() => activateMetaMask(setIsActiveWallet)}
                  />
                ) : (
                  <div className={styles.actionButtonsWrapper}>
                    {!isTaskAccepted ? (
                      <Button
                        size={'exlg'}
                        onClick={handleAcceptProposal}
                        children={'Принять предложение'}
                      />
                    ) : (
                      <Button
                        size={'lgs'}
                        onClick={handleTransferTokens}
                        children={words['user.taskPayment.pay']}
                      />
                    )}
                  </div>
                )}
              </div>
            </section>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </>
  )
}

export default TaskPayment
