import React, { FC, useEffect, useMemo, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { IAllUsersList, TProfileProps } from './Profile.model'
import styles from './Profile.module.scss'
import {
  ETransactionHistory,
  EProfileHistory,
  getTransactionToggleBarOptions,
  getToggleBarOptions,
  getFeedbacksToggleBarOptions
} from 'globalConfigs'
import { TSelectOption } from 'components/Select'
import { UserHeader } from 'components/Profile/UserHeader'
import { PersonalInfo } from 'components/Profile/PersonalInfo'
import { CareerInfo } from 'components/Profile/CareerInfo'
import { TimeOffBalance } from '../../../components/Profile/TimeOffBalance'
import { PointsSystem } from 'components/Profile/PointsSystem'
import { Button } from 'components/UiKit/Button'
import { ProfileModalPointsTransferForm } from './components/ProfileModalPointsTransferForm'
import queryString from 'query-string'
import { IToggleBarData, ToggleBar } from 'components/ToggleBar'
// import { Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { getProfileType, setDefaultMainToggleBarState } from 'utils/toggleBarsValue'
import { isAdmin as adminRole, isUser } from 'utils/user'
import { VerificationBlockPass } from '../../../components/Profile/VerificationBlockPass'
import { Feedbacks } from 'components/Profile/Feedbacks'

const Profile: FC<TProfileProps> = ({
  user,
  role,
  timeBalance,
  getBalance,
  usersList,
  getUsersList,
  userInfo,
  getUserProfileInfo,
  changeUserPointsTransfer,
  PSBalanse,
  history,
  loading,
  feedbacks,
  getTasks,
  tasks,
  locale
}) => {
  const parsed = queryString.parse(history.location.search)
  const profileTypeFromQuery = parsed.profileType
  const words = useSelector((state: TState) => state.global.language.words)
  const isAdmin = useMemo(() => adminRole(role), [role])
  const isUserRole = useMemo(() => isUser(role), [role])
  const isEdit = false

  // Local state
  const [selectedProfileType, setSelectedProfileType] = useState<IToggleBarData>(
    setDefaultMainToggleBarState(profileTypeFromQuery, words, isEdit, isAdmin, isUserRole)
  )
  const [openPointsTransferModal, setOpenPointsTransferModal] = useState<boolean>(false)
  const togglePointsTransferModal = () => setOpenPointsTransferModal(!openPointsTransferModal)

  const getUserInfo = () => {
    getUserProfileInfo(user.id, String(profileTypeFromQuery), isAdmin)
  }

  const checkSearchParam = (): React.JSX.Element | void => {
    if (!parsed.type || !profileTypeFromQuery) {
      history.push({
        pathname: history.location.pathname,
        search: `type=${parsed.type ? parsed.type : ETransactionHistory.GOODS}&profileType=${
          profileTypeFromQuery ? profileTypeFromQuery : EProfileHistory.MAIN_INFO
        }`
        // &userId=${user.id}
      })
      return
    }

    if (
      isUserRole &&
      (profileTypeFromQuery === EProfileHistory.POINTS_SYSTEM ||
        profileTypeFromQuery === EProfileHistory.TIME_OFF_BALANCE)
    ) {
      history.push({
        pathname: history.location.pathname,
        search: `type=${parsed.type ? parsed.type : ETransactionHistory.GOODS}&profileType=${
          EProfileHistory.MAIN_INFO
        }`
        // &userId=${user.id}
      })
      return
      // (
      //   <Redirect
      //     to={{
      //       pathname: history.location.pathname,
      //       search: `profileType=${EProfileHistory.MAIN_INFO}&userId=${user.id}`
      //     }}
      //   />
      // )
    }

    getUserInfo()

    if (profileTypeFromQuery && profileTypeFromQuery === EProfileHistory.TIME_OFF_BALANCE) {
      getBalance()
    }
  }

  useEffect(() => {
    checkSearchParam()
  }, [profileTypeFromQuery, parsed.type])

  useEffect(() => {
    if (openPointsTransferModal) {
      getUsersList()
    }
  }, [openPointsTransferModal])

  const usersData: TSelectOption[] = useMemo(
    () =>
      usersList
        .filter((item: IAllUsersList) => item.id !== user.id)
        .map((item: IAllUsersList) => ({
          value: `${item.id}`,
          label: item.fullName
        })),
    [usersList]
  )

  return (
    <>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1>{words['user.header.profile']}</h1>
        </section>
        <section className={styles.block_profile}>
          <UserHeader isSameUser={true} role={role} {...userInfo} />
        </section>

        <div className={styles['main-toggle-bar-wrapper']}>
          <div>
            <ToggleBar
              name="profile-info"
              data={getToggleBarOptions(words, isEdit, isAdmin, false, isUserRole)}
              defaultChecked={selectedProfileType}
              onChange={event => {
                history.push(
                  `${history.location.pathname}?${queryString.stringify({
                    profileType: getProfileType(event.value),
                    type: parsed.type,
                    userId: user.id
                  })}`
                )
                setSelectedProfileType(event)
              }}
            />
          </div>
          {profileTypeFromQuery === EProfileHistory.POINTS_SYSTEM && (
            <div className={styles['points-btns']}>
              <Button
                type="button"
                className={styles['rating']}
                outline={true}
                onClick={() => {
                  history.push('/dashboard/point-system/profile/rating')
                }}
              >
                {words['user.profile.buttons.rating']}
              </Button>
              <Button
                type="button"
                className={styles['send-friend']}
                onClick={togglePointsTransferModal}
              >
                {words['user.profile.buttons.sendPoints']}
              </Button>
            </div>
          )}
        </div>

        <section className={styles['personal-info']}>
          {profileTypeFromQuery === EProfileHistory.MAIN_INFO ? (
            <PersonalInfo isSameUser={true} role={role} {...userInfo} />
          ) : profileTypeFromQuery === EProfileHistory.CAREER ? (
            <CareerInfo {...userInfo} loading={loading} history={history} />
          ) : profileTypeFromQuery === EProfileHistory.TIME_OFF_BALANCE ? (
            <TimeOffBalance balance={timeBalance} history={history} />
          ) : profileTypeFromQuery === EProfileHistory.POINTS_SYSTEM ? (
            <PointsSystem
              transactionToggleBarOptions={getTransactionToggleBarOptions(words)}
              id={String(userInfo && userInfo.id)}
              balance={PSBalanse || 0}
              history={history}
            />
          ) : profileTypeFromQuery === EProfileHistory.FEEDBACKS ? (
            <Feedbacks
              feedbacks={feedbacks}
              history={history}
              feedbackToggleBarOptions={getFeedbacksToggleBarOptions(words)}
              id={String(userInfo && userInfo.id)}
              getTasks={getTasks}
              tasks={tasks}
              locale={locale}
            />
          ) : profileTypeFromQuery === EProfileHistory.VERIFICATION ? (
            <VerificationBlockPass
              status={userInfo.verificationStatus}
              id={userInfo.id}
              getUserInfo={getUserInfo}
            />
          ) : (
            <></>
          )}
        </section>
      </div>

      <ProfileModalPointsTransferForm
        open={openPointsTransferModal}
        togglePointsTransfer={togglePointsTransferModal}
        users={usersData}
        changeUserPointsTransfer={changeUserPointsTransfer}
      />
    </>
  )
}

export default Profile
