import React, { FC, useEffect, useRef, useState } from 'react'
import { jwtDecode } from 'utils/jwt'
import { Redirect, Route, Switch, RouteComponentProps } from 'react-router-dom'
import PrivateRoute from '../PrivatRoute'
import { Dashboard } from './components/Dashboard'
import { Login } from 'screens/auth/Login'
import { mapStateToProps, mapDispatchToProps } from './App.container'
import {
  defaultContextValue,
  isManager,
  isUser,
  isAdmin,
  isSuperAdmin,
  TContextValue,
  UserContext,
  technorelyLanguage,
  isTeamMember
} from 'utils/user'
import { Elanguages } from 'store/global'
import { useDispatch, useSelector } from 'react-redux'
import { Web3Provider } from '@ethersproject/providers'
import { Web3ReactProvider } from '@web3-react/core'
import { NotificationService } from 'socket/libs/services/notifications.socket.service'
import { socketService } from 'socket/socket'
import AuthRoute from 'elements/AuthRoute/AuthRoute.container'
import { TState } from 'store'
import { isEmpty } from 'lodash'
import { addAccessTokenInterceptor } from 'services/api'
import { useAuth } from 'hooks/auth.hook'
import isExpired from 'utils/checkExpAccessToken'
import CallbackRoute from 'elements/AuthRoute/CallbackRoute'
import { LoginLottie } from './components/LoginLottie'

type Props = RouteComponentProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export type TLanguageObject = {
  fetchInfo: (language: string) => void
  languages: string[]
  currentLanguage: string
}

function getLibrary(provider: any): Web3Provider {
  const library = new Web3Provider(provider)
  library.pollingInterval = 12000
  return library
}

export const App: FC<Props> = ({
  currentLanguage,
  error: getLangErr,
  userId,
  decodedToken,
  fetchInformation,
  setAccessToken
}) => {
  let role = ''
  let tokenExp = 0

  if (decodedToken) {
    role = decodedToken.role
    tokenExp = decodedToken.exp
  }

  const [contextValue, setContextValue] = useState<TContextValue>(defaultContextValue)
  const words = useSelector((state: TState) => state.global.language.words)
  const dispatch = useDispatch()
  const intervalRef: any = useRef(null)
  const {
    getAccessTokenSilently,
    logoutWithRedirect,
    isAuthenticated,
    error: errorAuth,
    isLoading: isAuthloading
  } = useAuth()
  const isSignin =
    window.location.href.includes('/signin') || window.location.href.includes('/callback')

  const handleAuthToken = async () => {
    try {
      const token = await getAccessTokenSilently()
      if (token) {
        addAccessTokenInterceptor(token)
        const decodedAccessToken = jwtDecode(token)

        if (!decodedAccessToken || isExpired(decodedAccessToken.exp)) {
          logoutWithRedirect()
        }

        const pay = {
          token,
          decodedToken: decodedAccessToken
        }
        dispatch(setAccessToken(pay))
      }
      if (!token) {
        logoutWithRedirect()
      }
    } catch (err) {
      logoutWithRedirect()
    }
  }

  useEffect(() => {
    if (isAuthenticated && !isSignin && !isAuthloading && tokenExp) {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }

      intervalRef.current = setInterval(() => {
        const isTokenExpired = tokenExp ? isExpired(tokenExp) : true

        if (isTokenExpired) {
          clearInterval(intervalRef.current)
          intervalRef.current = null
          logoutWithRedirect()
        }
      }, 10000)
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
        intervalRef.current = null
      }
    }
  }, [isAuthenticated, isAuthloading, tokenExp, isSignin])

  useEffect(() => {
    if (!isSignin && errorAuth) {
      logoutWithRedirect()
    }

    if (isAuthenticated && !isSignin && !isAuthloading) {
      handleAuthToken()
    }
  }, [isAuthenticated, isAuthloading])

  const fetchInfo = (language: string) => {
    localStorage.setItem(technorelyLanguage, language)
    fetchInformation(language)
  }

  const fetchInformationWithSave = (language: string) => {
    const currentLang = localStorage.getItem(technorelyLanguage)
    if (currentLang) {
      fetchInformation(currentLang)
    } else {
      localStorage.setItem(technorelyLanguage, language)
      fetchInformation(language)
    }
  }

  const languageObject = {
    fetchInfo,
    languages: Object.values(Elanguages),
    currentLanguage
  }

  useEffect(() => {
    fetchInformationWithSave(currentLanguage)
  }, [])

  useEffect(() => {
    let userData
    //Set up SSL on Backend side for socket.io on hosting
    socketService.connect()

    if (role && userId) {
      const notificationService = new NotificationService(socketService.getSocket())
      notificationService.notificationConnect(userId, dispatch)

      setContextValue({
        data: userData,
        superAdmin: isSuperAdmin(role),
        admin: isAdmin(role),
        manager: isManager(role),
        user: isUser(role),
        teamMember: isTeamMember(role)
      } as TContextValue)
    }
  }, [role, userId])

  useEffect(() => {
    if (getLangErr) {
      setTimeout(() => {
        document.location.reload()
      }, 10000)
    }
  }, [getLangErr])

  const content = () => {
    if ((getLangErr || isEmpty(words) || isAuthloading) && !isSignin) {
      return <LoginLottie />
    }

    if (!isSignin && !isAuthloading && !isAuthenticated) {
      logoutWithRedirect()
      return <LoginLottie />
    }

    return (
      <Web3ReactProvider getLibrary={getLibrary}>
        <UserContext.Provider value={contextValue}>
          <Switch>
            {isAuthenticated && role && (
              <PrivateRoute
                path="/dashboard"
                component={Dashboard}
                languageObject={languageObject}
              />
            )}
            <AuthRoute path="/signin" component={Login} />
            <CallbackRoute path="/callback" />

            <Route render={() => <Redirect to="/signin" />} />
          </Switch>
        </UserContext.Provider>
      </Web3ReactProvider>
    )
  }

  return content()
}
