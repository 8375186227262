import React, { FC, useCallback, useEffect } from 'react'
import stylesFromHeader from 'elements/Header/Header.module.scss'
import { HeaderPopUp } from 'elements/Header/components/HeaderPopUp/HeaderPopUp'
import { NotificationPopUpElement } from '../NotificationPopUpElement/NotificationPopUpElement'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'
import { INotificationsItem } from '../../Notifications.model'
import { Dispatch } from 'redux'
import { NavLink } from 'react-router-dom'
import history from 'utils/history'

type Property = {
  totalQuantity: number
  notifications: INotificationsItem[]
  popupClasses?: string
  fetchNotifications: () => (dispatch: Dispatch) => Promise<void>
  deleteNotifications: (id: number) => (dispatch: Dispatch) => Promise<void>
  selectNotification: (notification: INotificationsItem) => void
  removeSelectedNotification: () => {
    type: string
  }
}

const OpenNotificationsPopup: FC<Property> = ({
  totalQuantity,
  notifications,
  popupClasses,
  fetchNotifications,
  deleteNotifications,
  removeSelectedNotification,
  selectNotification
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const dispatch = useDispatch()

  useEffect(() => {
    fetchNotifications()
  }, [])

  const handleDelete = useCallback((id: number) => {
    dispatch(removeSelectedNotification())
    deleteNotifications(id)
  }, [])

  const handleSelect = useCallback((notification: INotificationsItem) => {
    selectNotification(notification)
    history.push('/dashboard/point-system/notifications')
  }, [])

  return (
    <HeaderPopUp
      title={words['component.notifications.title']}
      totalQuantity={totalQuantity}
      position="right"
      popupClasses={popupClasses}
    >
      {({ handleTriggerClick }) => (
        <>
          <div className={stylesFromHeader['popup-content']}>
            {notifications
              .filter(el => !el.isViewed)
              .map(notification => (
                <NotificationPopUpElement
                  notification={notification}
                  key={notification.id}
                  onDelete={handleDelete}
                  onSelect={handleSelect}
                />
              ))}
          </div>
          <div className={stylesFromHeader['popup-footer']}>
            <div className={stylesFromHeader['popup-btn-container']}>
              <NavLink
                className={stylesFromHeader['popup-btn']}
                to="/dashboard/point-system/notifications"
                onClick={handleTriggerClick}
              >
                {words['component.notifications.btnAll']}
              </NavLink>
            </div>
          </div>
        </>
      )}
    </HeaderPopUp>
  )
}

export { OpenNotificationsPopup }
