import React, { FC } from 'react'
import { IOpenBasketPopup } from './OpenBasketPopup.model'
import stylesFromHeader from '../../../../../elements/Header/Header.module.scss'
import { NavLink } from 'react-router-dom'
import { BasketElement } from '../BasketElement/BasketElement'
import { Icons } from 'components/Icons'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import toastr from '../../../../../utils/toastr'
import { Button } from 'components/UiKit/Button'
import { HeaderPopUp } from 'elements/Header/components/HeaderPopUp/HeaderPopUp'

export const OpenBasketPopup: FC<IOpenBasketPopup> = ({
  totalQuantity,
  totalPrice,
  basket,
  addToCart,
  removeFromCart,
  balance
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const displayToastr = () => {
    toastr('info', words['NOT_ENOUGH_TCENTS'])
  }

  return (
    <HeaderPopUp title={words['user.basket']} totalQuantity={totalQuantity} position="left">
      {({ handleTriggerClick }) => (
        <>
          <div className={stylesFromHeader['popup-content']}>
            <BasketElement basket={basket} addToCart={addToCart} removeFromCart={removeFromCart} />
          </div>
          <div className={stylesFromHeader['popup-footer']}>
            <div className={stylesFromHeader['popup-total-amount']}>
              <p>{words['user.basket.commonSum']}</p>
              <p>
                <span>{totalPrice}</span> <Icons icon="pointIcon" />
              </p>
            </div>
            <div className={stylesFromHeader['popup-btn-container']}>
              {balance && balance < totalPrice ? (
                <Button
                  className={stylesFromHeader['popup-btn-not-enough']}
                  onClick={displayToastr}
                >
                  {words['user.basket.checkout']}
                </Button>
              ) : (
                <NavLink
                  className={stylesFromHeader['popup-btn']}
                  to="/dashboard/point-system/basket"
                  onClick={handleTriggerClick}
                >
                  {words['user.basket.checkout']}
                </NavLink>
              )}
            </div>
          </div>
        </>
      )}
    </HeaderPopUp>
  )
}
