import 'react-datepicker/dist/react-datepicker.css'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { TableNew } from '../../../components/UiKit/Table'
import { Button } from 'components/UiKit/Button'
import { TStoreProps } from './Shop.model'
import styles from './Shop.module.scss'
import history from '../../../utils/history'
import { getGoodsColumns } from './Shop.columns'
import queryString from 'query-string'
import { IToggleBarData, ToggleBar } from '../../../components/ToggleBar'
import { Redirect } from 'react-router'
import { useLoadDateFortTable } from '../../../hooks/useLoadDateFortTable'
import { getFilterConfig } from './Shop.filter.config'
import { Filter } from '../../../components/UiKit/Filter'
import { TGoodsCategories } from '../GoodsItem/GoodsItem.model'
import { TSelectOption } from '../../../components/Select'
import { ERowSize } from '../../../globalConfigs'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export const Shop: FC<TStoreProps> = ({
  goods,
  getGoods,
  goodsCategories,
  getGoodsCategories,
  total,
  minPrice,
  maxPrice,
  removeGood,
  restoreGood,
  clearGoodsData
}) => {
  const parsStr = JSON.stringify(queryString.parse(history.location.search))
  const parsed = useMemo(() => queryString.parse(history.location.search), [parsStr])
  const words = useSelector((state: TState) => state.global.language.words)
  const toggleBarOptions = useMemo(
    () => [
      {
        value: 'active',
        title: words['admin.users.toggle.active']
      },
      {
        value: 'deleted',
        title: words['admin.users.toggle.inactive']
      }
    ],
    [words]
  )
  const { setCurrentPage, setLoadedPages } = useLoadDateFortTable(getGoods)
  const [forceUpd, setForceUpd] = useState(Date.now())
  const [selectedType, setSelectedType] = useState<IToggleBarData>(
    parsed.deleted === 'true' ? toggleBarOptions[1] : toggleBarOptions[0]
  )

  const clearAllData = () => {
    clearGoodsData()
    setCurrentPage(1)
    setLoadedPages([])
    setForceUpd(Date.now())
  }

  // Clear data if change filters
  useEffect(() => {
    if (parsStr) {
      clearAllData()
    }
  }, [parsStr])

  useEffect(() => {
    return () => {
      clearAllData()
    }
  }, [])

  useEffect(() => {
    if (parsed.deleted) {
      getGoodsCategories()
    }
  }, [parsed.deleted])

  const categories: TSelectOption[] = useMemo(
    () =>
      goodsCategories.map((item: TGoodsCategories) => ({
        value: `${item.id}`,
        label: item.name
      })),
    [goodsCategories]
  )

  const columns = history.location.search.match(/deleted=true/)
    ? getGoodsColumns(
        {
          onEdit: id => {
            history.push(`${history.location.pathname}/${id}/edit`)
          },
          onRestore: id => restoreGood(id)
        },
        words
      )
    : getGoodsColumns(
        {
          onEdit: id => {
            history.push(`${history.location.pathname}/${id}/edit`)
          },
          onRemove: id => removeGood(id)
        },
        words
      )

  const columnsMemoized: any = useMemo(() => columns, [parsStr])

  const numberOfPeople: TSelectOption[] = useMemo(
    () =>
      // TODO -- need added numberOfPeople from props
      new Array(12).fill(1).map((a, i) => ({
        value: `${a + i}`,
        label: `${a + i}`
      })),
    [goods]
  )

  const handleCreateNewItemBtn = (event: any) => {
    event.preventDefault()
    history.push('/dashboard/point-system/store/create')
  }

  if (!parsed.deleted) {
    return <Redirect to={{ search: 'deleted=false', pathname: history.location.pathname }} />
  }

  return (
    <div className={styles.container}>
      <section className={styles.block_transaction}>
        <header className={styles.header}>
          <h1>{words['sidebar.pointsSystem.market']}</h1>
          <div className={styles['header-btns']}>
            <div className={styles.toggle}>
              <ToggleBar
                name="active-goods-no-active"
                data={toggleBarOptions}
                defaultChecked={selectedType}
                onChange={event => {
                  history.push({
                    pathname: `${history.location.pathname}`,
                    search: `deleted=${event.value === 'deleted'}`
                  })
                  setSelectedType(event)
                }}
              />
            </div>

            <Button className={styles['add-btn']} onClick={handleCreateNewItemBtn}>
              <div>
                <span>{words['admin.productionCalendar.new.buttons.add']}</span>
              </div>
            </Button>
          </div>
        </header>

        <div className={styles['filter-container']}>
          <Filter
            config={getFilterConfig({
              categories,
              minPrice,
              maxPrice,
              numberOfPeople,
              words
            })}
            withButton={true}
            defaultOpened={true}
          />
        </div>

        <TableNew
          key={forceUpd}
          total={total}
          data={goods}
          noData={words['noData']}
          setCurrentPage={setCurrentPage}
          columns={columnsMemoized}
          rowSize={ERowSize.MIDDLE}
        />
      </section>
    </div>
  )
}
