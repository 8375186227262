import React, { FC, useEffect, useState } from 'react'
import { withRouter, Route } from 'react-router-dom'

import { TAuthRoute } from './AuthRoute.model'
import { useAuth } from 'hooks/auth.hook'
import { isAdmin, isSuperAdmin } from 'utils/user'
import { ADMIN_PATH, USER_PATH } from 'elements/App/App.constants'
import history from 'utils/history'
import { jwtDecode } from 'utils/jwt'
import ErrorModal from './components/AuthModal/ErrorModal'
import { addAccessTokenInterceptor } from 'services/api'
import isExpired from 'utils/checkExpAccessToken'
import { LoginLottie } from 'elements/App/components/LoginLottie'
import { useDispatch } from 'react-redux'
import { setAccessToken } from 'screens/auth/Login/Login.actions'

const AuthRoute: FC<TAuthRoute> = ({ component: Component, exact, path, ...rest }) => {
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false)
  const [textError, setTextError] = useState('')

  const dispatch = useDispatch()
  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logoutWithRedirect,
    error: authError
  } = useAuth()

  const handleAuth = async () => {
    if (authError) {
      setIsOpenErrorModal(() => true)
      const err = authError ? authError.message : 'Authentication error'
      setTextError(err)
      return
    }
    if (isAuthenticated && !isLoading) {
      try {
        const token = await getAccessTokenSilently()

        if (!token) {
          logoutWithRedirect()
          return
        }
        const decodedToken: any = jwtDecode(token)
        const roleFromToken = decodedToken.role

        const isExpToken = isExpired(decodedToken.exp)
        if (isExpToken) {
          logoutWithRedirect()
          return
        }

        addAccessTokenInterceptor(token)
        const pay = {
          token,
          decodedToken
        }
        dispatch(setAccessToken(pay))
        // await login(roleFromToken, logout)
        const successPath =
          isSuperAdmin(roleFromToken) || isAdmin(roleFromToken) ? ADMIN_PATH : USER_PATH
        history.push(successPath)
      } catch (err) {
        const errorResponse = (err as any).response

        if (!errorResponse) {
          console.log('auth_err=', err)
          logoutWithRedirect()
          return
        }

        console.log('auth_errorResponse=', errorResponse)

        setTextError(errorResponse.data.message || 'Authentication error')
        setIsOpenErrorModal(true)
      }
    }
  }

  useEffect(() => {
    handleAuth()
  }, [isAuthenticated, isLoading, authError])

  const closeModalErr = () => {
    setIsOpenErrorModal(() => false)
    logoutWithRedirect()
  }

  return (
    <Route
      {...rest}
      exact={exact}
      path={path}
      component={(props: any) => (
        <>
          {isLoading || isAuthenticated ? <LoginLottie /> : <Component {...props} />}
          {/* <AuthModal isOpen={isOpen} signup={signup} onClose={() => setIsOpen(false)} /> */}
          <ErrorModal isOpen={isOpenErrorModal} onClose={closeModalErr} text={textError} />
        </>
      )}
    />
  )
}

export default withRouter(AuthRoute)
