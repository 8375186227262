/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-key */
import React, { FC, useEffect, useState } from 'react'
import {
  Column,
  useBlockLayout,
  useTable,
  useRowSelect,
  UseTableRowProps,
  useExpanded,
  useFlexLayout,
  useResizeColumns
} from 'react-table'
import styles from './Table.module.scss'
import { FixedSizeList } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { ERowSize } from 'globalConfigs'
import cls from 'classnames'

type TGetDataOptions = {
  page: number
}

type TTableProps = {
  columns: Array<Column<any>>
  data: any[]
  total: number
  virtualScroll?: boolean
  getData: (options: TGetDataOptions) => void
  noData?: string
  limit?: number
  onRowClick?: (row: any) => any
  onSelectedRows?: (selected: Array<UseTableRowProps<any>>) => void
  rowSize?: ERowSize
  classNameToRow?: string | undefined
  classNameToBody?: string | undefined
  hiddenColumns?: any
  lang?: any
  tableHeight?: string
}

export const Table: FC<TTableProps> = ({
  columns,
  data,
  virtualScroll = true,
  getData,
  noData = '',
  limit = 10,
  total,
  onRowClick,
  onSelectedRows,
  rowSize = ERowSize.DEFAULT,
  classNameToBody = '',
  classNameToRow = '',
  tableHeight = '50vh'
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    selectedFlatRows
  } = useTable(
    {
      columns,
      data
    },
    useBlockLayout,
    useExpanded,
    useRowSelect
  )

  useEffect(() => {
    if (selectedFlatRows && onSelectedRows) {
      onSelectedRows(selectedFlatRows)
    }
  }, [selectedFlatRows])

  useEffect(() => {
    getData({
      page: currentPage
    })
  }, [currentPage])

  const RenderRow = React.useCallback(
    ({ index, style }: { index: any; style: any }) => {
      const row = rows[index]
      prepareRow(row)

      const rowprops = {
        ...row.getRowProps({
          style
        })
      }
      const { key: rowKey, role: keyRole, style: rowStyle } = rowprops

      return (
        <div
          key={rowKey}
          role={keyRole}
          onClick={(e: any) => {
            if (onRowClick) {
              e.stopPropagation()
              onRowClick(row)
            }
          }}
          style={rowStyle}
          className={cls({
            [styles.bodyTr]: true,
            [classNameToRow]: true
          })}
        >
          {row.cells.map(cell => {
            const cellprops = { ...cell.getCellProps() }
            const { key, role, style: cellStyle } = cellprops
            return (
              <div
                key={key}
                role={role}
                style={cellStyle}
                className={cls({
                  [styles.bodyTd]: true,
                  [classNameToBody]: true
                })}
              >
                {typeof cell.value === 'string' || typeof cell.value === 'number' ? (
                  <div title={`${cell.value}`} className={styles.bodyTdText}>
                    {cell.render('Cell')}
                  </div>
                ) : (
                  cell.render('Cell')
                )}
              </div>
            )
          })}
        </div>
      )
    },
    [prepareRow, rows]
  )

  const isDataEmpty = data.length

  const renderTable = (
    <div {...getTableProps()} className={styles.table}>
      <div className={styles.header}>
        {headerGroups.map((headerGroup, headerGroupIndex) => {
          return (
            <div className={styles.headerTr} key={`${headerGroup.id}_${headerGroupIndex}`}>
              {headerGroup.headers.map(column => {
                const {
                  key: headerKey,
                  role: headerTableRole,
                  style: headerTableStyle
                } = column.getHeaderProps()
                return (
                  <div
                    key={headerKey}
                    role={headerTableRole}
                    className={styles.headerTh}
                    style={{
                      ...headerTableStyle,
                      cursor: isDataEmpty ? 'auto' : 'not-allowed'
                    }}
                  >
                    {isDataEmpty ? (
                      column.render('Header')
                    ) : (
                      <div style={{ pointerEvents: 'none' }}>{column.render('Header')}</div>
                    )}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      <div
        {...getTableBodyProps()}
        className={styles.body}
        style={{ height: tableHeight, width: '100%' }}
      >
        {isDataEmpty ? (
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <FixedSizeList
                height={height}
                itemCount={rows.length}
                itemSize={rowSize}
                width={width}
                onItemsRendered={({ overscanStopIndex }) => {
                  const page = Math.round(overscanStopIndex / limit)
                  const maxPages = Math.ceil(total / limit)

                  if (page < maxPages) {
                    setCurrentPage(page + 1)
                  }
                }}
              >
                {RenderRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        ) : (
          <div className={styles['no-data']}>{noData}</div>
        )}
      </div>
    </div>
  )

  // TODO --- add base table
  return virtualScroll ? renderTable : renderTable
}

export const TeamTable: FC<TTableProps> = ({
  columns,
  data,
  getData,
  noData = '',
  limit = 10,
  total,
  onRowClick,
  onSelectedRows,
  rowSize = ERowSize.DEFAULT,
  classNameToRow = '',
  hiddenColumns,
  lang = 'en',
  tableHeight = '50vh'
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [columnWidth, setColumnWidth] = useState<number>(0)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    selectedFlatRows,
    setHiddenColumns
  } = useTable(
    {
      columns,
      data
    },
    useFlexLayout,
    // useBlockLayout,
    useExpanded,
    useRowSelect,
    useResizeColumns
  )

  useEffect(() => {
    if (selectedFlatRows && onSelectedRows) {
      onSelectedRows(selectedFlatRows)
    }
  }, [selectedFlatRows])

  useEffect(() => {
    getData({
      page: currentPage
    })
  }, [currentPage])

  useEffect(() => {
    if (hiddenColumns) {
      setHiddenColumns(hiddenColumns)
    }
  }, [hiddenColumns])

  const tableWidthAdjustment = lang !== 'en' ? 51 : 91
  let widthColumn = tableWidthAdjustment

  useEffect(() => {
    columns.map((item: any) => {
      if (!hiddenColumns.includes(item.accessor)) {
        if (item.width) {
          widthColumn += item.width
        } else {
          widthColumn += item.minWidth
        }
      }
    })
    setColumnWidth(widthColumn)
  }, [columns, hiddenColumns])

  const RenderRow = React.useCallback(
    ({ index, style }: { index: any; style: any }) => {
      const row = rows[index]
      prepareRow(row)
      const rowprops = {
        ...row.getRowProps({
          style
        })
      }
      const { key: rowKey, role: rowRole, style: rowStyle } = rowprops

      return (
        <div
          key={rowKey}
          role={rowRole}
          onClick={() => {
            if (onRowClick) {
              onRowClick(row)
            }
          }}
          style={rowStyle}
          className={cls({
            [styles.bodyTrTeam]: true,
            [classNameToRow]: true
          })}
        >
          {row.cells.map(cell => {
            const cellprops = { ...cell.getCellProps() }
            const { key, role, style: cellStyle } = cellprops

            return (
              <div key={key} role={role} style={cellStyle} className={styles.bodyTd}>
                {typeof cell.value === 'string' || typeof cell.value === 'number' ? (
                  <div title={`${cell.value}`} className={styles.bodyTdText}>
                    {cell.render('Cell')}
                  </div>
                ) : (
                  cell.render('Cell')
                )}
              </div>
            )
          })}
        </div>
      )
    },
    [prepareRow, rows, currentPage]
  )

  const isDataEmpty = data.length

  const renderTable = (
    <div {...getTableProps()} className={styles.teamTable}>
      <div className={styles.header}>
        {headerGroups.map((headerGroup, headerGroupIndex) => {
          return (
            <div className={styles.headerTrTeam} key={`${headerGroup.id}_${headerGroupIndex}`}>
              {headerGroup.headers.map(column => {
                const {
                  key: headerKey,
                  role: headerTableRole,
                  style: headerTableStyle
                } = column.getHeaderProps()
                return (
                  <div
                    className={styles.headerTh}
                    key={headerKey}
                    role={headerTableRole}
                    style={{
                      ...headerTableStyle,
                      cursor: isDataEmpty ? 'auto' : 'not-allowed'
                    }}
                  >
                    {isDataEmpty ? (
                      column.render('Header')
                    ) : (
                      <div style={{ pointerEvents: 'none' }}>{column.render('Header')}</div>
                    )}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      <div
        {...getTableBodyProps()}
        className={styles.bodyTeam}
        style={{ height: tableHeight, width: '100%' }}
      >
        {isDataEmpty ? (
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <FixedSizeList
                height={height}
                itemCount={rows.length}
                itemSize={rowSize}
                width={width > columnWidth ? width : columnWidth}
                onItemsRendered={({ overscanStopIndex }) => {
                  const page = Math.round(overscanStopIndex / limit)
                  const maxPages = Math.ceil(total / limit)

                  if (page < maxPages) {
                    setCurrentPage(page + 1)
                  }
                }}
              >
                {RenderRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        ) : (
          <div className={styles['no-data']}>{noData}</div>
        )}
      </div>
    </div>
  )

  return renderTable
}

interface ITableProps {
  columns: Array<Column<any>>
  data: any[]
  total: number
  noData?: string
  limit?: number
  onRowClick?: (row: any) => any
  onSelectedRows?: (selected: Array<UseTableRowProps<any>>) => void
  rowSize?: ERowSize
  classNameToRow?: string | undefined
  classNameToBody?: string | undefined
  hiddenColumns?: any
  lang?: any
  tableHeight?: string
  setCurrentPage: any
}

export const TableNew: FC<ITableProps> = ({
  columns,
  data,
  noData = '',
  limit = 10,
  total,
  onRowClick,
  onSelectedRows,
  rowSize = ERowSize.DEFAULT,
  classNameToBody = '',
  classNameToRow = '',
  tableHeight = '50vh',
  setCurrentPage
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    selectedFlatRows
  } = useTable(
    {
      columns,
      data
    },
    useBlockLayout,
    useExpanded,
    useRowSelect
  )

  useEffect(() => {
    console.log('***_TABLE_MOUNTED')
  }, [])

  useEffect(() => {
    if (selectedFlatRows && onSelectedRows) {
      onSelectedRows(selectedFlatRows)
    }
  }, [selectedFlatRows])

  const RenderRow = React.useCallback(
    ({ index, style }: { index: any; style: any }) => {
      const row = rows[index]
      prepareRow(row)

      const rowprops = {
        ...row.getRowProps({
          style
        })
      }
      const { key: rowKey, role: keyRole, style: rowStyle } = rowprops

      return (
        <div
          key={rowKey}
          role={keyRole}
          onClick={(e: any) => {
            if (onRowClick) {
              e.stopPropagation()
              onRowClick(row)
            }
          }}
          style={rowStyle}
          className={cls({
            [styles.bodyTr]: true,
            [classNameToRow]: true
          })}
        >
          {row.cells.map(cell => {
            const cellprops = { ...cell.getCellProps() }
            const { key, role, style: cellStyle } = cellprops
            return (
              <div
                key={key}
                role={role}
                style={cellStyle}
                className={cls({
                  [styles.bodyTd]: true,
                  [classNameToBody]: true
                })}
              >
                {typeof cell.value === 'string' || typeof cell.value === 'number' ? (
                  <div title={`${cell.value}`} className={styles.bodyTdText}>
                    {cell.render('Cell')}
                  </div>
                ) : (
                  cell.render('Cell')
                )}
              </div>
            )
          })}
        </div>
      )
    },
    [prepareRow, rows]
  )

  const isDataEmpty = data.length

  const renderTable = (
    <div {...getTableProps()} className={styles.table}>
      <div className={styles.header}>
        {headerGroups.map((headerGroup, headerGroupIndex) => {
          return (
            <div className={styles.headerTr} key={`${headerGroup.id}_${headerGroupIndex}`}>
              {headerGroup.headers.map(column => {
                const {
                  key: headerKey,
                  role: headerTableRole,
                  style: headerTableStyle
                } = column.getHeaderProps()
                return (
                  <div
                    key={headerKey}
                    role={headerTableRole}
                    className={styles.headerTh}
                    style={{
                      ...headerTableStyle,
                      cursor: isDataEmpty ? 'auto' : 'not-allowed'
                    }}
                  >
                    {isDataEmpty ? (
                      column.render('Header')
                    ) : (
                      <div style={{ pointerEvents: 'none' }}>{column.render('Header')}</div>
                    )}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      <div
        {...getTableBodyProps()}
        className={styles.body}
        style={{ height: tableHeight, width: '100%' }}
      >
        {isDataEmpty ? (
          <AutoSizer>
            {({ height, width }: { height: number; width: number }) => (
              <FixedSizeList
                height={height}
                itemCount={rows.length}
                itemSize={rowSize}
                width={width}
                onItemsRendered={({ overscanStopIndex }) => {
                  const page = Math.round(overscanStopIndex / limit)
                  const maxPages = Math.ceil(total / limit)

                  if (page < maxPages) {
                    setCurrentPage(page + 1)
                  }
                }}
              >
                {RenderRow}
              </FixedSizeList>
            )}
          </AutoSizer>
        ) : (
          <div className={styles['no-data']}>{noData}</div>
        )}
      </div>
    </div>
  )

  return renderTable
}
