import React from 'react'
import { ROLES } from 'globalConfigs'
import { TCellRoles } from './CellRoles.modul'
import cls from 'classnames'
import { useSelector } from 'react-redux'

import styles from './CellRoles.module.scss'
import { TState } from 'store'

export const CellRoles = ({ roles }: TCellRoles) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const getTemplateRole = (role: string) => {
    switch (role) {
      case '1':
        return (
          <div
            key={role}
            className={cls({
              [styles['role']]: true,
              [styles['admin']]: true
            })}
          >
            {ROLES(words)[1]}
          </div>
        )
      case '2':
        return (
          <div
            key={role}
            className={cls({
              [styles['role']]: true,
              [styles['admin']]: true
            })}
          >
            {ROLES(words)[2]}
          </div>
        )
      case '3':
        return (
          <div
            key={role}
            className={cls({
              [styles['role']]: true,
              [styles['manager']]: true
            })}
          >
            {ROLES(words)[3]}
          </div>
        )
      case '6':
        return (
          <div
            key={role}
            className={cls({
              [styles['role']]: true,
              [styles['user']]: true
            })}
          >
            {ROLES(words)[6]}
          </div>
        )
      case '5':
        return (
          <div
            key={role}
            className={cls({
              [styles['role']]: true,
              [styles['manager']]: true
            })}
          >
            {ROLES(words)[5]}
          </div>
        )
      case '4':
      default:
        return (
          <div
            key={role}
            className={cls({
              [styles['role']]: true,
              [styles['user']]: true
            })}
          >
            {ROLES(words)[4]}
          </div>
        )
    }
  }
  return roles.length ? (
    <div className={styles['wrapper-cell-roles']}> {roles.map(getTemplateRole)} </div>
  ) : null
}
