import React, { FC, useState } from 'react'
import styles from './HeaderMenu.module.scss'
import history from 'utils/history'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { useHistory } from 'react-router'
import { ReactComponent as DefaultAvatar } from 'assets/images/default_avatar.svg'
import cls from 'classnames'
import { useAuth } from 'hooks/auth.hook'
import { technorelyLanguage } from 'utils/user'

type THeaderMenuProps = {
  photo: string
  userSide: boolean
  className?: string
  currentLanguage: string
}

export const HeaderMenu: FC<THeaderMenuProps> = ({
  photo,
  userSide,
  className,
  currentLanguage
}) => {
  const { logoutWithRedirect } = useAuth()
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)
  const words = useSelector((state: TState) => state.global.language.words)
  const { location } = useHistory()

  return (
    <div className={cls(styles.container, className)}>
      {open && <div className={styles.overlay} onClick={toggleOpen} />}

      <div className={styles.avatar} onClick={toggleOpen}>
        {photo ? (
          <img className={styles.avatar} src={photo} alt="avatar" />
        ) : (
          <DefaultAvatar className={styles.avatar} />
        )}
      </div>
      {open && (
        <ul className={cls({ [styles.menu]: true, [styles.menu_admin]: !userSide })}>
          {userSide && location.pathname !== '/dashboard/profile' && (
            <li onClick={() => history.push('/dashboard/profile')}>
              {words['user.header.profile']}
            </li>
          )}
          {!userSide && (
            <li onClick={() => history.push('/dashboard/settings')}>
              {words['admin.header.settings']}
            </li>
          )}
          <li
            onClick={() => {
              localStorage.clear()
              localStorage.setItem(technorelyLanguage, currentLanguage)
              logoutWithRedirect()
            }}
          >
            {words['user.header.quit']}
          </li>
        </ul>
      )}
    </div>
  )
}
