import {
  GET_LOCALIZATION_REQUEST,
  GET_LOCALIZATION_SUCCESS,
  GET_LOCALIZATION_ERROR
} from './language.constants'
import { IInitialState, Elanguages } from './language.model'
import { IAction } from 'models'

export const initialState: IInitialState = {
  words: {},
  error: '',
  loading: false,
  languages: {
    [Elanguages.UA]: 'ua',
    [Elanguages.RU]: 'ru',
    [Elanguages.EN]: 'en'
  },
  currentLanguage: Elanguages.RU
}

export const languageReducer = (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_LOCALIZATION_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_LOCALIZATION_SUCCESS:
      return {
        ...state,
        words: action.payload.words,
        currentLanguage: action.payload.language,
        loading: false
      }

    case GET_LOCALIZATION_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    default:
      return state
  }
}
