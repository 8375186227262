/* eslint-disable no-shadow */
import { TSelectOption } from './components/Select'
import { TWords } from 'elements/SideBar/SideBar.config'
import { IToggleBarData } from 'components/ToggleBar'
import { IChekbox } from 'components/UiKit/Table/components/SetColumns'
import {
  ERestAtBreakevenMode,
  EStopLossMode,
  ETakeProfitMode,
  EOrderType,
  EProfitFixationMode,
  EOrderPrice
} from 'screens/client/CryptoBot/components/ControlPanel/ControlPanel.types'
import { IToggleBarOptions } from 'global.model'

export type TConfig = typeof config
export type TMapperNumberString = { [key: number]: string }
export type TMapperStringsNumber = { [key: string]: number }
export type TMultipleSelectOptions = {
  values: number[]
  label: string
}

export const config = {
  trackJsToken: process.env.REACT_APP_TRACK_JS_TOKEN || '476fd1e32d67479e8015c712dc7c41c9',
  trackJsApplication: process.env.REACT_APP_TRACK_JS_APP || 'template',
  isProduction: process.env.NODE_ENV === 'production',
  isDevelopment: process.env.NODE_ENV === 'development',
  isTest: process.env.NODE_ENV === 'test',
  apiUrl: process.env.REACT_APP_API_URL || 'http://localhost:3001/api',
  apiPolygon:
    process.env.REACT_APP_POLYGON_API_URL_SH_ID ||
    // 'http://localhost:8080/http://54.161.192.24:3001/v1/',
    'https://cors-anywhere.herokuapp.com/http://54.161.192.24:3001/v1/',
  googleLogin:
    process.env.REACT_APP_GOOGLE_LOGIN ||
    'https://dev-points-system.technorely.com/backend/api/login',
  dateFormat: 'dd.MM.yyyy',
  fileTypeRegExp: /.+\.(\w+)$/,
  fileNameRegExp: /^.+\/\d+_(.+)\./,
  blockPassClientID: process.env.REACT_APP_BLOCKPASS_CLIENT_ID || '',
  serverUrl: process.env.REACT_APP_SERVER_URL || 'http://localhost:3001',
  authDomain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  authClientId: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  authAudience: process.env.REACT_APP_AUTH0_AUDIENCE || '',
  authScope: process.env.REACT_APP_AUTH0_SCOPE || '',
  walletConnectProjectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || '',
  bscscanApikey: process.env.REACT_APP_BSCSCAN_APIKEY || ''
}
export const DATE_FORMAT_FOR_DETAILS = 'DD.MM.YY'
export const address0 = '0x0000000000000000000000000000000000000000'

export const TYPE_OF_REQUESTS_TIME_OFF: TMapperNumberString = {
  1: 'Panding',
  2: 'Day-off',
  3: 'Absent',
  4: 'Self-paid',
  5: 'Sick',
  6: 'Remote'
}

export const TYPE_OF_CURRENCY: TMapperNumberString = {
  1: 'UAH',
  2: 'USD',
  3: 'TRL'
}

export const STATUS_OF_REQUESTS_TIME_OFF = (words: TWords): TMapperNumberString => {
  return {
    1: words['user.dayOffTracker.table.requestStatus.pending'],
    2: words['user.dayOffTracker.table.requestStatus.approved'],
    3: words['user.dayOffTracker.table.requestStatus.rejected'],
    4: words['user.dayOffTracker.table.requestStatus.canceled'],
    5: words['user.dayOffTracker.table.requestStatus.processed']
  }
}

export const STATUS_OF_REQUESTS_SELECT = (words: TWords): TSelectOption[] => {
  return [
    { value: '1', label: words['user.dayOffTracker.table.requestStatus.pending'] },
    { value: '2', label: words['user.dayOffTracker.table.requestStatus.approved'] },
    { value: '3', label: words['user.dayOffTracker.table.requestStatus.rejected'] }
  ]
}

export const REQUEST_STATUS_COLORS: { [key: number]: string } = {
  1: 'orange',
  2: 'green',
  3: 'red',
  4: 'blue',
  5: 'green'
}
export const TYPE_TRANSACTION = (words: TWords): TMapperNumberString => {
  return {
    1: words['user.profile.table.type.outgoingTransfer'],
    2: words['user.profile.table.type.incomingTransfer']
  }
}

export const ACTIONS_FOR_CHANGE_STATUS_OF_REQUESTS_TIME_OFF: TMapperStringsNumber = {
  approve: 2,
  reject: 3,
  cancel: 4
}

export const FILTER_SELECT_TYPE = (words: TWords): TSelectOption[] => {
  return [
    { value: '@ALL@', label: words['all'] },
    { value: '2', label: words['manager.dayOffTracker.details.requestType.dayOff'] },
    { value: '3', label: words['manager.dayOffTracker.details.requestType.absent'] },
    { value: '5', label: words['manager.dayOffTracker.details.requestType.sick'] },
    { value: '6', label: words['manager.dayOffTracker.details.requestType.remote'] },
    { value: '4', label: words['manager.dayOffTracker.details.requestType.selfPaid'] }
  ]
}

export const FILTER_SELECT_STATUS = (words: TWords): TSelectOption[] => {
  return [
    { value: '@ALL@', label: words['all'] },
    { value: '1', label: words['user.dayOffTracker.table.requestStatus.pending'] },
    { value: '2', label: words['user.dayOffTracker.table.requestStatus.approved'] },
    { value: '3', label: words['user.dayOffTracker.table.requestStatus.rejected'] },
    { value: '4', label: words['user.dayOffTracker.table.requestStatus.canceled'] },
    { value: '5', label: words['user.dayOffTracker.table.requestStatus.processed'] }
  ]
}

export const FILTER_SELECT_ACCAUNT_STATUS = (words: TWords): TSelectOption[] => {
  return [
    { value: '@ALL@', label: words['all'] },
    { value: '1', label: words['admin.table.filter.activated'] },
    { value: '2', label: words['admin.table.filter.deactivated'] }
  ]
}

export const FILTER_SELECT_STATUS_FOR_USER = (words: TWords): TSelectOption[] => {
  return [
    { value: '@ALL@', label: words['all'] },
    { value: '1', label: words['user.dayOffTracker.table.requestStatus.pending'] },
    { value: '2', label: words['user.dayOffTracker.table.requestStatus.approved'] },
    { value: '3', label: words['user.dayOffTracker.table.requestStatus.rejected'] }
  ]
}

export const FILTER_SELECT_INITIATIVES_STATUS = (words: TWords): TSelectOption[] => {
  return [
    { value: '@ALL@', label: words['all'] },
    { value: '1', label: words['user.dayOffTracker.table.requestStatus.pending'] },
    { value: '5', label: words['user.dayOffTracker.table.requestStatus.processed'] }
  ]
}

export const LIFE_PRIORITIES = (words: TWords): TSelectOption[] => {
  return [
    { value: '1', label: words['user.profile.mainInfo.livePriorities.family'] },
    { value: '2', label: words['user.profile.mainInfo.livePriorities.career'] },
    { value: '3', label: words['user.profile.mainInfo.livePriorities.selfDev'] },
    { value: '4', label: words['user.profile.mainInfo.livePriorities.spiritDev'] },
    { value: '5', label: words['user.profile.mainInfo.livePriorities.finances'] },
    { value: '6', label: words['user.profile.mainInfo.livePriorities.charity'] },
    { value: '7', label: words['user.profile.mainInfo.livePriorities.phisicalImprovement'] }
  ]
}

export const AMOUNT_OF_CHILDREN = (words: TWords): TSelectOption[] => {
  return [
    { value: '0', label: words['user.editProfile.notSelected'] },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' }
  ]
}

export const AMOUNT_OF_LEVEL = (words: TWords): TSelectOption[] => {
  return [
    { value: '0', label: words['user.editProfile.notSelected'] },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
    { value: '6', label: '6' },
    { value: '7', label: '7' },
    { value: '8', label: '8' },
    { value: '9', label: '9' },
    { value: '10', label: '10' }
  ]
}

export const TYPES_TRANSACTIONS = (words: TWords): TSelectOption[] => {
  return [
    { value: '1', label: words['user.profile.table.type.outgoingTransfer'] },
    { value: '2', label: words['user.profile.table.type.incomingTransfer'] }
  ]
}

export const FILTER_SELECT_ROLES_FOR_USERS = (words: TWords): TSelectOption[] => {
  return [
    { value: '2', label: words['roles.admin'] },
    { value: '3', label: words['roles.manager'] },
    { value: '4', label: words['roles.user'] }
  ]
}

export const ROLES = (words: TWords): TMapperNumberString => {
  return {
    1: words['roles.superAdmin'],
    2: words['roles.admin'],
    3: words['roles.manager'],
    4: words['roles.user'],
    5: words['roles.ledgenManager'] || 'Ledgen manager',
    6: words['roles.teamMember'] || 'Team member'
  }
}

export const PRODUCT_CALENDAR_TYPE = (words: TWords): TMapperNumberString => {
  return {
    1: words['admin.productionCalendar.new.type.workingDay'],
    2: words['admin.productionCalendar.new.type.holiday']
  }
}

export const FILTER_SELECT_PRODUCT_CALENDAR_TYPE = (words: TWords): TSelectOption[] => {
  return [
    { value: '1', label: words['admin.productionCalendar.new.type.workingDay'] },
    { value: '2', label: words['admin.productionCalendar.new.type.holiday'] }
  ]
}

export enum EPageLimits {
  USERS = 200,
  MANAGERS = 200,
  GOODS = 20,
  ACHIEVEMENTS = 20,
  HISTORY = 10,
  TRANSACTION = 10,
  TEAM = 20,
  PROJECTS = 16
}

export enum EPolygonStatus {
  FALSE = 'false',
  DATA = 'data',
  INPROGRESS = 'inprogress',
  CLAIMS = 'claims',
  TRUE = 'true'
}

export const exchangeSettingsLimits = {
  allocationPercent: { min: 0.001, max: 5 },
  fundingRateLimit: { min: 0.001, max: 1 },
  orderPrice: { min: 0.25, max: 1000 },
  initialBalance: { min: 1, max: 10000000 },
  maxNumPositions: { min: 1, max: 200 },
  minSumForHoldOrder: { min: 11, max: 1000 },
  percentForHold: { min: 0.001, max: 100 },
  percentageForStability: { min: 1, max: 99 },
  maxOrderPrice: { min: 1, max: 2000 },
  takeProfit: { min: 1, max: 100 },
  trailing: { min: 0.1, max: 1e4 },
  trailingTrigger: { min: 0.01, max: 1e4 },
  limitTokenAlive: { min: 0, max: 200 }
}

export const channelsMinMax = {
  autoAveragingTP: { min: 5, max: 2000 },
  closeWhenLessPercent: { min: 1, max: 99 },
  firstStepAveraging: { min: 100, max: 2000 },
  maxAutoAveraging: { min: 0, max: 5 },
  maxChannelAveraging: { min: 0, max: 5 },
  numberOfTakes: { min: 1, max: 7 },
  onlyMoveTPStep: { min: 0, max: 100 },
  otherStepsAveraging: { min: 100, max: 2000 },
  percentagePosToAver: { min: 0, max: 300 },
  periodAfterOMTPStep: { min: 0, max: 90 },
  secondStepAveraging: { min: 100, max: 2000 },
  stopLoss: { min: 5, max: 2000 },
  takeProfit: { min: 5, max: 2000 }
}

export const profitFixationModeSelectOptions = (words: TWords) => [
  { label: words['crypto-bot.standard'], value: EProfitFixationMode.ORDER },
  { label: words['crypto-bot.fixedProfitOnBalance'], value: EProfitFixationMode.TOTAL_BALANCE },
  { label: words['crypto-bot.trail'], value: EProfitFixationMode.TOTAL_BALANCE_TRAILING }
]

export const orderPriceModeSelectOptions = (words: TWords) => [
  { label: words['crypto-bot.betInUSDT'], value: EOrderPrice.FIXED },
  { label: words['crypto-bot.betPercentageOfDeposit'], value: EOrderPrice.ALLOCATION }
]

export const restAtBreakevenModeSelectOptions = [
  { label: 'WITHOUT', value: ERestAtBreakevenMode.WITHOUT },
  { label: 'ALWAYS', value: ERestAtBreakevenMode.ALWAYS }
]

export const stopLossModeModeSelectOptions = [
  { label: 'WITHOUT', value: EStopLossMode.WITHOUT },
  { label: 'MANUAL', value: EStopLossMode.MANUAL },
  { label: 'SIGNAL', value: EStopLossMode.SIGNAL }
]

export const takeProfitModeSelectOptions = [
  { label: 'MANUAL', value: ETakeProfitMode.MANUAL },
  { label: 'WITHOUT', value: ETakeProfitMode.WITHOUT },
  { label: 'SIGNAL', value: ETakeProfitMode.SIGNAL },
  { label: 'MANUAL_MULTIPLE', value: ETakeProfitMode.MANUAL_MULTIPLE }
]

export const openOrderTypeSelectOptions = [
  { label: 'LIMIT', value: EOrderType.LIMIT },
  { label: 'MARKET', value: EOrderType.MARKET }
]

export const closeOrderTypeSelectOptions = [
  { label: 'LIMIT', value: EOrderType.LIMIT },
  { label: 'MARKET', value: EOrderType.MARKET }
]

export const PERIOD_OF_POLLING: number = 30000
export const PAGING_LIMIT: number = 100

export enum EPointsEdit {
  STEP = 100
}

export enum EPointsSettings {
  STEP_FEE_CHARGER = 1,
  STEP_POINTS_FUND = 1000,
  STEP_ACHIEVEMENTS = 1000
}

export enum ERowSize {
  DEFAULT = 50,
  SMALL = 56,
  MIDDLE = 72,
  LARGE = 120
}

export enum EProfileHistory {
  MAIN_INFO = 'main-info',
  CAREER = 'career',
  TIME_OFF_BALANCE = 'time-off-balance',
  POINTS_SYSTEM = 'points-system',
  FEEDBACKS = 'feedbacks',
  VERIFICATION = 'verification'
}

export enum ESubcontractHistory {
  ALL_TASKS = 'all-tasks',
  MY_TASK = 'my-tasks',
  MY_ANSWERS = 'my-answers',
  PERFORMERS = 'performers',
  PLANNING = 'planning',
  DRAFTS = 'drafts',
  INVOICES = 'invoices'
}

export enum ECryptoBotHistory {
  GENERAL = 'general',
  CHART = 'chart',
  POSITIONS = 'positions'
}

export const enum EAdvancedSettingsHistory {
  EXCHANGE = 'exchange',
  CHANNELS = 'channels'
}

export enum ESubcontractAdminHistory {
  ALL_TASKS_ADMIN = 'all-tasks-admin',
  DEACTIVATED = 'deactivated'
}

export enum EVacanciesAdminHistory {
  ALL_VACANCIES_ADMIN = 'all-vacancies-admin',
  ARCHIVED = 'archived'
}

export enum EVerificationAdminHistory {
  ALL_VERIFY = 'all-verify',
  NOT_VERIFY = 'not-verify',
  ACCEPTED_VERIFY = 'accepted-verify'
}

export enum EResponsesHistory {
  ALL_RESPONSES = 'all-responses',
  MY_PERFORMER = 'my-performer',
  ON_INTERVIEW = 'on-interview',
  AGREED = 'agreed'
}

export const enum EContractAddress {
  // FIX_DEAL_ESCROW = '0x3a9c06b30c034A95728d216221Bac3D63AcaD480', //old contract
  // old ones
  // FIX_DEAL_ESCROW = '0x16b7521EB4119766CEC705f7CE815F5563BC1bbd',
  // TRL_TOKEN = '0x93776cF92e1a7a176A5dfEAB4fBECCd5597Cf63B',
  // VOTING = 'votingContractAddress'
  // new ones
  FIX_DEAL_ESCROW = '0xbC6Da153e8B5C08299c47Ea4671f33480A41b57e',
  TRL_TOKEN = '0x5da18d467df8a56331F5296a1D3B3c2f21E5163c',
  VOTING = '0x2F337643bB9B24b0553007412cf2FA7f857d2878'
}

export enum EProjectHistory {
  CURRENT_TEAM = 'current-team',
  VACANCIES = 'vacancies',
  INVOICES = 'invoices',
  TEAM_ARCHIVE = 'team-archive'
}

export enum EEntrepreneurAdminHistory {
  LIST_ENTREPRENEURS = 'list',
  FORMULATED_INVOICES = 'invoices'
}

export enum ETransactionHistory {
  GOODS = 'goods',
  ACHIEVEMENTS = 'initiative',
  TRANSACTIONS = 'transactions'
}

export enum EFeedbackOptions {
  ALL = 'all',
  POSITIVE = 'positive',
  NEGATIVE = 'negative'
}

export enum ETaskCancellationOptions {
  WAITING = 'waiting',
  CANCELLED = 'cancelled'
}

export enum EFaq {
  PROCESSES = 'processes',
  WEEKENDS = 'weekends',
  POINTSSYSTEM = 'pointssystem'
}

export const enum EAdminSettings {
  TASK_CANCELLATION = 'taskCancellation',
  CALENDAR = 'calendar',
  POINTS_SYSTEM = 'pointsSystem',
  SUBCONTRACT = 'subcontract'
}

export const USER_ROLE = 'userRole'
export const ADMIN_ROLE = 'adminRole'

export const requiredOptions: any = {
  'main-info': ['rank', 'position', 'phoneNumber', 'hometown', 'city', 'dateOfBirth'],
  career: ['technologies', 'careerGoal']
}

export const getTransactionToggleBarOptions = (words: TWords): IToggleBarData[] => [
  {
    value: ETransactionHistory.GOODS,
    title: words['user.profile.goodsService']
  },
  {
    value: ETransactionHistory.ACHIEVEMENTS,
    title: words['user.profile.initiatives']
  },
  {
    value: ETransactionHistory.TRANSACTIONS,
    title: words['user.profile.transactions']
  }
]

export const getFeedbacksToggleBarOptions = (words: TWords): IToggleBarData[] => [
  {
    value: EFeedbackOptions.ALL,
    title: words['user.profile.feedbacks.all']
  },
  {
    value: EFeedbackOptions.POSITIVE,
    title: words['user.profile.feedbacks.positives']
  },
  {
    value: EFeedbackOptions.NEGATIVE,
    title: words['user.profile.feedbacks.negatives']
  }
]

export const getTaskCancellationToggleBarOptions = (words: TWords): IToggleBarData[] => [
  {
    value: ETaskCancellationOptions.WAITING,
    title: words['admin.settings.subcontract.pending']
  },
  {
    value: ETaskCancellationOptions.CANCELLED,
    title: words['admin.settings.subcontract.cancelled']
  }
]

export const getToggleBarOptions = (
  words: TWords,
  isEdit: boolean,
  isAdmin: boolean,
  isForeign: boolean = false,
  isUser: boolean
): IToggleBarOptions[] => {
  const toggleBarOptions = [
    {
      value: EProfileHistory.MAIN_INFO,
      title: words['user.profile.togglebar.mainInfo']
    },
    {
      value: EProfileHistory.CAREER,
      title: words['user.profile.togglebar.career']
    }
  ]

  if (isForeign) {
    return toggleBarOptions
  }

  if (isAdmin || (!isUser && !isEdit)) {
    toggleBarOptions.push(
      {
        value: EProfileHistory.TIME_OFF_BALANCE,
        title: words['user.profile.togglebar.daysOff']
      },
      {
        value: EProfileHistory.POINTS_SYSTEM,
        title: words['user.profile.togglebar.pointsSystem']
      }
    )
  }

  if (!isAdmin) {
    toggleBarOptions.push({
      value: EProfileHistory.VERIFICATION,
      title: words['user.profile.verification']
    })
  }

  return toggleBarOptions
}

export const getToggleBarOptionsForSubcontract = (words: TWords): any => {
  const toggleBarOptions = [
    {
      value: ESubcontractHistory.ALL_TASKS,
      title: words['user.subcontract.nav.allTasks']
    },
    {
      value: ESubcontractHistory.MY_TASK,
      title: words['user.subcontract.nav.myTasks']
    },
    {
      value: ESubcontractHistory.MY_ANSWERS,
      title: words['user.subcontract.nav.myResponses']
    },
    {
      value: ESubcontractHistory.PLANNING,
      title: words['user.subcontract.nav.planning']
    },
    {
      value: ESubcontractHistory.DRAFTS,
      title: words['user.subcontract.nav.drafts']
    }
  ]
  return toggleBarOptions
}

export const getToggleBarOptionsForCrypto = (words: TWords): any => {
  const toggleBarOptions = [
    {
      value: ECryptoBotHistory.GENERAL,
      title: words['crypto-bot.generalInfo']
    },
    {
      value: ECryptoBotHistory.CHART,
      title: words['crypto-bot.profitLossChart']
    },
    {
      value: ECryptoBotHistory.POSITIONS,
      title: words['crypto-bot.openPositions']
    }
  ]
  return toggleBarOptions
}

export const getToggleBarOptionsForAdvancedBotSettings = (): any => {
  return [
    {
      value: EAdvancedSettingsHistory.EXCHANGE,
      title: 'Биржи'
    },
    {
      value: EAdvancedSettingsHistory.CHANNELS,
      title: 'Каналы'
    }
  ]
}

export const getToggleBarOptionsForSubcontractTasks = (words: TWords): any => {
  const toggleBarOptions = [
    {
      value: ESubcontractAdminHistory.ALL_TASKS_ADMIN,
      title: words['user.subcontract.nav.allTasks']
    },
    {
      value: ESubcontractAdminHistory.DEACTIVATED,
      title: words['user.subcontract.nav.deactivated']
    }
  ]
  return toggleBarOptions
}

// ADD LOCALIZATION*
export const getToggleBarOptionsForEntrepreneurs = (words: TWords): any => {
  const toggleBarOptions = [
    {
      value: EEntrepreneurAdminHistory.LIST_ENTREPRENEURS,
      title: words['entrepreneur.list']
    },
    {
      value: EEntrepreneurAdminHistory.FORMULATED_INVOICES,
      title: words['entrepreneur.invoices']
    }
  ]
  return toggleBarOptions
}
// add localization*
export const getToggleBarOptionsForVacancies = (words: TWords): any => {
  const toggleBarOptions = [
    {
      value: EVacanciesAdminHistory.ALL_VACANCIES_ADMIN,
      title: words['vacancies.list']
    },
    {
      value: EVacanciesAdminHistory.ARCHIVED,
      title: words['vacancies.archive']
    }
  ]
  return toggleBarOptions
}

export const getToggleBarOptionsForVerifications = (words: TWords): any => {
  const toggleBarOptions = [
    {
      value: EVerificationAdminHistory.ALL_VERIFY,
      title: words['user.subcontract.nav.allTasks']
    },
    {
      value: EVerificationAdminHistory.NOT_VERIFY,
      title: 'Not Verify'
    },
    {
      value: EVerificationAdminHistory.ACCEPTED_VERIFY,
      title: 'Accepted Verify'
    }
  ]
  return toggleBarOptions
}

export const getToggleBarOptionsForSubcontractResponse = (words: TWords): any => {
  const toggleBarOptions = [
    {
      value: EResponsesHistory.ALL_RESPONSES,
      title: words['user.subcontract.myTask.nav.allRes']
    },
    {
      value: EResponsesHistory.ON_INTERVIEW,
      title: words['user.subcontract.myTask.nav.interview']
    },
    {
      value: EResponsesHistory.AGREED,
      title: words['user.subcontract.myTask.nav.agreed']
    },
    {
      value: EResponsesHistory.MY_PERFORMER,
      title: words['user.subcontract.myTask.nav.selectPerformer']
    }
  ]
  return toggleBarOptions
}

export const getToggleBarOptionsForProject = (words: TWords): any => {
  const toggleBarOptions = [
    {
      value: EProjectHistory.CURRENT_TEAM,
      title: words['project.team.current']
    },
    {
      value: EProjectHistory.VACANCIES,
      title: words['project.team.vancies']
    },
    {
      value: EProjectHistory.INVOICES,
      title: words['project.team.invoices']
    },
    {
      value: EProjectHistory.TEAM_ARCHIVE,
      title: words['project.team.archive']
    }
  ]
  return toggleBarOptions
}

export const setToggleBarOptionsTeam = (words: TWords): IToggleBarData[] => {
  return [
    {
      value: 'active',
      title: words['admin.team.toggle.active']
    },
    {
      value: 'deleted',
      title: words['admin.team.toggle.deactivated']
    }
  ]
}

export const checkboxConfig = (words: TWords, deleted: string): IChekbox[] => {
  const checkboxConfigArray = [
    { name: 'fullName', label: words['admin.users.table.filters.userName'], value: true },
    { name: 'position', label: words['user.profile.career.position'], value: true },
    { name: 'rank', label: words['user.profile.career.level'], value: true },
    { name: 'city', label: words['user.profile.mainInfo.location'], value: true },
    {
      name: 'department',
      label: words['user.profile.career.department'],
      value: false
    },
    {
      name: 'typeOfEmployment',
      label: words['user.profile.career.occupation'],
      value: true
    },
    { name: 'workingHoursPerDay', label: words['user.profile.career.workingHours'], value: false },
    { name: 'firstWorkingDay', label: words['user.profile.career.hiringDate'], value: true },
    {
      name: 'probationEndDate',
      label: words['user.profile.career.probationEndDate'],
      value: false
    },
    { name: 'salary', label: words['user.profile.career.salary'], value: true },
    { name: 'dateOfBirth', label: words['user.profile.mainInfo.dob'], value: false },
    { name: 'maritalStatus', label: words['user.profile.mainInfo.martialStatus'], value: false },
    {
      name: 'numberOfChildren',
      label: words['user.profile.mainInfo.numberOfChildren'],
      value: false
    },
    { name: 'englishLevel', label: words['user.profile.career.englishLevel'], value: false }
  ]

  if (deleted === 'true') {
    checkboxConfigArray.splice(
      4,
      0,
      { name: 'terminationDate', label: words['admin.team.terminationDate'], value: true },
      { name: 'terminationInitiator', label: words['admin.team.terminationInitiator'], value: true }
    )
  }

  return checkboxConfigArray
}
