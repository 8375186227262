import React, { FC, ReactNode, useState } from 'react'
import { ReactComponent as IconBasketClose } from '../../../../assets/images/close.svg'
import { ReactComponent as IconBasket } from '../../../../assets/images/basket_icon.svg'
import { Nav, NavItem } from 'reactstrap'
import cls from 'classnames'
import styles from '../../Header.module.scss'
import { Icons } from 'components/Icons'

type Property = {
  title: string
  position: 'left' | 'right'
  totalQuantity: number
  children: ReactNode | ((props: { handleTriggerClick: () => void }) => ReactNode)
  popupClasses?: string
}

export const HeaderPopUp: FC<Property> = ({
  totalQuantity,
  children,
  title,
  position,
  popupClasses
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleTriggerClick = () => {
    if (isOpen) {
      setIsOpen(false)
      document.body.style.overflow = 'auto'
    } else {
      setIsOpen(true)
      document.body.style.overflow = 'hidden'
    }
  }

  const handleClose = () => {
    setIsOpen(false)
    document.body.style.overflow = 'auto'
  }
  return (
    <>
      <Nav className={cls({ [styles['popup-active']]: isOpen })}>
        <NavItem>
          <span onClick={handleTriggerClick}>
            <span className={styles['popup-icon']}>
              {position === 'left' ? <IconBasket /> : <Icons icon="notificationsBell" />}
              <span className={styles.counter}>{totalQuantity}</span>
            </span>
          </span>
        </NavItem>
      </Nav>
      {isOpen && (
        <>
          <div className={styles.mask} onClick={handleClose} />
          <section
            className={cls(
              styles['popup-container'],
              styles[`popup-container-${position}`],
              popupClasses
            )}
          >
            <header className={styles['popup-header']}>
              <h2>{title}</h2>
              <IconBasketClose className={styles['popup-close']} onClick={handleClose} />
            </header>
            {/* {children} */}
            {typeof children === 'function' ? children({ handleTriggerClick }) : children}
          </section>
        </>
      )}
    </>
  )
}
