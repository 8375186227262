export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN'
export const SET_USER_DATA = 'SET_USER_DATA'
export const SIGN_IN_CLEAR = 'SIGN_IN_CLEAR'

export const PULLING_SUCCESS = 'PULLING_SUCCESS'
export const PULLING_ERROR = 'PULLING_ERROR'

export const CHANGE_USER_DAY_OF_BIRTH_REQUEST = 'CHANGE_USER_DAY_OF_BIRTH_REQUEST'
export const CHANGE_USER_DAY_OF_BIRTH_SUCCESS = 'CHANGE_USER_DAY_OF_BIRTH_SUCCESS'
export const CHANGE_USER_DAY_OF_BIRTH_ERROR = 'CHANGE_USER_DAY_OF_BIRTH_ERROR'

export const CHANGE_USER_CITY_REQUEST = 'CHANGE_USER_CITY_REQUEST'
export const CHANGE_USER_CITY_SUCCESS = 'CHANGE_USER_CITY_SUCCESS'
export const CHANGE_USER_CITY_ERROR = 'CHANGE_USER_CITY_ERROR'

export const CHANGE_USER_POINTS_TRANSFER_REQUEST = 'CHANGE_USER_POINTS_TRANSFER_REQUEST'
export const CHANGE_USER_POINTS_TRANSFER_SUCCESS = 'CHANGE_USER_POINTS_TRANSFER_SUCCESS'
export const CHANGE_USER_POINTS_TRANSFER_ERROR = 'CHANGE_USER_POINTS_TRANSFER_ERROR'

export const SET_USER_AVATAR = 'SET_USER_AVATAR'
export const SET_USER_VERIFICATION = 'SET_USER_VERIFICATION'

export const SET_ACCEPT_POLICY = 'SET_ACCEPT_POLICY'

export const SET_SELECTED_ROLE = 'SET_SELECTED_ROLE'
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING'

export const toggleBarOptions = [
  {
    value: '4',
    title: 'User'
  },
  {
    value: '2',
    title: 'Admin'
  }
]
