import { connect } from 'react-redux'
import { App } from './App'
import { TState } from 'store'
import { bindActionCreators, Dispatch } from 'redux'
import { fetchInformation } from 'store/global'
import { withRouter } from 'react-router'
import { setAccessToken } from 'screens/auth/Login/Login.actions'

export const mapStateToProps = (state: TState) => ({
  currentLanguage: state.global.language.currentLanguage,
  error: state.global.language.error,
  userId: state.auth.data.id,
  decodedToken: state.auth.decodedToken
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ fetchInformation, setAccessToken }, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(App))
