import React, { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
// @ts-ignore
import trimCanvas from 'trim-canvas'
import axios from 'axios'
import Modal from 'components/Modal'
import styles from './EditPhotoModal.module.scss'
import { Button } from 'components/UiKit/Button'
import ReactCrop, { centerCrop, Crop, makeAspectCrop } from 'react-image-crop'
import { Field } from 'react-final-form'
import { ReactComponent as AddImgIcon } from 'assets/images/plus_grey.svg'
import { ReactComponent as EditAvatar } from 'assets/images/editBtn.svg'

export const EditPhotoModal: FC<any> = ({ open, closeCropperModal, save, photo }) => {
  const words = useSelector((state: TState) => state.global.language.words)

  const [crop, setCrop] = useState<Crop>()
  const [imgSrc, setImgSrc] = useState('')
  const [fileType, setFileType] = useState<string>('image/jpg')
  const [originalImageFile, setOriginalImageFile] = useState<File | null>(null)

  const aspect = 1

  const getFileFromUrl = async (srcUrl: any): Promise<void> => {
    let fileNameFromUrl: any = srcUrl.split('photos/')
    const nameOrigin = fileNameFromUrl[1]
    if (nameOrigin) {
      const regex = /[0-9]+_/
      fileNameFromUrl = nameOrigin.replace(regex, '')
    } else {
      fileNameFromUrl = 'originFile'
    }
    await axios
      .get(srcUrl, { responseType: 'blob' })
      .then(res => {
        const data = res.data
        const typeFile = data.type
        const file = new File([data], fileNameFromUrl, { type: typeFile })
        setOriginalImageFile(file)
        setFileType(typeFile)
        const reader: any = new FileReader()
        reader.readAsDataURL(file)
        reader.addEventListener('load', () => {
          const rederResult = reader.result
          if (rederResult) {
            return setImgSrc(rederResult.toString())
          }
          return setImgSrc('')
        })
      })
      .catch((e: any) => {
        console.error('Err_from_getFileFromUrl_AXIOS=', e)
      })
  }

  useEffect(() => {
    if (photo) {
      getFileFromUrl(photo)
    }
  }, [photo])

  const onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader: any = new FileReader()
      reader.addEventListener('load', () => {
        const rederResult = reader.result
        if (rederResult) {
          return setImgSrc(rederResult.toString())
        }
        return setImgSrc('')
      })
      const f = e.target.files[0]
      setOriginalImageFile(f)
      setFileType(f.type)
      reader.readAsDataURL(f)
    }
  }

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget
    const defaultCrop: any = centerAspectCrop(width, height, aspect)
    setCrop(defaultCrop)
  }

  const getCroppedImg = (image: any, imgCrop: any, fileName: any) => {
    const canvas: any = document.createElement('canvas')
    const scale: any = image.naturalWidth / image.width
    canvas.width = imgCrop.width * scale
    canvas.height = imgCrop.height * scale
    const ctx: any = canvas.getContext('2d')

    ctx.drawImage(
      image,
      imgCrop.x * scale,
      imgCrop.y * scale,
      imgCrop.width * scale,
      imgCrop.height * scale,
      0,
      0,
      imgCrop.width * scale,
      imgCrop.height * scale
    )

    return new Promise((resolve, reject) => {
      trimCanvas(canvas).toBlob(
        (blob: any) => {
          if (!blob) {
            reject(new Error('Canvas is empty'))
            return
          }
          blob.name = fileName
          resolve(blob)
        },
        fileType,
        1
      )
    })
  }

  const makeClientCrop = async (imgCrop: any) => {
    const img: any = document.querySelector('.ReactCrop__child-wrapper img')

    const fileName = originalImageFile
      ? originalImageFile.name
      : photo
          .split('/')
          .pop()
          .replace(/^[0-9_]+/, '')

    if (imgSrc && imgCrop.width && imgCrop.height) {
      const cropedImage: any = await getCroppedImg(img, imgCrop, fileName)

      const saveObj = {
        croppedBlob: cropedImage,
        originalFile: originalImageFile,
        originalImageSrc: imgSrc,
        fileName
      }

      save(saveObj)
    }
  }
  const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspectProp: number) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: 'px',
          width: mediaWidth
        },
        aspectProp,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    )
  }

  return (
    <Modal isShow={open} onClose={closeCropperModal} className={styles.modal}>
      <header>{words['user.profile.savePhotoPopup.header']}</header>

      <Field
        name={'avatar'}
        render={({ input }) => (
          <div className={styles['cropper-wrapper']}>
            <div
              className={imgSrc ? styles['edit-photo-container'] : styles['add-photo-container']}
            >
              <label htmlFor="avatar">
                {imgSrc && <EditAvatar style={{ cursor: 'pointer' }} />}
                {!imgSrc && <AddImgIcon style={{ cursor: 'pointer' }} />}
                {!imgSrc && (
                  <div className={styles['add-photo']}>{words['user.editProfile.addPhoto']}</div>
                )}
              </label>
              <input {...input} id="avatar" type="file" onChange={onSelectFile} />
            </div>
            <ReactCrop
              aspect={aspect}
              crop={crop}
              onChange={(newCrop: any) => {
                setCrop(newCrop)
              }}
              circularCrop={true}
            >
              <img src={imgSrc} onLoad={onImageLoad} style={{ maxHeight: 'calc(100vh - 200px)' }} />
            </ReactCrop>
          </div>
        )}
      />
      <div className={styles['btn-container']}>
        <Button
          outline={true}
          onClick={() => {
            closeCropperModal()
          }}
        >
          <span>{words['user.profile.savePopup.dontSave']}</span>
        </Button>

        <Button
          onClick={() => {
            makeClientCrop(crop)
            closeCropperModal()
          }}
        >
          <span>{words['user.profile.savePopup.saveBtn']}</span>
        </Button>
      </div>
    </Modal>
  )
}
