import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import styles from './TaskInfoDescription.module.scss'

interface ITaskInfoDescription {
  taskInfo: any
}

interface ISpecializations {
  label: string
  value: string
}

export const TaskInfoDescription: React.FC<ITaskInfoDescription> = ({ taskInfo }) => {
  const words = useSelector((state: TState) => state.global.language.words)

  if (!taskInfo.description) {
    return null
  }

  const positions = taskInfo.specializations.map((pos: ISpecializations) => pos.label).join(', ')

  return (
    <section className={styles['personal-info']}>
      <h3 className={styles.title}>{words['user.subcontract.task.description']}</h3>

      <p className={styles.description}>{taskInfo.description}</p>

      <div className={styles.infoDetailsWrapper}>
        <p className={styles['about-task']}>
          <span className={styles['about-task--color']}>
            {words['user.subcontract.task.specializationOfThePerformer']}:
          </span>{' '}
          {positions}
        </p>

        <p className={styles['about-task']}>
          <span className={styles['about-task--color']}>
            {words['user.subcontract.task.performerLevel']}:
          </span>{' '}
          {words[`${taskInfo.level ? taskInfo.level.name : taskInfo.level}`]}
        </p>
      </div>

      <p className={styles['about-task']}>
        <span className={styles['about-task--color']}>
          {words['user.subcontract.task.technology']}:
        </span>

        <br />

        {taskInfo.description}
      </p>

      <p className={styles['about-task']}>
        <span className={styles['about-task--color']}>
          {words['user.subcontract.task.published']}:
        </span>{' '}
        {moment(taskInfo.publishedAt).format(`DD.MM.YYYY | HH:mm`)}
      </p>
    </section>
  )
}
