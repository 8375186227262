import React, { FC, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styles from './ActionsTableCell.module.scss'
import { Icons } from 'components/Icons'
import { ReactComponent as IconDelete } from 'assets/images/delete_icon.svg'
import { isAdmin, isManager, isUser } from 'utils/user'
import { useUser } from '../../../../../hooks/useUser'
import { ConfirmAction } from 'components/ConfirmAction'
import { ClickAwayListener } from 'components/ClickAwayListener'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export type TActionsTableCell = {
  id: string | number
  status: string
  onClick: (id: number | string) => void
  role: string
  userId: number
  rowData: any
  hideDeleteBtn?: boolean
}

export const ActionsTableCell: FC<TActionsTableCell> = ({
  id,
  onClick,
  role,
  status,
  userId,
  rowData,
  hideDeleteBtn
}) => {
  const { userData } = useUser()
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const toggleConfirm = () => setOpenConfirm(!openConfirm)
  const words = useSelector((state: TState) => state.global.language.words)

  const from = moment(rowData.fromDate, 'YYYY-MM-DD').toISOString()

  const diff = moment().isBefore(from)

  const renderButton = (
    <button
      className={hideDeleteBtn ? styles['none'] : styles['discharge-btn']}
      onClick={toggleConfirm}
      data-id={id}
      style={{ marginLeft: 5, backgroundColor: 'inherit' }}
    >
      <IconDelete />
    </button>
  )

  return (
    <div
      className={
        hideDeleteBtn ? styles['container-for-hidden-btn'] : styles['actions-cell-container']
      }
      onClick={event => {
        event.stopPropagation()
      }}
    >
      {status === 'В ожидании' || status === 'Pending' ? (
        <NavLink to={`/dashboard/requests/${id}/edit`}>
          <Icons icon="updateIcon" />
        </NavLink>
      ) : (
        <Icons icon="updateDisabledIcon" />
      )}

      {status === 'В ожидании' ||
      (status === 'Pending' && isManager(role) && userData.data && userId === userData.data.id) ||
      (status === 'В ожидании' ||
        (status === 'Pending' &&
          isManager(role) &&
          userData.data &&
          userId !== userData.data.id &&
          diff)) ||
      (status === 'В ожидании' || (status === 'Pending' && isUser(role))) ||
      (status === 'В ожидании' || (status === 'Pending' && isAdmin(role))) ||
      (status === 'Утверждено' || (status === 'Approved' && isAdmin(role))) ||
      (status === 'Утверждено' || (status === 'Approved' && isManager(role) && diff)) ? (
        renderButton
      ) : (
        <div style={{ marginLeft: '5px', padding: '1px 6px', backgroundColor: 'inherit' }}>
          {hideDeleteBtn ? '' : <Icons icon="dischargeOutlineDisabled" />}
        </div>
      )}

      {openConfirm && (
        <ClickAwayListener
          style={{ width: 'min-content' }}
          onOutsideClick={() => setOpenConfirm(false)}
        >
          <ConfirmAction
            children={`${words['admin.users.table.buttons.delete']} ?`}
            successLabel={words['user.deleting.successLabel']}
            cancelLabel={words['user.deleting.cancelLabel']}
            onCancel={toggleConfirm}
            onSuccess={() => {
              onClick(id)
              toggleConfirm()
            }}
          />
        </ClickAwayListener>
      )}
    </div>
  )
}
