import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import styles from '../../Task.module.scss'

export const Description: any = ({ taskInfo }: any) => {
  const words = useSelector((state: TState) => state.global.language.words)

  return (
    <>
      {taskInfo.type !== '4' ? (
        <section className={styles['personal-info']}>
          <h2>{words['user.subcontract.task.description']}</h2>
          <p>{taskInfo.description}</p>
          <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '40%' }}>
            <p className={styles['about-task']}>
              <span>{words['user.subcontract.task.specializationOfThePerformer']}:</span>{' '}
              {taskInfo.specializations &&
                taskInfo.specializations.map((pos: any, i: number) => {
                  if (i < taskInfo.specializations.length - 1) {
                    return pos.label + ', '
                  } else {
                    return pos.label
                  }
                })}
            </p>
            <p className={styles['about-task']}>
              <span>{words['user.subcontract.task.performerLevel']}:</span>{' '}
              {words[`${taskInfo.level ? taskInfo.level.name : taskInfo.level}`]}
            </p>
          </div>
          <p className={styles['about-task']}>
            <span>{words['user.subcontract.task.technology']}:</span>
            <br />
            {taskInfo.description}
          </p>
          <p className={styles['about-task']}>
            <span>{words['user.subcontract.task.published']}:</span>{' '}
            {moment(taskInfo.publishedAt).format(`DD.MM.YYYY | HH:mm`)}
          </p>
        </section>
      ) : (
        <section className={styles['personal-info']}>
          <h2>{words['user.subcontract.task.basicInfo']}</h2>
          <p className={styles['about-task']}>
            <span>{words['user.subcontract.task.devStage']}:</span>
            <br />
            {words[`${taskInfo.devStage ? taskInfo.devStage.name : ''}`]}
          </p>
          <p className={styles['about-task']}>
            <span>{words['user.subcontract.task.teamStructure']}:</span>
            <br />
            {taskInfo.teamStructure}
          </p>
          <p className={styles['about-task']}>
            <span>{words['user.subcontract.task.techStack']}:</span>
            <br />
            {taskInfo.techStack}
          </p>
        </section>
      )}
    </>
  )
}
