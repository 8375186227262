/* eslint-disable no-console */
import { Icons } from 'components/Icons'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { config, ESubcontractHistory } from 'globalConfigs'
import moment from 'moment'
import cls from 'classnames'
import React, { FC, useState, MouseEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { TState } from 'store'
import { TTaskInfoProps } from './TaskInfo.model'
import styles from './TaskInfo.module.scss'
import ProposeTaskModal from '../../SubcontractModals/ProposeTaskModal'
import history from 'utils/history'
import queryString from 'query-string'
import { clearTaskList } from 'screens/client/Subcontract/Subcontract.actions'

const TaskInfo: FC<TTaskInfoProps> = ({
  id,
  status,
  deadline,
  path,
  task,
  clearTask,
  signTaskThunk,
  acceptTask,
  accessToken
}) => {
  const parsed = queryString.parse(history.location.search)
  const [modalState, setModalState] = useState(false)
  const words = useSelector((state: TState) => state.global.language.words)
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)
  const answers = parsed.task !== ESubcontractHistory.MY_ANSWERS
  const dispatch = useDispatch()

  function openModal() {
    setModalState(!modalState)
  }

  const handleAcceptTask = (event: MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    acceptTask(task)
  }

  const handleSignTask = async (event: MouseEvent): Promise<void> => {
    event.stopPropagation()
    event.preventDefault()

    try {
      signTaskThunk(task, history)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <NavLink
        exact={true}
        className={styles.onetask}
        to={path ? path : `/dashboard/subcontract/${id}`}
        onClick={() => clearTask()}
      >
        <div className={styles['info-section']}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <h2>{task.title}</h2>
            <div>
              {status === 1 && answers ? (
                <BaseButton
                  children={words['user.subcontract.signAndPublish']}
                  size="percent"
                  className={styles.btn}
                  onClick={handleSignTask}
                />
              ) : status === 2 && answers ? (
                <div className={styles.created}>{words['TASK_STATUS_2']}</div>
              ) : status === 3 && answers ? (
                <div className={styles.created}>{words['TASK_STATUS_3']}</div>
              ) : status === 4 && answers ? (
                <div className={styles.interwiev}>{words['TASK_STATUS_4']}</div>
              ) : status === 5 && answers ? (
                <div className={styles.work}>{words['TASK_STATUS_5']}</div>
              ) : status === 6 && answers ? (
                <div className={styles.canceled}>{words['TASK_STATUS_6']}</div>
              ) : status === 8 && answers ? (
                <div className={styles.canceled}>{words['TASK_STATUS_8']}</div>
              ) : status === 9 && answers ? (
                <BaseButton
                  children={words['user.subcontract.acceptTask']}
                  onClick={handleAcceptTask}
                  size="percent"
                  className={styles.btn}
                />
              ) : status === 'offer' && answers ? (
                <BaseButton
                  children={words['user.taskSelection.suggest']}
                  size="percent"
                  type="submit"
                  className={styles['btn-offer']}
                  onClick={handleSignTask}
                />
              ) : !answers && task.offerRequests && task.offerRequests.length ? (
                <BaseButton
                  children={words['user.subcontract.hire']}
                  size="percent"
                  type="submit"
                  className={styles['btn-request']}
                  onClick={e => {
                    e.preventDefault()
                    openModal()
                  }}
                />
              ) : !answers && task.interviewRequests && task.interviewRequests.length ? (
                <BaseButton
                  children={words['user.subcontract.confirmInterview']}
                  size="percent"
                  type="submit"
                  className={styles['btn-request']}
                  onClick={e => {
                    e.preventDefault()
                    openModal()
                  }}
                />
              ) : status === 'planning' && answers ? (
                <BaseButton
                  children={
                    task.tokenStatus
                      ? words['user.subcontract.task.planning']
                      : words['component.taskInfo.connectCalendar']
                  }
                  size="percent"
                  type="submit"
                  className={styles['btn-request']}
                  onClick={e => {
                    e.preventDefault()
                    if (task.tokenStatus) {
                      history.push(
                        `${history.location.pathname}/planing-calendar?taskId=${task.id}`
                      )
                    } else {
                      // window.location.href = `${
                      //   window.location.href.search(/localhost/i) === 0
                      //     ? 'https://green-points-system.technorely.com/backend/api'
                      //     : 'http://localhost:3001/api'
                      // }/google-calendar/refresh-token?token=${accessToken}&callbackUrl=${encodeURIComponent(
                      //   window.location.href
                      // )}`
                      window.location.href = `${
                        config.apiUrl
                      }/google-calendar/refresh-token?token=${accessToken}&callbackUrl=${encodeURIComponent(
                        window.location.href
                      )}`
                    }
                    dispatch(clearTaskList())
                  }}
                />
              ) : !answers &&
                task.interviewRequests &&
                !task.interviewRequests.length &&
                task.offerRequests &&
                !task.offerRequests.length &&
                status === 5 ? (
                <div className={styles.work}>{words['TASK_STATUS_5']}</div>
              ) : !answers ? (
                <div className={styles.interwiev}>{words['user.subcontract.waiting']}</div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className={styles['info-container']}>
            <p>{task.description}</p>
            <div className={styles['meta-info']}>
              {deadline ? (
                task.fixedDeadline ? (
                  <p style={{ fontWeight: 'bold' }}>
                    <Icons icon="calendarTask" /> {words['user.subcontract.until']}{' '}
                    {moment(task.deadline)
                      .locale(currentLanguage)
                      .format('DD.MM.YYYY')}
                  </p>
                ) : (
                  <div />
                )
              ) : (
                <div style={{ opacity: '0.5', display: 'flex' }}>
                  <p>
                    {moment(task.publishedAt)
                      .locale(currentLanguage)
                      .fromNow()}
                  </p>
                  <p>
                    <Icons icon="popupDialog" />
                    {task.taskResponsesCount} {words['user.subcontract.requests']}
                  </p>
                </div>
              )}

              <div className={styles['about-price']}>
                <h2
                  className={cls({
                    [styles['price']]: true,
                    [styles['orange']]: task.custom
                  })}
                >
                  {task.price} {task.token && task.token.symbol}
                </h2>
                {task.price ? (
                  <p className={styles['job_time']}>
                    {task.type === '4' || task.type === '4'
                      ? `/ ${words['user.subcontract.perMonth']}`
                      : ''}
                    {task.type === '2' ? `/ ${words['user.subcontract.perHour']}` : ''}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </NavLink>
      <ProposeTaskModal toggle={modalState} action={openModal} size={'md'} />
    </>
  )
}

export default TaskInfo
