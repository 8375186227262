import React, { FC, useEffect, useState } from 'react'
import { withRouter, Route } from 'react-router-dom'

import { useAuth } from 'hooks/auth.hook'
import { LOGIN_USER_PATH } from 'elements/App/App.constants'
import history from 'utils/history'
import { jwtDecode } from 'utils/jwt'
import ErrorModal from './components/AuthModal/ErrorModal'
import { addAccessTokenInterceptor } from 'services/api'
import { useDispatch } from 'react-redux'
import { setAccessToken } from 'screens/auth/Login/Login.actions'
import { login } from 'screens/auth/Login/Login.thunx'
import { TCallbackRoute } from './AuthRoute.model'
import { LoginLottie } from 'elements/App/components/LoginLottie'

const CallbackRoute: FC<TCallbackRoute> = ({ exact, path }) => {
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false)
  const [textError, setTextError] = useState('')
  const dispatch = useDispatch()

  const {
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
    logoutWithRedirect,
    error: authError
  } = useAuth()

  const handleAuth = async () => {
    if (authError) {
      setIsOpenErrorModal(() => true)
      const err = authError ? authError.message : 'Authentication error'
      setTextError(err)
      return
    }
    if (isAuthenticated && !isLoading) {
      try {
        const token = await getAccessTokenSilently()
        if (!token) {
          logoutWithRedirect()
          return
        }
        const decodedToken: any = jwtDecode(token)
        const roleFromToken = decodedToken.role

        addAccessTokenInterceptor(token)
        const pay = {
          token,
          decodedToken
        }
        dispatch(setAccessToken(pay))
        await dispatch(login(roleFromToken, logoutWithRedirect))
      } catch (err) {
        const errorResponse = (err as any).response
        setTextError(errorResponse.data.message || 'Authentication error')
        setIsOpenErrorModal(true)
      }
    }
    if (!isAuthenticated && !isLoading) {
      history.push(LOGIN_USER_PATH)
    }
  }

  useEffect(() => {
    handleAuth()
  }, [isAuthenticated, isLoading])

  const closeModalErr = () => {
    setIsOpenErrorModal(() => false)
    logoutWithRedirect()
  }

  return (
    <Route
      exact={exact}
      path={path}
      component={() => (
        <>
          {!isOpenErrorModal && <LoginLottie />}
          <ErrorModal isOpen={isOpenErrorModal} onClose={closeModalErr} text={textError} />
        </>
      )}
    />
  )
}

export default withRouter(CallbackRoute)
