/* eslint-disable no-console */
import React, { FC, useEffect, useMemo, useState } from 'react'
import styles from './SubcontractTasks.module.scss'
import queryString from 'query-string'
import {
  ESubcontractAdminHistory,
  ESubcontractHistory,
  getToggleBarOptionsForSubcontractTasks
} from 'globalConfigs'
import {
  getSubcontractTasksAdminType,
  setDefaultMainToggleBarStateForSubcontractTask
} from 'utils/toggleBarsValue'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'
import { TSubcontractTasksProps } from './SubcontractTasks.model'
import Loading from '../../../components/Spinner'
import { ReactComponent as Prev } from 'assets/images/prev.svg'
import { ReactComponent as Next } from 'assets/images/next.svg'
import Pagination from 'react-js-pagination'
import { finishPoint } from '../../client/Subcontract/components/CreateTask/initialvalues'
import { ToggleBar } from 'components/ToggleBar'
import { Table as UIKitTable } from '../../../components/UiKit/Table'
import { Filter } from '../../../components/UiKit/Filter'
import { Columns } from './SubcontractTasks.columns'
import { getFilterConfig } from './SubcontractTasks.filter.config'
import { getSelectOptions } from 'screens/client/EditProfile/EditProfile.thunx'

const SubcontractTasks: FC<TSubcontractTasksProps> = ({
  getTasks,
  taskArray,
  history,
  getUsers,
  loading,
  itemsCount,
  paginLoading,
  fetchAllTokensThunk,
  tokens
}) => {
  const rank = useSelector((state: TState) => state.client.editProfile.rank)
  const positions = useSelector((state: TState) => state.client.editProfile.positions)
  const dispatch = useDispatch()
  const parsed = queryString.parse(history.location.search)
  const words = useSelector((state: TState) => state.global.language.words)
  const [selectedTaskType, setSelectedTaskType] = useState(
    setDefaultMainToggleBarStateForSubcontractTask(parsed.task, words)
  )
  const limit = 10

  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())

  useEffect(() => {
    dispatch(getSelectOptions())
    fetchAllTokensThunk()
    getTasks(limit, parsed.page, '/tasks/admin', finishPoint(), parsed.clear === 'true')
  }, [])

  const uses = paginLoading
    ? false
    : (params.page === '1' ? !loading : true) && taskArray.length > 0

  const handlePageChange = (pagePag: number) => {
    const url = new URL((window.location as unknown) as string)
    url.searchParams.set('page', String(pagePag))
    window.history.pushState({}, '', url.toString())
    if (parsed.task !== ESubcontractHistory.PERFORMERS) {
      getTasks(10, pagePag, '/tasks/admin', finishPoint(), true, true)
    } else {
      getUsers(10, pagePag, '/users', finishPoint(), true, true)
    }
    window.scrollTo(0, 0)
  }

  const columns = Columns({ role: '2', isOnlyManager: false })
  const columnsMemoized = useMemo(() => columns, [])

  return (
    <>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1>{words['user.header.subcontract']}</h1>
        </section>
        <div className={styles.wrapBar}>
          <ToggleBar
            name="profile-info"
            data={getToggleBarOptionsForSubcontractTasks(words)}
            defaultChecked={selectedTaskType}
            onChange={(event: any) => {
              history.push(
                `${history.location.pathname}?${queryString.stringify({
                  task: getSubcontractTasksAdminType(event.value),
                  page: 1
                  // clear: 'true'
                })}`
              )
              setSelectedTaskType(event)
            }}
          />
        </div>

        <section className={styles['list-filter']}>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '80vh'
              }}
            >
              <Loading />
            </div>
          ) : parsed.task === ESubcontractAdminHistory.DEACTIVATED && uses ? (
            <p>deactivated</p>
          ) : (
            <>
              <Filter
                config={getFilterConfig({
                  positions,
                  role: '4',
                  fromDate: parsed.fromDate,
                  toDate: parsed.toDate,
                  rank,
                  parsed,
                  words,
                  tokens
                })}
                withButton={false}
                defaultOpened={true}
              />
              <UIKitTable
                data={taskArray}
                columns={columnsMemoized}
                total={taskArray.length}
                getData={() => ({})}
                classNameToRow={styles.cursor}
                tableHeight={'40vh'}
                noData={words['user.subcontract.taskListIsEmpty']}
                onRowClick={d => {
                  history.push(
                    `${history.location.pathname}/${
                      d.original.id
                    }?my_task=true&response=all-responses&taskId=${d.original.id}`
                  )
                }}
              />
              <h2 className={styles['task-count']}>
                {`${words['user.subcontract.task.activeTasks']}: `}
                <span>{itemsCount}</span>
              </h2>
            </>
          )}
          <div className={styles.filter}>
            <div className={styles['pagination-container']}>
              <Pagination
                itemsCountPerPage={10}
                totalItemsCount={itemsCount}
                activePage={params.page ? +params.page : 0}
                onChange={handlePageChange}
                hideFirstLastPages={true}
                prevPageText={<Prev />}
                nextPageText={<Next />}
                linkClass={styles['pagination-item']}
                activeLinkClass={styles['pagination-active']}
                disabledClass={styles['pagination-disabled']}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default SubcontractTasks
