import React, { FC, useMemo, useState } from 'react'
import styles from './SideBar.module.scss'
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom'
import AccordionItem from 'components/AccordionItem'
import { mapDispatchToProps, mapStateToProps } from './SideBar.container'
import { TSideBarMenu, TSidebarSubLink, adminSideBar, clientSideBar } from './SideBar.config'
import { Icons } from '../../components/Icons'
import cls from 'classnames'
import storage from 'utils/storage'
import { TLanguageObject } from 'elements/App/App'
import queryString from 'query-string'
import { checkActiveLink } from './libs/helpers/check-active-link'
import { isAdmin, isSuperAdmin } from 'utils/user'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export type TSideBarProps = {
  successPath: string
  onCollapsed: (isCollapsed: boolean) => void
  languageObject?: TLanguageObject
} & RouteComponentProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

const sideBarKey = 'side_bar'

const SideBar: FC<TSideBarProps> = ({
  successPath,
  role,
  history,
  languageObject,
  onCollapsed,
  clearStore
}) => {
  const parsed = queryString.parse(history.location.search)
  const words = useSelector((state: TState) => state.global.language.words)
  const [collapsed, setCollapsed] = useState<boolean>(!!Number(storage.get(sideBarKey)))
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null)

  const mainMenu = useMemo(
    () =>
      (isSuperAdmin(role) || isAdmin(role)
        ? adminSideBar(words)
        : clientSideBar(words, role)) as TSideBarMenu,
    [role, words]
  )

  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
    onCollapsed(!collapsed)
    storage.set(sideBarKey, collapsed ? '0' : '1')
  }

  const menu = Object.keys(mainMenu).map(item => {
    const hover = collapsed ? activeAccordion === item : true
    const itemHasElement = mainMenu[item].content.length > 0
    const isActive = checkActiveLink({
      link: mainMenu[item].commonPath,
      history: history
    })
    return (
      <li
        key={item}
        className={cls(styles.side_bar_li, { [styles.disabled]: mainMenu[item].disabled })}
        onMouseEnter={() => {
          if (itemHasElement) {
            setActiveAccordion(item)
          }
        }}
        onMouseLeave={() => {
          if (itemHasElement) {
            setActiveAccordion(null)
          }
        }}
        onClick={() => {
          if (window.location.search === '?task=all-tasks&page=1&fromPrice=' && !parsed.page) {
            clearStore()
          }
        }}
      >
        <NavLink
          className={cls(styles.side_bar_div, { [styles.active]: isActive })}
          to={mainMenu[item].path}
          exact={mainMenu[item].exact}
        >
          <i className={styles.side_bar_icon}>{mainMenu[item].icon}</i>
          {!collapsed && mainMenu[item].title}
        </NavLink>
        {isActive && itemHasElement && (
          <ul
            className={cls({
              [styles.accordion_ul]: true,
              [styles.collapsed]: collapsed,
              [styles.hide]: !hover
            })}
          >
            {itemHasElement &&
              mainMenu[item].content.map((i: TSidebarSubLink, ind: number) => (
                <AccordionItem
                  isActive={checkActiveLink({
                    link: i.to,
                    history: history,
                    tab: i.tab
                  })}
                  key={ind}
                  to={i.to}
                  title={i.title}
                />
              ))}
          </ul>
        )}
      </li>
    )
  })

  return (
    <div className={cls({ [styles.container]: true, [styles.collapsed]: collapsed })}>
      <NavLink
        to={successPath}
        exact={true}
        activeStyle={{ pointerEvents: 'none' }}
        isActive={(_, location) => location.pathname === successPath}
      >
        <div className={styles.logo}>
          {collapsed ? <Icons icon="logoCollapsed" /> : <Icons icon="logo" />}
        </div>
      </NavLink>

      <div className={styles.menu_wrapper}>
        <ul className={`${styles.side_bar_ul} ${styles.menu}`}>{menu}</ul>
      </div>
      <div className={styles.languages}>
        <div
          className={cls({
            [styles.languageButtonsCollapse]: collapsed,
            [styles.languageButtons]: !collapsed
          })}
        >
          {languageObject &&
            languageObject.languages.map(item => (
              <button
                key={item}
                onClick={() => {
                  languageObject.fetchInfo(item)
                }}
                className={cls({
                  [styles.collapseCurrentLanguage]: item === languageObject.currentLanguage,
                  [styles.collapseLanguage]: item !== languageObject.currentLanguage
                })}
              >
                {item.toUpperCase()}
              </button>
            ))}
        </div>
      </div>

      <button onClick={toggleCollapsed} className={styles.collapse}>
        <Icons icon="doubleArrow" />
      </button>
    </div>
  )
}

export default withRouter(SideBar)
