import axios from 'axios'
import { config } from 'globalConfigs'

export const getContractAbi = async (address: string) => {
  try {
    const axiosConfig = {
      baseURL: 'https://api-testnet.bscscan.com/api',
      params: {
        module: 'contract',
        action: 'getabi',
        address,
        apikey: config.bscscanApikey
      }
    }

    const result = await axios.get('', axiosConfig)

    return JSON.parse(result.data.result)
  } catch (err) {
    console.log(err)
  }
}
