import { exchangeSettingsLimits, channelsMinMax } from 'globalConfigs'
import { createValidationField } from 'utils/createValidationField'
import { messages } from './validationMessages'
import * as Yup from 'yup'

type unstring = string | null
type strNum = string | number

interface IRules {
  email: RegExp
  personName: RegExp
  password: RegExp
  // isPhoneNumber: RegExp
}

class Validation {
  public rules: IRules = {
    email: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
    personName: /^[a-z'-]{3,32}$/i,
    password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/
    // isPhoneNumber: /^(\+38\d{3}\d{3}\d{2}\d{2})?$/
  }

  public required = (errMessage?: string) => (value: strNum): unstring => {
    if (typeof value === 'string') value = value.trim()
    return value ? null : errMessage ? errMessage : messages.required
  }

  public isNumber = (errMessage?: string) => (value: number): unstring => {
    return !isNaN(value) ? null : errMessage ? errMessage : messages.isNumber
  }
  public isInteger = (errMessage?: string) => (value: number): unstring => {
    return !value.toString().match(/\D/gi) ? null : errMessage ? errMessage : messages.isNumber
  }

  public isEmail = (errMessage?: string) => (value: string): unstring =>
    this.rules.email.test(value) ? null : errMessage ? errMessage : messages.email

  public isPassword = (errMessage?: string) => (value: string): unstring =>
    this.rules.password.test(value) ? null : errMessage ? errMessage : messages.password

  public isPasswordConfirm = (errMessage?: string) => (value: string, values: any): unstring => {
    const isValue = this.rules.password.test(value)

    if (!isValue) {
      // Optional
      return messages.password
    }

    if (isValue && values.password !== value) {
      return errMessage ? errMessage : messages.confirmPassword
    }

    return null
  }

  public minValue = (min: number) => (value: string): unstring =>
    value.length >= min ? null : `${messages.minText} ${min}`

  public maxValue = (max: number) => (value: string): unstring =>
    value.length <= max ? null : `${messages.maxText} ${max}`

  public min = (min: number) => (value: string | number): unstring =>
    Number(value) >= min ? null : `${messages.minText} ${min}`

  public max = (max: number) => (value: string | number): unstring =>
    Number(value) <= max ? null : `${messages.maxText} ${max}`

  public composeValidators = (...validators: any[]) => (value: any, values: any) =>
    validators.reduce((error, validator) => error || validator(value, values), null)

  // public isPhoneNumber = (errMessage?: string) => (value: string): unstring =>
  //   this.rules.isPhoneNumber.test(value) ? null : errMessage ? errMessage : messages.isPhoneNumber
}

export const validationSchemaForExchanges = Yup.object().shape({
  allocationPercent: createValidationField(exchangeSettingsLimits.allocationPercent),
  fundingRateLimit: createValidationField(exchangeSettingsLimits.fundingRateLimit),
  orderPrice: createValidationField(exchangeSettingsLimits.orderPrice),
  initialBalance: createValidationField(exchangeSettingsLimits.initialBalance),
  maxNumPositions: createValidationField(exchangeSettingsLimits.maxNumPositions),
  minSumForHoldOrder: createValidationField(exchangeSettingsLimits.minSumForHoldOrder),
  percentForHold: createValidationField(exchangeSettingsLimits.percentForHold),
  percentageForStability: createValidationField(exchangeSettingsLimits.percentageForStability),
  maxOrderPrice: createValidationField(exchangeSettingsLimits.maxOrderPrice),
  takeProfit: createValidationField(exchangeSettingsLimits.takeProfit),
  trailing: createValidationField(exchangeSettingsLimits.trailing),
  trailingTrigger: createValidationField(exchangeSettingsLimits.trailingTrigger),
  limitTokenAlive: createValidationField(exchangeSettingsLimits.limitTokenAlive)
})

export const validationSchemaForChannels = Yup.object().shape({
  autoAveragingTP: createValidationField(channelsMinMax.autoAveragingTP),
  closeWhenLessPercent: createValidationField(channelsMinMax.closeWhenLessPercent),
  firstStepAveraging: createValidationField(channelsMinMax.firstStepAveraging),
  maxAutoAveraging: createValidationField(channelsMinMax.maxAutoAveraging),
  maxChannelAveraging: createValidationField(channelsMinMax.maxChannelAveraging),
  numberOfTakes: createValidationField(channelsMinMax.numberOfTakes),
  otherStepsAveraging: createValidationField(channelsMinMax.otherStepsAveraging),
  percentagePosToAver: createValidationField(channelsMinMax.percentagePosToAver),
  periodAfterOMTPStep: createValidationField(channelsMinMax.periodAfterOMTPStep),
  secondStepAveraging: createValidationField(channelsMinMax.secondStepAveraging),
  stopLoss: createValidationField(channelsMinMax.stopLoss),
  takeProfit: createValidationField(channelsMinMax.takeProfit)
})

const validation = new Validation()
export default validation
