import { IAction } from 'models/index'
import {
  GET_USERS_REQUEST,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  CLEAR_USER_DATA,
  DELETE_USERS_SUCCESS,
  DELETE_USERS_ERROR,
  RESTORE_USERS_SUCCESS,
  RESTORE_USERS_ERROR,
  GET_POSITION_REQUEST,
  GET_POSITION_SUCCESS,
  GET_POSITION_ERROR,
  UPDATE_POINTS_BALANCE_ERROR,
  UPDATE_POINTS_BALANCE_SUCCESS,
  GET_USERS_TO_FILTER_SUCCESS,
  GET_USERS_TO_FILTER_ERROR,
  GET_ALL_TEAM_MEMBERS
} from './Users.constants'
import {
  TGetUsersSuccess,
  TUpdateUserSuccess,
  TDeleteUsersSuccess,
  TRestoreUsersSuccess,
  TUserInitialState
} from './Users.model'

export const initialState: TUserInitialState = {
  results: [],
  total: 0,
  loading: false,
  usersToFilter: [],
  userEdit: {
    loading: false
  },
  positions: {
    loading: false,
    results: []
  },
  allTeamMembers: []
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_USERS_SUCCESS:
      const total = action.payload.total
      if (total < 10) {
        return {
          ...state,
          loading: false,
          results: [...(action as TGetUsersSuccess).payload.results],
          total: action.payload.total
        }
      }
      return {
        ...state,
        loading: false,
        results: [...state.results, ...(action as TGetUsersSuccess).payload.results],
        total: action.payload.total
      }

    case GET_USERS_TO_FILTER_ERROR:
    case GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case UPDATE_USER_REQUEST:
      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          loading: true
        }
      }
    case GET_POSITION_REQUEST:
      return {
        ...state,
        positions: {
          ...state.positions,
          loading: true
        }
      }

    case GET_USERS_TO_FILTER_SUCCESS: {
      return {
        ...state,
        usersToFilter: action.payload.results
      }
    }
    case UPDATE_USER_SUCCESS: {
      const userData = (action as TUpdateUserSuccess).payload

      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          loading: false
        },
        results: state.results.map(item => {
          const { id, reviewerId } = item

          if (id === userData.id) {
            return {
              ...item,
              ...userData,
              reviewerId
            }
          }
          return item
        })
      }
    }

    case UPDATE_POINTS_BALANCE_SUCCESS: {
      // TODO -- will do update user table data without request to server when endpoint ready
      return {
        ...state
      }
    }

    case DELETE_USERS_SUCCESS: {
      const { id } = (action as TDeleteUsersSuccess).payload
      return {
        ...state,
        results: state.results.filter(item => item.id !== id)
      }
    }

    case RESTORE_USERS_SUCCESS: {
      const { id } = (action as TRestoreUsersSuccess).payload
      return {
        ...state,
        results: state.results.filter(item => item.id !== id)
      }
    }
    case GET_POSITION_SUCCESS: {
      return {
        ...state,
        positions: {
          ...state,
          results: action.payload.result,
          loading: true
        }
      }
    }

    case UPDATE_USER_ERROR:
      return {
        ...state,
        userEdit: {
          ...state.userEdit,
          loading: false
        }
      }

    case DELETE_USERS_ERROR:
      return {
        ...state
      }

    case UPDATE_POINTS_BALANCE_ERROR:
      return {
        ...state
      }

    case RESTORE_USERS_ERROR:
      return {
        ...state
      }
    case GET_POSITION_ERROR: {
      return {
        ...state,
        positions: {
          ...state.positions,
          loading: false
        }
      }
    }
    case CLEAR_USER_DATA:
      return {
        // ...initialState
        ...state,
        results: [],
        total: 0,
        loading: false,
        positions: {
          loading: false,
          results: []
        }
      }
    case GET_ALL_TEAM_MEMBERS: {
      return {
        ...state,
        allTeamMembers: action.payload
      }
    }

    default:
      return state
  }
}
