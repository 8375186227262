import React from 'react'
import Lottie from 'lottie-react'
import animationData from './data.json'

const UncontrolledLottie = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    style: {
      height: 200,
      width: 200
    }
  }

  return <Lottie {...defaultOptions} />
}

export default UncontrolledLottie
