/* eslint-disable no-console */

import React, { FC, useEffect, useState } from 'react'
import styles from './Subcontract.module.scss'
import { ToggleBar } from 'components/ToggleBar'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { AllTasksList } from 'components/Subcontract/AllTasksList'
import { MyTasksList } from 'components/Subcontract/MyTasksList/MyTasksList'
import queryString from 'query-string'
import { ESubcontractHistory, getToggleBarOptionsForSubcontract } from 'globalConfigs'
import { getSubcontractType, setDefaultMainToggleBarStateForTask } from 'utils/toggleBarsValue'
import { MyTaskAnswerList } from 'components/Subcontract/MyTaskAnswerList/MyTaskAnswerList'
import { DraftsList } from 'components/Subcontract/DraftsList/DraftsList'
import { PlanningList } from 'components/Subcontract/PlanningList/PlanningList'
import {
  SubcontractTasksFilter,
  TSubcontractFilterValues
} from 'components/Subcontract/SubcontractTasksFilter/SubcontractTasksFilter'
import { ESubcontractTabs } from 'components/Subcontract/subcontract.enum'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { TSubcontractProps } from './Subcontract.model'
import Loading from '../../../components/Spinner'
import { ReactComponent as Prev } from 'assets/images/prev.svg'
import { ReactComponent as Next } from 'assets/images/next.svg'
import Pagination from 'react-js-pagination'
import tasks_tablet from '../../../assets/images/tasks_tablet.svg'
import { finishPoint } from './components/CreateTask/initialvalues'
import ScrollToBtn from 'components/ScrollToBtn/ScrollToBtn'

const Subcontract: FC<TSubcontractProps> = ({
  getTasks,
  taskArray,
  history,
  loading,
  clearStore,
  pageCount,
  page,
  itemsCount,
  paginLoading,
  tokens,
  isTransaction,
  fetchAllTokensThunk,
  user
}) => {
  const parsed = queryString.parse(history.location.search)
  const words = useSelector((state: TState) => state.global.language.words)
  const verifiedStatus = useSelector((state: TState) => state.auth.data.verification)
  const verifiedIdenfyStatus = useSelector((state: TState) => state.auth.data.verificationIdenfy)
  const [selectedTaskType, setSelectedTaskType] = useState(
    setDefaultMainToggleBarStateForTask(parsed.task, words)
  )
  const limit = 10
  const userId = user.id

  const [rerender, setRerender] = useState(false)

  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())

  const urlEndpoint =
    parsed.task === ESubcontractHistory.ALL_TASKS
      ? '/tasks'
      : parsed.task === ESubcontractHistory.MY_TASK
      ? '/tasks/own'
      : parsed.task === ESubcontractHistory.MY_ANSWERS
      ? '/task-responses/own'
      : parsed.task === ESubcontractHistory.PLANNING
      ? '/tasks?planning=false&'
      : parsed.task === ESubcontractHistory.DRAFTS
      ? '/tasks/own?status=1&'
      : parsed.task === ESubcontractHistory.INVOICES
      ? '/tasks/invices'
      : '/tasks'

  useEffect(() => {
    fetchAllTokensThunk()
    if (
      (params.main ? params.main : pageCount !== (parsed.page ? +parsed.page : 0)) ||
      parsed.page !== page
    ) {
      getTasks(limit, parsed.page, urlEndpoint, finishPoint(), parsed.clear === 'true')
    }
  }, [urlEndpoint])

  const choosenArray = taskArray
  const uses = paginLoading
    ? false
    : (params.page === '1' ? !loading : true) && choosenArray.length > 0
  const handlePageChange = (pagePag: number) => {
    const url = new URL((window.location as unknown) as string)
    url.searchParams.set('page', String(pagePag))
    window.history.pushState({}, '', url.toString())
    getTasks(10, pagePag, urlEndpoint, finishPoint(), true, true)

    window.scrollTo(0, 0)
  }

  return (
    <>
      <div className={styles.container}>
        <section className={styles.header}>
          <h1>{words['user.header.subcontract']}</h1>
        </section>
        <div className={styles.wrapBar}>
          <ToggleBar
            name="tasks-info"
            data={getToggleBarOptionsForSubcontract(words)}
            defaultChecked={selectedTaskType}
            onChange={(event: any) => {
              clearStore()
              history.push(
                `${history.location.pathname}?${queryString.stringify({
                  task: getSubcontractType(event.value),
                  page: 1
                })}`
              )
              setSelectedTaskType(event)
            }}
          />
          {parsed.task === 'my-answers' || parsed.task === 'performers' ? null : (
            <div className={styles.btn}>
              <BaseButton
                children={
                  verifiedStatus == 'true' || verifiedIdenfyStatus == 'true'
                    ? words['user.subcontract.createTask']
                    : 'Verification'
                }
                type="submit"
                size="lgs"
                onClick={
                  verifiedStatus == 'true' || verifiedIdenfyStatus == 'true'
                    ? () =>
                        history.push(
                          `/dashboard/subcontract/create-task?userId=:${userId}&redirectUrl=${encodeURIComponent(
                            `${window.location.pathname}${window.location.search}`
                              .slice(
                                1,
                                `${window.location.pathname}${window.location.search}`.length
                              )
                              .replace(/page=\d*/gi, 'page=1')
                              .replace(/&page=\d*/gi, '&page=1')
                          )}`
                        )
                    : () => history.push(`/dashboard/subcontract/verification`)
                }
              />
            </div>
          )}
        </div>

        <section className={styles['list-filter']}>
          <ScrollToBtn refsik={window} />
          {parsed.task === ESubcontractHistory.ALL_TASKS && uses ? (
            <AllTasksList urlEndpoint={urlEndpoint} />
          ) : parsed.task === ESubcontractHistory.MY_TASK && uses ? (
            <MyTasksList
              btnQuery={parsed.task}
              getTasks={getTasks}
              urlEndpoint={urlEndpoint}
              isTransaction={isTransaction}
            />
          ) : parsed.task === ESubcontractHistory.MY_ANSWERS && uses ? (
            <MyTaskAnswerList
              btnQuery={parsed.task}
              getTasks={getTasks}
              urlEndpoint={urlEndpoint}
            />
          ) : parsed.task === ESubcontractHistory.PLANNING && uses ? (
            <PlanningList btnQuery={parsed.task} getTasks={getTasks} urlEndpoint={urlEndpoint} />
          ) : parsed.task === ESubcontractHistory.DRAFTS && uses ? (
            <DraftsList
              btnQuery={parsed.task}
              setRerender={setRerender}
              rerender={rerender}
              getTasks={getTasks}
              urlEndpoint={urlEndpoint}
              isTransaction={isTransaction}
            />
          ) : choosenArray.length === 0 && loading ? (
            <div className={styles.loadingWrapper}>
              <Loading />
            </div>
          ) : choosenArray.length === 0 ? (
            <div className={styles['empty-list']}>
              <img src={tasks_tablet} alt="logo" />
              <h2>{words['user.subcontract.taskListIsEmpty']}</h2>
            </div>
          ) : (
            <div className={styles.loadingWrapper}>
              <Loading />
            </div>
          )}
          <div className={styles.filter}>
            <SubcontractTasksFilter
              tokens={tokens}
              activeTab={
                parsed.task === ESubcontractHistory.ALL_TASKS
                  ? ESubcontractTabs.ALL_TASKS
                  : parsed.task === ESubcontractHistory.MY_TASK
                  ? ESubcontractTabs.MY_TASKS
                  : parsed.task === ESubcontractHistory.MY_ANSWERS
                  ? ESubcontractTabs.MY_RESPONDS
                  : parsed.task === ESubcontractHistory.DRAFTS
                  ? ESubcontractTabs.DRAFTS
                  : ESubcontractTabs.ALL_TASKS
              }
              onSubmit={function(values: TSubcontractFilterValues) {
                clearStore()
                history.push(
                  `${history.location.pathname}?${queryString.stringify({
                    ...parsed,
                    page: 1,
                    category:
                      values.category && values.category.value !== '@ALL@'
                        ? values.category.value
                        : '',
                    currency: values.currency.value !== '@ALL@' ? values.currency.value : '',
                    level: values.level.value !== '@ALL@' ? values.level.value : '',
                    specialization:
                      values.specialization.value !== '@ALL@' ? values.specialization.value : '',
                    fromPrice: values.budgetFrom,
                    toPrice: values.budgetTo,
                    fromDate: values.publicationDateFrom,
                    toDate: values.publicationDateTo,
                    status:
                      values.status && values.status.value !== '@ALL@' ? values.status.value : '',
                    technologies: values.technology,
                    isClear: true
                  })}`
                )
              }}
            />
            <div className={styles['pagination-container']}>
              <Pagination
                itemsCountPerPage={10}
                totalItemsCount={itemsCount}
                activePage={params.page ? +params.page : 0}
                onChange={handlePageChange}
                hideFirstLastPages={true}
                prevPageText={<Prev />}
                nextPageText={<Next />}
                linkClass={styles['pagination-item']}
                activeLinkClass={styles['pagination-active']}
                disabledClass={styles['pagination-disabled']}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Subcontract
