import { IToggleBarData } from 'components/ToggleBar'
import { TWords } from 'elements/SideBar/SideBar.config'
import { IToggleBarOptions } from 'global.model'
import {
  EProfileHistory,
  ETransactionHistory,
  ESubcontractHistory,
  EResponsesHistory,
  getToggleBarOptions,
  getToggleBarOptionsForSubcontract,
  getToggleBarOptionsForSubcontractResponse,
  ESubcontractAdminHistory,
  getToggleBarOptionsForSubcontractTasks,
  EVerificationAdminHistory,
  getToggleBarOptionsForVerifications,
  EProjectHistory,
  getToggleBarOptionsForProject,
  getToggleBarOptionsForEntrepreneurs,
  EEntrepreneurAdminHistory,
  EFeedbackOptions,
  getToggleBarOptionsForVacancies,
  EVacanciesAdminHistory,
  ECryptoBotHistory,
  getToggleBarOptionsForCrypto,
  ETaskCancellationOptions,
  getToggleBarOptionsForAdvancedBotSettings,
  EAdvancedSettingsHistory
} from 'globalConfigs'

export const getPSType = (value: string): string => {
  switch (value) {
    case ETransactionHistory.GOODS:
      return ETransactionHistory.GOODS
    case ETransactionHistory.ACHIEVEMENTS:
      return ETransactionHistory.ACHIEVEMENTS
    case ETransactionHistory.TRANSACTIONS:
      return ETransactionHistory.TRANSACTIONS
    default:
      return ETransactionHistory.GOODS
  }
}

export const getFeedbacksType = (value: string): string => {
  switch (value) {
    case EFeedbackOptions.ALL:
      return EFeedbackOptions.ALL
    case EFeedbackOptions.POSITIVE:
      return EFeedbackOptions.POSITIVE
    case EFeedbackOptions.NEGATIVE:
      return EFeedbackOptions.NEGATIVE
    default:
      return EFeedbackOptions.ALL
  }
}

export const getTaskCancellationType = (value: string): string => {
  switch (value) {
    case ETaskCancellationOptions.WAITING:
      return ETaskCancellationOptions.WAITING
    case ETaskCancellationOptions.CANCELLED:
      return ETaskCancellationOptions.CANCELLED
    default:
      return ETaskCancellationOptions.WAITING
  }
}

export const getProfileType = (value: string): string => {
  switch (value) {
    case EProfileHistory.MAIN_INFO:
      return EProfileHistory.MAIN_INFO
    case EProfileHistory.CAREER:
      return EProfileHistory.CAREER
    case EProfileHistory.TIME_OFF_BALANCE:
      return EProfileHistory.TIME_OFF_BALANCE
    case EProfileHistory.POINTS_SYSTEM:
      return EProfileHistory.POINTS_SYSTEM
    case EProfileHistory.FEEDBACKS:
      return EProfileHistory.FEEDBACKS
    case EProfileHistory.VERIFICATION:
      return EProfileHistory.VERIFICATION
    default:
      return EProfileHistory.MAIN_INFO
  }
}

const getDeafultOptions = (toggleBarOptions: IToggleBarOptions[], itemValue: string) => {
  if (toggleBarOptions && toggleBarOptions.length) {
    let index = 0
    toggleBarOptions.forEach((item: IToggleBarOptions, i: number) => {
      if (item.value === itemValue) {
        index = i
      }
    })
    return toggleBarOptions[index]
  }
  return {
    value: '',
    title: ''
  }
}

export const setDefaultMainToggleBarState = (
  profileType: any,
  words: TWords,
  isEdit: boolean,
  isAdmin: boolean,
  isUser: boolean
): IToggleBarData => {
  const toggleBarOptions = getToggleBarOptions(words, isEdit, isAdmin, false, isUser)
  switch (profileType) {
    case EProfileHistory.MAIN_INFO:
      return getDeafultOptions(toggleBarOptions, EProfileHistory.MAIN_INFO)
    case EProfileHistory.CAREER:
      return getDeafultOptions(toggleBarOptions, EProfileHistory.CAREER)
    case EProfileHistory.TIME_OFF_BALANCE:
      return getDeafultOptions(toggleBarOptions, EProfileHistory.TIME_OFF_BALANCE)
    case EProfileHistory.POINTS_SYSTEM:
      return getDeafultOptions(toggleBarOptions, EProfileHistory.POINTS_SYSTEM)
    case EProfileHistory.FEEDBACKS:
      return getDeafultOptions(toggleBarOptions, EProfileHistory.FEEDBACKS)
    case EProfileHistory.VERIFICATION:
      return getDeafultOptions(toggleBarOptions, EProfileHistory.VERIFICATION)
    default:
      return toggleBarOptions && toggleBarOptions[0]
  }
}

export const getSubcontractType = (value: string): string => {
  switch (value) {
    case ESubcontractHistory.ALL_TASKS:
      return ESubcontractHistory.ALL_TASKS
    case ESubcontractHistory.MY_TASK:
      return ESubcontractHistory.MY_TASK
    case ESubcontractHistory.MY_ANSWERS:
      return ESubcontractHistory.MY_ANSWERS
    case ESubcontractHistory.PERFORMERS:
      return ESubcontractHistory.PERFORMERS
    case ESubcontractHistory.PLANNING:
      return ESubcontractHistory.PLANNING
    case ESubcontractHistory.DRAFTS:
      return ESubcontractHistory.DRAFTS
    default:
      return ESubcontractHistory.ALL_TASKS
  }
}

export const getEntrepreneurAdminType = (value: string): string => {
  switch (value) {
    case EEntrepreneurAdminHistory.LIST_ENTREPRENEURS:
      return EEntrepreneurAdminHistory.LIST_ENTREPRENEURS
    case EEntrepreneurAdminHistory.FORMULATED_INVOICES:
      return EEntrepreneurAdminHistory.FORMULATED_INVOICES
    default:
      return EEntrepreneurAdminHistory.LIST_ENTREPRENEURS
  }
}

export const getSubcontractTasksAdminType = (value: string): string => {
  switch (value) {
    case ESubcontractAdminHistory.ALL_TASKS_ADMIN:
      return ESubcontractAdminHistory.ALL_TASKS_ADMIN
    case ESubcontractAdminHistory.DEACTIVATED:
      return ESubcontractAdminHistory.DEACTIVATED
    default:
      return ESubcontractAdminHistory.ALL_TASKS_ADMIN
  }
}

export const getVacanciesAdminType = (value: string): string => {
  switch (value) {
    case EVacanciesAdminHistory.ALL_VACANCIES_ADMIN:
      return EVacanciesAdminHistory.ALL_VACANCIES_ADMIN
    case EVacanciesAdminHistory.ARCHIVED:
      return EVacanciesAdminHistory.ARCHIVED
    default:
      return EVacanciesAdminHistory.ALL_VACANCIES_ADMIN
  }
}

export const getCryptoBotType = (value: string): string => {
  switch (value) {
    case ECryptoBotHistory.GENERAL:
      return ECryptoBotHistory.GENERAL
    case ECryptoBotHistory.CHART:
      return ECryptoBotHistory.CHART
    case ECryptoBotHistory.POSITIONS:
      return ECryptoBotHistory.POSITIONS
    default:
      return ECryptoBotHistory.GENERAL
  }
}

export const getVerivicationAdminType = (value: string): string => {
  switch (value) {
    case EVerificationAdminHistory.ALL_VERIFY:
      return EVerificationAdminHistory.ALL_VERIFY
    case EVerificationAdminHistory.NOT_VERIFY:
      return EVerificationAdminHistory.NOT_VERIFY
    case EVerificationAdminHistory.ACCEPTED_VERIFY:
      return EVerificationAdminHistory.ACCEPTED_VERIFY
    default:
      return EVerificationAdminHistory.ALL_VERIFY
  }
}

export const getProjectType = (value: string): string => {
  switch (value) {
    case EProjectHistory.CURRENT_TEAM:
      return EProjectHistory.CURRENT_TEAM
    case EProjectHistory.VACANCIES:
      return EProjectHistory.VACANCIES
    case EProjectHistory.INVOICES:
      return EProjectHistory.INVOICES
    case EProjectHistory.TEAM_ARCHIVE:
      return EProjectHistory.TEAM_ARCHIVE
    default:
      return EProjectHistory.CURRENT_TEAM
  }
}

export const setDefaultMainToggleBarStateForTask = (
  TaskType: any,
  words: TWords
): IToggleBarData => {
  const toggleBarOptions = getToggleBarOptionsForSubcontract(words)

  switch (TaskType) {
    case ESubcontractHistory.ALL_TASKS:
      return toggleBarOptions && toggleBarOptions[0]
    case ESubcontractHistory.MY_TASK:
      return toggleBarOptions && toggleBarOptions[1]
    case ESubcontractHistory.MY_ANSWERS:
      return toggleBarOptions && toggleBarOptions[2]
    case ESubcontractHistory.PLANNING:
      return toggleBarOptions && toggleBarOptions[3]
    case ESubcontractHistory.DRAFTS:
      return toggleBarOptions && toggleBarOptions[4]
    default:
      return toggleBarOptions && toggleBarOptions[0]
  }
}

export const setDefaultMainToggleBarStateForEntrepreneurs = (
  TaskType: any,
  words: TWords
): IToggleBarData => {
  const toggleBarOptions = getToggleBarOptionsForEntrepreneurs(words)

  switch (TaskType) {
    case EEntrepreneurAdminHistory.LIST_ENTREPRENEURS:
      return toggleBarOptions && toggleBarOptions[0]
    case EEntrepreneurAdminHistory.FORMULATED_INVOICES:
      return toggleBarOptions && toggleBarOptions[1]
    default:
      return toggleBarOptions && toggleBarOptions[0]
  }
}

export const setDefaultMainToggleBarStateForSubcontractTask = (
  TaskType: any,
  words: TWords
): IToggleBarData => {
  const toggleBarOptions = getToggleBarOptionsForSubcontractTasks(words)

  switch (TaskType) {
    case ESubcontractAdminHistory.ALL_TASKS_ADMIN:
      return toggleBarOptions && toggleBarOptions[0]
    case ESubcontractAdminHistory.DEACTIVATED:
      return toggleBarOptions && toggleBarOptions[1]
    default:
      return toggleBarOptions && toggleBarOptions[0]
  }
}

export const setDefaultMainToggleBarStateForVacancies = (
  VacanciesType: any,
  words: TWords
): IToggleBarData => {
  const toggleBarOptions = getToggleBarOptionsForVacancies(words)

  switch (VacanciesType) {
    case EVacanciesAdminHistory.ALL_VACANCIES_ADMIN:
      return toggleBarOptions && toggleBarOptions[0]
    case EVacanciesAdminHistory.ARCHIVED:
      return toggleBarOptions && toggleBarOptions[1]
    default:
      return toggleBarOptions && toggleBarOptions[0]
  }
}

export const setDefaultMainToggleBarStateForCryptoBot = (
  TaskType: any,
  words: TWords
): IToggleBarData => {
  const toggleBarOptions = getToggleBarOptionsForCrypto(words)

  switch (TaskType) {
    case ECryptoBotHistory.GENERAL:
      return toggleBarOptions && toggleBarOptions[0]
    case ECryptoBotHistory.CHART:
      return toggleBarOptions && toggleBarOptions[1]
    case ECryptoBotHistory.POSITIONS:
      return toggleBarOptions && toggleBarOptions[2]
    default:
      return toggleBarOptions && toggleBarOptions[0]
  }
}

export const setDefaultMainToggleBarStateForAdvancedBotSettings = (value: any): IToggleBarData => {
  const toggleBarOptions = getToggleBarOptionsForAdvancedBotSettings()

  switch (value) {
    case EAdvancedSettingsHistory.EXCHANGE:
      return toggleBarOptions && toggleBarOptions[0]
    case EAdvancedSettingsHistory.CHANNELS:
      return toggleBarOptions && toggleBarOptions[1]
    default:
      return toggleBarOptions && toggleBarOptions[0]
  }
}

export const setDefaultMainToggleBarStateForVerifications = (
  TaskType: any,
  words: TWords
): IToggleBarData => {
  const toggleBarOptions = getToggleBarOptionsForVerifications(words)

  switch (TaskType) {
    case EVerificationAdminHistory.ALL_VERIFY:
      return toggleBarOptions && toggleBarOptions[0]
    case EVerificationAdminHistory.NOT_VERIFY:
      return toggleBarOptions && toggleBarOptions[1]
    case EVerificationAdminHistory.NOT_VERIFY:
      return toggleBarOptions && toggleBarOptions[2]
    default:
      return toggleBarOptions && toggleBarOptions[0]
  }
}

export const setDefaultMainToggleBarStateForProject = (
  ProjectType: any,
  words: TWords
): IToggleBarData => {
  const toggleBarOptions = getToggleBarOptionsForProject(words)

  switch (ProjectType) {
    case EProjectHistory.CURRENT_TEAM:
      return toggleBarOptions && toggleBarOptions[0]
    case EProjectHistory.VACANCIES:
      return toggleBarOptions && toggleBarOptions[1]
    case EProjectHistory.INVOICES:
      return toggleBarOptions && toggleBarOptions[2]
    case EProjectHistory.TEAM_ARCHIVE:
      return toggleBarOptions && toggleBarOptions[3]
    default:
      return toggleBarOptions && toggleBarOptions[0]
  }
}

export const getResponseType = (value: string): string => {
  switch (value) {
    case EResponsesHistory.ALL_RESPONSES:
      return EResponsesHistory.ALL_RESPONSES
    case EResponsesHistory.MY_PERFORMER:
      return EResponsesHistory.MY_PERFORMER
    case EResponsesHistory.ON_INTERVIEW:
      return EResponsesHistory.ON_INTERVIEW
    case EResponsesHistory.AGREED:
      return EResponsesHistory.AGREED
    default:
      return EResponsesHistory.ALL_RESPONSES
  }
}

export const setDefaultMainToggleBarStateForResponse = (
  ResponseType: any,
  words: TWords
): IToggleBarData => {
  const toggleBarOptions = getToggleBarOptionsForSubcontractResponse(words)

  switch (ResponseType) {
    case EResponsesHistory.ALL_RESPONSES:
      return toggleBarOptions && toggleBarOptions[0]
    case EResponsesHistory.MY_PERFORMER:
      return toggleBarOptions && toggleBarOptions[1]
    case EResponsesHistory.ON_INTERVIEW:
      return toggleBarOptions && toggleBarOptions[2]
    case EResponsesHistory.AGREED:
      return toggleBarOptions && toggleBarOptions[3]
    default:
      return toggleBarOptions && toggleBarOptions[0]
  }
}
