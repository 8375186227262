import React, { FC, useEffect, useState } from 'react'
import styles from './CreateVacancy.module.scss'
import { Form } from 'react-final-form'
import { BaseButton } from '../../../../components/UiKit/Button/BaseButton'
import FieldFactory from '../../../../components/UiKit/FieldFactory/FieldFactory'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from '../../../../store'
import { useParams } from 'react-router'
import { ReactComponent as ArrowLeftWithStick } from 'assets/images/arrow_left_with_stick.svg'
import { config } from '../../../../globalConfigs'
import { getSelectOptions } from '../../../client/EditProfile/EditProfile.thunx'
import { getProjectOptions, setVacancy } from './CreateVacancy.thunk'
import Modal from '../../../../components/Modal'
import { getManagers } from '../../../client/NewRequestTimeOff/NewRequestTimeOff.thunx'
import { TSelectOption } from '../../../../components/Select'
import { TCreateVacancyProps } from './CreateVacancy.types'
import { getInitialValues } from './initialValues'
// import { setVacancy } from './CreateVacancy.thunk'

const CreateVacancy: FC<TCreateVacancyProps> = ({
  positions,
  rank,
  englishLevel,
  typeOfEmployment,
  projectOptions,
  managersData,
  history,
  getVacancy,
  vacancy,
  updateVacancy
}) => {
  const [managersOptions, setManagersOptions] = useState<TSelectOption[]>([
    { value: '', label: '' }
  ])
  const words = useSelector((state: TState) => state.global.language.words)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const dispatch = useDispatch()
  const params: any = useParams()
  const isEdit = !!params.id
  useEffect(() => {
    const filteredData: TSelectOption[] = managersData.results
      .map(item => ({
        value: `${item.fullName}`,
        label: item.fullName
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    setManagersOptions(filteredData)
  }, [managersData])

  useEffect(() => {
    dispatch(getSelectOptions())
    dispatch(getProjectOptions())
    dispatch(getManagers({ limit: 10 }))
    if (isEdit) {
      getVacancy(params.id)
    }
  }, [])
  const createVacancySub = (values: any) => {
    const requestObj: any = {}
    requestObj.title = Number(values.title)
    requestObj.rank = values.rank
    requestObj.dateOfClosing = values.dateOfClosing
    requestObj.location = values.location
    requestObj.recruiter = values.recruiter
    requestObj.countOfHours = values.countOfHours
    requestObj.participationPeriod = values.participationPeriod
    requestObj.isTestExist = values.isTestExist === '1'
    requestObj.technologies = values.technologies
    requestObj.additionalRequirements = values.additionalRequirements
    requestObj.interviewer = values.interviewer
    requestObj.typeOfEmployment = String(values.typeOfEmployment)
    requestObj.englishLevel = String(values.levelOfEnglish)
    requestObj.project = String(values.project)
    requestObj.status = String(1)
    if (isEdit) {
      updateVacancy(params.id, requestObj, requestObj)
    } else {
      dispatch(setVacancy(requestObj))
    }
    setIsSuccessful(true)
  }

  const handleVacancySubmit = (values: any) => {
    createVacancySub(values)
  }

  return (
    <>
      <div className={styles.container}>
        <section className={styles.header}>
          {/* {!parsed.taskType ? <Icons icon="backArrow" /> : null} */}
          <h1>
            {isEdit ? words['admin.vacancies.editingVacancy'] : words['admin.vacancies.newVacancy']}
          </h1>
        </section>
        <div className={styles.createVacancyContainer}>
          <div className={styles.backButton} onClick={() => history.goBack()}>
            <ArrowLeftWithStick />
            <span>{words['user.projectInvoicesTable.back']}</span>
          </div>
          <Form
            initialValues={isEdit ? getInitialValues(vacancy) : {}}
            onSubmit={(values: any) => handleVacancySubmit(values)}
          >
            {({ form, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <section className={styles.mainInfo}>
                    <h2>{words['project.main']}</h2>
                    <div className={styles.mainInfoContainer}>
                      <div className={styles.mainInfoColumn}>
                        <FieldFactory
                          form={form}
                          config={[
                            {
                              items: [
                                {
                                  name: 'title',
                                  label: words['user.subcontract.taskFilter.specialization'],
                                  required: true,
                                  component: () => {
                                    return {
                                      type: 'select',
                                      props: {
                                        placeholder: '',
                                        options: positions
                                          ? positions
                                          : [{ value: '1', label: 'Loading...' }]
                                      }
                                    }
                                  }
                                }
                              ]
                            }
                          ]}
                          words={words}
                        />
                        <div className={styles.mainInfoWrap}>
                          <FieldFactory
                            // currentLanguage={currentLanguage}
                            form={form}
                            config={[
                              {
                                style: {
                                  marginBottom: '0px',
                                  width: '100%'
                                },
                                items: [
                                  {
                                    name: 'typeOfEmployment',
                                    label: words['user.profile.career.occupation'],
                                    required: true,
                                    component: () => {
                                      return {
                                        type: 'select',
                                        props: {
                                          placeholder: '',
                                          options: typeOfEmployment
                                            ? typeOfEmployment
                                            : [{ value: '1', label: 'Loading...' }]
                                        }
                                      }
                                    }
                                  }
                                ]
                              }
                            ]}
                            words={words}
                          />
                          <FieldFactory
                            // currentLanguage={currentLanguage}
                            disabled={false}
                            form={form}
                            config={[
                              {
                                style: {
                                  marginBottom: '0px',
                                  width: '100%'
                                },
                                items: [
                                  {
                                    name: 'dateOfClosing',
                                    label: words['admin.vacancies.dateOfClosing'],
                                    required: false,
                                    component: ({ input }: any) => {
                                      return {
                                        type: 'datePicker',
                                        props: {
                                          autoComplete: 'off',
                                          dateFormat: config.dateFormat,
                                          selected: input.value ? new Date(input.value) : null
                                        }
                                      }
                                    }
                                  }
                                ]
                              }
                            ]}
                            words={words}
                          />
                        </div>
                        <FieldFactory
                          // currentLanguage={currentLanguage}
                          form={form}
                          config={[
                            {
                              items: [
                                {
                                  name: 'location',
                                  label: words['user.profile.mainInfo.location'],
                                  required: true,
                                  inputWrapperClassName: styles['description-location'],
                                  component: () => {
                                    return {
                                      type: 'input',
                                      props: {
                                        variant: 'outlined'
                                      }
                                    }
                                  }
                                }
                              ]
                            }
                          ]}
                          words={words}
                        />
                      </div>
                      <div className={styles.mainInfoColumn}>
                        <FieldFactory
                          // currentLanguage={currentLanguage}
                          form={form}
                          config={[
                            {
                              items: [
                                {
                                  name: 'rank',
                                  label: words['user.profile.career.level'],
                                  required: true,
                                  component: () => {
                                    return {
                                      type: 'select',
                                      props: {
                                        placeholder: '',
                                        options: rank ? rank : [{ value: '1', label: 'Loading...' }]
                                      }
                                    }
                                  }
                                }
                              ]
                            }
                          ]}
                          words={words}
                        />
                        <FieldFactory
                          // currentLanguage={currentLanguage}
                          form={form}
                          config={[
                            {
                              items: [
                                {
                                  name: 'project',
                                  label: words['admin.vacancies.project'],
                                  required: true,
                                  component: () => {
                                    return {
                                      type: 'select',
                                      props: {
                                        placeholder: '',
                                        options: projectOptions
                                          ? projectOptions
                                          : [{ value: '1', label: 'Loading...' }]
                                      }
                                    }
                                  }
                                }
                              ]
                            }
                          ]}
                          words={words}
                        />
                        <FieldFactory
                          // currentLanguage={currentLanguage}
                          form={form}
                          config={[
                            {
                              items: [
                                {
                                  name: 'recruiter',
                                  label: words['admin.vacancies.responsibleRecruiter'],
                                  required: true,
                                  component: () => {
                                    return {
                                      type: 'select',
                                      props: {
                                        placeholder: '',
                                        options: managersOptions
                                      }
                                    }
                                  }
                                }
                              ]
                            }
                          ]}
                          words={words}
                        />
                      </div>
                    </div>
                  </section>
                  <section className={styles.requirementsInfo}>
                    <h2>{words['admin.vacancies.requirements'] || 'Требования'}</h2>
                    <div className={styles.requirementsInfoTopContainer}>
                      <FieldFactory
                        // currentLanguage={currentLanguage}
                        form={form}
                        config={[
                          {
                            items: [
                              {
                                name: 'participationPeriod',
                                label: words['admin.vacancies.participationPeriod'],
                                required: true,
                                component: () => {
                                  return {
                                    type: 'input',
                                    props: {
                                      variant: 'outlined'
                                    }
                                  }
                                }
                              }
                            ]
                          }
                        ]}
                        words={words}
                      />
                      <FieldFactory
                        // currentLanguage={currentLanguage}
                        form={form}
                        config={[
                          {
                            items: [
                              {
                                name: 'countOfHours',
                                label: words['admin.vacancies.countOfHoursPerDay'],
                                required: true,
                                component: () => {
                                  return {
                                    type: 'input',
                                    props: {
                                      variant: 'outlined'
                                    }
                                  }
                                }
                              }
                            ]
                          }
                        ]}
                        words={words}
                      />
                      <FieldFactory
                        // currentLanguage={currentLanguage}
                        form={form}
                        config={[
                          {
                            items: [
                              {
                                name: 'levelOfEnglish',
                                label: words['user.profile.career.englishLevel'],
                                required: true,
                                component: () => {
                                  return {
                                    type: 'select',
                                    props: {
                                      placeholder: '',
                                      options: englishLevel
                                        ? englishLevel
                                        : [{ value: '1', label: 'Loading...' }]
                                    }
                                  }
                                }
                              }
                            ]
                          }
                        ]}
                        words={words}
                      />
                    </div>
                    <div className={styles.requirementsInfoBottomContainer}>
                      <FieldFactory
                        // currentLanguage={currentLanguage}
                        form={form}
                        config={[
                          {
                            items: [
                              {
                                name: 'isTestExist',
                                label: words['admin.vacancies.testTaskFromClient'],
                                required: true,
                                component: () => {
                                  return {
                                    type: 'select',
                                    props: {
                                      placeholder: '',
                                      options: [
                                        { value: '1', label: 'Да' },
                                        { value: '2', label: 'Нет' }
                                      ]
                                    }
                                  }
                                }
                              }
                            ]
                          }
                        ]}
                        words={words}
                      />
                      <FieldFactory
                        // currentLanguage={currentLanguage}
                        form={form}
                        config={[
                          {
                            items: [
                              {
                                name: 'interviewer',
                                label: words['admin.vacancies.interviewers'],
                                required: true,
                                component: () => {
                                  return {
                                    type: 'select',
                                    props: {
                                      placeholder: '',
                                      options: managersOptions
                                    }
                                  }
                                }
                              }
                            ]
                          }
                        ]}
                        words={words}
                      />
                    </div>
                    <FieldFactory
                      // currentLanguage={currentLanguage}
                      form={form}
                      config={[
                        {
                          items: [
                            {
                              name: 'technologies',
                              label: words['admin.vacancies.techStackJob'],
                              required: true,
                              component: () => {
                                return {
                                  type: 'textarea',
                                  props: {}
                                }
                              }
                            }
                          ]
                        }
                      ]}
                      words={words}
                    />
                    <FieldFactory
                      // currentLanguage={currentLanguage}
                      form={form}
                      config={[
                        {
                          items: [
                            {
                              name: 'additionalRequirements',
                              label: words['admin.vacancies.additionalRequierements'],
                              required: true,
                              component: () => {
                                return {
                                  type: 'textarea',
                                  props: {}
                                }
                              }
                            }
                          ]
                        }
                      ]}
                      words={words}
                    />
                  </section>
                  <div className={styles.buttonsWrapper}>
                    <BaseButton size="lg" outline={true} onClick={() => history.goBack()}>
                      {words['project.cancel']}
                    </BaseButton>
                    <BaseButton type="submit" size="lg">
                      {isEdit ? words['user.profile.editBtn'] : words['user.dayOffTracker.create']}
                    </BaseButton>
                  </div>
                </form>
              )
            }}
          </Form>
        </div>
      </div>
      <Modal
        isShow={isSuccessful}
        onClose={() => history.push('/dashboard/vacancies?page=1&vacancy=all-vacancies-admin')}
        title={
          isEdit
            ? words['admin.vacancies.vacancySuccessfullyEdited'] ||
              'Вакансия успешно отредактирована'
            : words['admin.vacancies.vacancySuccessfullyCreated'] || 'Вакансия успешно создана!'
        }
        className={styles['modal']}
      />
    </>
  )
}

export default CreateVacancy
