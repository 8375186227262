import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch } from 'react-redux'
import { signInClear } from 'screens/auth/Login/Login.actions'

export const useAuth = () => {
  const { logout, ...rest } = useAuth0()
  const dispatch = useDispatch()

  const logoutWithRedirect = async () => {
    const redirectUri = `${window.location.origin}/signin`

    await logout({
      logoutParams: {
        returnTo: redirectUri
      }
    })
    setTimeout(() => {
      dispatch(signInClear())
    }, 500)
  }

  return {
    ...rest,
    logout,
    logoutWithRedirect
  }
}
