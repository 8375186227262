import React, { FC } from 'react'
import { withRouter } from 'react-router-dom'
import { TPrivateRouter } from './PrivatRoute.model'
import { Dashboard } from 'elements/App/components/Dashboard'

const PrivateRoute: FC<TPrivateRouter> = ({ languageObject, decodedToken, ...rest }) => {
  return <Dashboard decodedToken={decodedToken} languageObject={languageObject} {...rest} />
}

export default withRouter(PrivateRoute)
