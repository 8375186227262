import { initialDealState } from './deal.model'
import { GET_DEAL, CLEAR_DEAL } from './deal.constants'
// import { IDeal } from '../Web3/web3.model'
import { TaskDeal } from 'utils/contractsMethods'

export default (state = initialDealState, action: any) => {
  switch (action.type) {
    case GET_DEAL:
      return {
        ...state,
        data: action.payload as TaskDeal
      }
    case CLEAR_DEAL:
      return {
        ...state,
        data: null
      }
    default:
      return state
  }
}
