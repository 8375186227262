import { Auth0Provider } from '@auth0/auth0-react'
// import { AppState, Auth0Provider, User } from '@auth0/auth0-react'
import { config } from 'globalConfigs'
import React from 'react'
import { useSelector } from 'react-redux'
import { TState } from 'store'
// import history from 'utils/history'

export const Auth0ProviderWithHistory = ({ children }: any) => {
  const selectedRole = useSelector((state: TState) => state.auth.role)

  const domain = config.authDomain
  const clientId = config.authClientId
  // const redirectUri = `${window.location.origin}/signin`
  const redirectUri = `${window.location.origin}/callback`
  console.log('***_auth0_selectedRole=', selectedRole)
  console.log('***_auth0_redirectUri=', redirectUri)

  // const onRedirectCallback = (appState: AppState | undefined, user?: User | undefined): void => {
  //   console.log('***_auth0_onRedirectCallback_appState=', appState)
  //   console.log('***_auth0_onRedirectCallback_user=', user)
  //   let url = redirectUri

  //   if (appState && appState.returnTo) {
  //     url = appState.returnTo
  //   }
  //   console.log('***url_', url)
  //   // history.push(url)
  // }

  if (!(domain && clientId && redirectUri)) {
    return null
  }

  return (
    <Auth0Provider
      key={selectedRole}
      domain={domain}
      clientId={clientId}
      cacheLocation="localstorage"
      useRefreshTokens={true}
      // onRedirectCallback={onRedirectCallback}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: config.authAudience,
        scope: config.authScope,
        currentRole: selectedRole || '4'
      }}
    >
      {children}
    </Auth0Provider>
  )
}
