import React, { FC, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'
import styles from './EditBtnMenu.module.scss'
import { ReactComponent as EditProfileBTN } from 'assets/images/icon_dots_vertical.svg'
import history from 'utils/history'
import { EProfileHistory } from 'globalConfigs'
import { isAdmin as adminRole } from 'utils/user'
import { DeactivateUserModal } from '../DeactivateUserModal'
import {
  setOpenDeactivateModal,
  setOpenRestoreUserModal
} from 'screens/admin/Team/TeamAdmin.actions'
import { RestoreUserModal } from '../RestoreUserModal/RestoreUserModal'

type TEditMenuProps = {
  userId: number
  role: string
  deactivated: string | null
}

export const EditBtnMenu: FC<TEditMenuProps> = ({ userId, role, deactivated }) => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const words = useSelector((state: TState) => state.global.language.words)
  const isAdmin = useMemo(() => adminRole(role), [role])

  const toggleOpen = () => setOpen(!open)
  const toggleOpenDeactivateModal = () => {
    dispatch(setOpenDeactivateModal())
  }
  const toggleOpenRestoreModal = () => {
    dispatch(setOpenRestoreUserModal())
  }
  return (
    <>
      <div className={styles.container}>
        {open && <div className={styles.overlay} onClick={toggleOpen} />}
        <button className={styles['edit-btn']} onClick={toggleOpen} type="button">
          <EditProfileBTN />
        </button>
        {open && (
          <ul className={styles.menu}>
            <li
              onClick={() =>
                history.push(
                  `/dashboard/profile/editProfile?profileType=${
                    EProfileHistory.MAIN_INFO
                  }&userId=${userId}`
                )
              }
            >
              {words['user.profile.editBtn']}
            </li>
            {isAdmin && !deactivated && (
              <li onClick={toggleOpenDeactivateModal}>{words['admin.team.deactivate']}</li>
            )}
            {isAdmin && deactivated && (
              <li onClick={toggleOpenRestoreModal}>{words['admin.users.table.buttons.restore']}</li>
            )}
          </ul>
        )}
      </div>

      <DeactivateUserModal toggleOpenDeactivateModal={toggleOpenDeactivateModal} userId={userId} />
      <RestoreUserModal userId={userId} />
    </>
  )
}
