import { applyMiddleware, createStore, combineReducers, AnyAction } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { config } from 'globalConfigs'
import { persistReducer, persistStore } from 'redux-persist'
import persistStorage from 'redux-persist/lib/storage' // defaults to localStorage for web

import auth from 'screens/auth/Login/Login.reducer'
import getUser from 'screens/admin/CreateInvoice/CreateInvoice.reducer'
import goods from 'screens/client/Goods/Goods.reducer'
import good from 'screens/client/GoodsItemDetails/GoodsItemDetails.reducer'
import basket from 'screens/client/Basket/Basket.reducer'
import notifications from 'screens/client/Notifications/Notifications.reducer'
import info from 'screens/client/Information/Information.reducer'
import achievements from 'screens/client/Achievements/Achievements.reducer'
import profile from 'screens/client/Profile/Profile.reducer'
import editProfile from 'screens/client/EditProfile/EditProfile.reducer'
import createTask from 'screens/client/Subcontract/components/CreateTask/CreateTask.reducer'
import subcontract from 'screens/client/Subcontract/Subcontract.reducer'
import planing from 'screens/client/Subcontract/components/PlaningCalendar/PlaningCalendar.reducer'
import newRequestTimeOff from 'screens/client/NewRequestTimeOff/NewRequestTimeOff.reducer'
import requestsTimeOffUser from 'screens/client/RequestsTimeOffUser/RequestsTimeOffUser.reducer'
import timeOffRequests from 'screens/client/TimeOffRequests/TimeOffRequests.reducer'
import rating from 'screens/client/Profile/components/UserRating/UserRating.reducer'
import goodsItem from 'screens/admin/GoodsItem/GoodsItem.reducer'
import shop from 'screens/admin/Shop/Shop.reducer'
import users from 'screens/admin/Users/Users.reducer'
import timeOfRequest from 'screens/admin/Settings/components/SettingsTimeOfRequest/SettingsTimeOfRequest.reducer'
import pointsSystem from 'screens/admin/Settings/components/SettingsPointsSystem/SettingsPointsSystem.reducer'
import timeOfRequestUpdate from 'screens/admin/Settings/components/SettingsTimeOfRequestUpdate/SettingsTimeOfRequestUpdate.reducer'
import requests from 'screens/admin/Requests/Requests.reducer'
import filter from 'components/UiKit/Filter/Filter.reducer'
import team from 'screens/client/Team/Team.reducer'
import controlPanel from 'screens/client/CryptoBot/components/ControlPanel/ControlPanel.reducer'
import teamAdmin from 'screens/admin/Team/TeamAdmin.reducer'
import taskRequest from 'screens/client/TaskRequest/TaskRequest.reducer'
import interview from 'screens/client/InterviewInvitation/InterviewInvitation.reducer'
import web3 from '../screens/client/Web3/web3.reducer'
import verification from 'screens/admin/SubcontractTasks/Verification/VerificationAdmin.reducer'
import verificationClient from 'screens/client/Subcontract/components/Verification/Verification.reducer'
import projectInvoice from 'screens/admin/ProjectInvoices/ProjectInvoices.reducer'
import projects from 'screens/admin/Projects/Projects.reducer'
import entrepreneurs from 'screens/admin/Entrepreneurs/Entrepreneurs.reducer'
import foreignProfile from 'screens/client/ForeignProfile/ForeignProfile.reducer'
import vacancies from 'screens/admin/Vacancies/Vacancies.reducer'
import policy from 'screens/client/Policy/Policy.reducer'
import voting from 'screens/client/VotingList/VotingList.reducer'
import deal from 'screens/client/Deal/deal.reducer'

import currentTeam from '../components/Project/CurrentTeam/CurrentTeam.reducer'
// import faq from 'screens/client/Information/Information.reducer'
import { languageReducer as language } from './global'
import token from 'screens/admin/Token/Token.reducer'
// import thunk from 'redux-thunk'

const rootPersistConfig = {
  key: 'root',
  storage: persistStorage,
  whitelist: ['auth']
}

const basketPersistConfig = {
  key: 'basket',
  storage: persistStorage,
  blacklist: ['error', 'loading']
}

const client = combineReducers({
  goods,
  good,
  basket: persistReducer(basketPersistConfig, basket),
  notifications,
  info,
  achievements,
  profile,
  editProfile,
  policy,
  voting,
  createTask,
  subcontract,
  planing,
  requestsTimeOffUser,
  timeOffRequests,
  newRequestTimeOff,
  rating,
  filter,
  team,
  taskRequest,
  interview,
  verificationClient,
  web3,
  foreignProfile,
  controlPanel,
  deal
})

const settings = combineReducers({
  timeOfRequest,
  timeOfRequestUpdate,
  pointsSystem
})

const admin = combineReducers({
  goodsItem,
  shop,
  users,
  settings,
  requests,
  teamAdmin,
  verification,
  getUser,
  projectInvoice,
  entrepreneurs,
  vacancies,
  token,
  projects,
  currentTeam
})

const global = combineReducers({
  language
})

const appReducer = combineReducers({
  auth,
  admin,
  client,
  global
})

const rootReducer = (state: any, action = {}) => appReducer(state, action as AnyAction)

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

const configureStore = () => {
  let middleware = applyMiddleware(thunkMiddleware)
  if (!config.isProduction) {
    middleware = composeWithDevTools(middleware)
  }

  const store = createStore(persistedReducer, middleware)
  return store
}

export const store = configureStore()

export const persistor = persistStore(store)

export type TState = ReturnType<typeof appReducer>
