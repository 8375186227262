import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { TimeOffRequests } from './TimeOffRequests'
import { bindActionCreators, Dispatch } from 'redux'
import { getTimeOffRequests, actionForTimeOff, getCurrentRowRequest } from './TimeOffRequests.thunx'
import { TState } from '../../../store'
import { getActiveTeamMembers, getManagers } from '../NewRequestTimeOff/NewRequestTimeOff.thunx'

export const mapStateToProps = (state: TState) => {
  return {
    role: state.auth.decodedToken.role,
    data: state.client.timeOffRequests.data,
    minFromDate: state.client.timeOffRequests.minFromDate,
    maxToDate: state.client.timeOffRequests.maxToDate,
    dataUsers: state.client.newRequestTimeOff.users.results,
    dataManagers: state.client.newRequestTimeOff.managers.results,
    total: state.client.timeOffRequests.total,
    currentRequest: state.client.timeOffRequests.currentRequest,
    loadingData: state.client.timeOffRequests.loading,
    loadingReject: state.client.timeOffRequests.loadingReject,
    loadingApprove: state.client.timeOffRequests.loadingApprove,
    activeTeamMembersList: state.client.newRequestTimeOff.activeTeamMembersList
  }
}

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getActiveTeamMembers,
      getManagers,
      getTimeOffRequests,
      actionForTimeOff,
      getCurrentRowRequest
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TimeOffRequests))
