import React, { FC, useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { mapDispatchToProps, mapStateToProps } from './Information.container'
import { useUser } from '../../../hooks/useUser'
import { Icons } from 'components/Icons'
import { EFaq } from 'globalConfigs'
import { ToggleBar } from 'components/ToggleBar'
import queryString from 'query-string'
import history from 'utils/history'
import styles from './Information.module.scss'
import { IToggleBarData } from '../../../components/ToggleBar'
import { Button } from 'components/UiKit/Button'
import ItemFAQ from './components/ItemFAQ/ItemFAQ'
import { IElemData } from './Information.model'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { Form } from 'react-final-form'

type Props = RouteComponentProps &
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>

export const Information: FC<Props> = props => {
  const data = props.data.data
  const parsedType = queryString.parse(history.location.search).type
  const isAdmin = useUser().userData.admin
  const [validationNormal, setValidationNormal] = useState<boolean>(true)
  const [addPopupOpened, setAddPopupOpened] = useState<boolean>(false)
  const [questionId, setQuestionId] = useState<number>(0)
  const [question, setQuestion] = useState<string>('')
  const [answer, setAnswer] = useState<string>('')
  const words = useSelector((state: TState) => state.global.language.words)

  const toggleBarOptions = [
    {
      value: EFaq.PROCESSES,
      title: words['user.faq.companyProcesses']
    },
    {
      value: EFaq.WEEKENDS,
      title: words['user.faq.dayOffTracker']
    },
    {
      value: EFaq.POINTSSYSTEM,
      title: 'Points System'
    }
  ]

  const [selectedType, setSelectedType] = useState<IToggleBarData>(
    parsedType === EFaq.PROCESSES
      ? toggleBarOptions[0]
      : parsedType === EFaq.WEEKENDS
      ? toggleBarOptions[1]
      : toggleBarOptions[2]
  )

  const [file, setFile] = useState<any>()

  const onSelectFile = (e: any, setChoosen: any, rename?: string) => {
    console.log(rename)
    let f
    if (e) {
      f = e.target.files[0]
    }
    if (f) {
      const newFiles = []
      const blob = new Blob([f], { type: f.type })
      const url = URL.createObjectURL(blob)
      newFiles.push({ name: rename ? rename : f.name, link: url, file: f })
      setChoosen(newFiles[0].file)
      console.log('***_newFiles[0].file.name=', newFiles[0].file.name)
      // e.target.value = null
    }
  }
  // const onSelectFile = (e: any) => {
  //   const f = e.target.files[0]
  //   if (f) {
  //     const newFiles: any = []
  //     const blob = new Blob([f], { type: f.type })
  //     const url = URL.createObjectURL(blob)
  //     newFiles.push({ name: f.name, link: url, file: f })
  //     setFile(newFiles)
  //   }
  // }

  const getTypeNumber = (type: any) => {
    if (type === 'processes') {
      return 1
    } else if (type === 'weekends') {
      return 2
    } else if (type === 'pointssystem') {
      return 3
    } else {
      return
    }
  }

  const itemStructureObj = {
    answer,
    question,
    type: getTypeNumber(parsedType)
  }

  useEffect(() => {
    props.fetchInformation(getTypeNumber(parsedType))
  }, [])

  const handleQuestionChange = (event: any) => {
    setQuestion(event.target.value)
  }

  const openPopupFromUpdateIcon = (id: number) => {
    setAddPopupOpened(true)
    setQuestionId(id)
    data.map((elem: IElemData) => {
      if (elem.id === id) {
        setAnswer(elem.answer)
        setQuestion(elem.question)
      }
    })
  }

  const closeAddPopup = () => {
    setAddPopupOpened(false)
    setValidationNormal(true)
    setQuestionId(0)
    setQuestion('')
    setAnswer('')
  }

  const handleAnswerChange = (event: any) => {
    setAnswer(event.target.value)
  }

  const handleSubmits = (event: any) => {
    event.preventDefault()
    if (!isAdmin || question === '' || answer === '') {
      setValidationNormal(false)
      return
    }
    if (questionId === 0) {
      props.createInformation(itemStructureObj)
      closeAddPopup()
    }
    if (questionId !== 0) {
      props.updateInformation(questionId, itemStructureObj)
      closeAddPopup()
    }
  }
  console.log('***_file_', file)

  return (
    <>
      <div
        className={addPopupOpened ? styles.backgroundMask : styles.none}
        onClick={() => {
          closeAddPopup()
        }}
      >
        <div
          className={styles.popup}
          onClick={event => {
            event.stopPropagation()
          }}
        >
          <div className={styles.popupCloseBtnDiv}>
            <Icons
              icon="close"
              onClick={() => {
                closeAddPopup()
              }}
              className={styles.closebtn}
            />
          </div>
          <h2>
            {questionId ? words['admin.faq.new.editQuestion'] : words['admin.faq.new.newQuestion']}
          </h2>
          <div className={styles.popupInputsDiv}>
            <form>
              <p className={styles.popupSmallText}>
                {words['admin.faq.new.question']}
                <span className={styles.colorRed}>*</span>
              </p>
              <input
                name="question"
                type="text"
                value={question}
                onChange={handleQuestionChange}
                className={validationNormal ? styles.popupSmallInput : styles.popupRedSmallInput}
              />

              <p className={styles.popupSmallText}>
                {words['admin.faq.new.responce']}
                <span className={styles.colorRed}>*</span>
              </p>
              <textarea
                name="answer"
                value={answer}
                onChange={handleAnswerChange}
                className={validationNormal ? styles.popupBigInput : styles.popupRedBigInput}
              />
              <input
                type="button"
                onClick={handleSubmits}
                className={styles.popupSubmit}
                value={
                  questionId
                    ? words['admin.users.table.Edit.button.save']
                    : words['user.dayOffTracker.create']
                }
              />
            </form>
          </div>
        </div>
      </div>
      <div>
        <header className={styles.header}>
          <h1>FAQ</h1>
          {isAdmin ? (
            <Form
              initialValues={{}}
              onSubmit={(values: any) => {
                onSelectFile(false, setFile, values.document)
                props.postPolicy({ file, name: values.document })
              }}
            >
              {({ form, handleSubmit }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={styles.inputFileWrapper}>
                      <label htmlFor="addPolicy">
                        {!file && <Icons icon="add" />}
                        {file && <Icons icon="edit" />}
                        <input
                          id="addPolicy"
                          type="file"
                          // accept="image/*, .pdf"
                          onChange={(e: any) => {
                            onSelectFile(e, setFile)
                          }}
                        />
                        <div className={styles.inputFileLabel}>
                          {(file && file.name) || words['admin.faq.addFile'] || 'Add File'}
                        </div>
                      </label>

                      <BaseButton size="smb" type="submit" disabled={props.policyLoading}>
                        {words['user.basket.send']}
                      </BaseButton>
                    </div>

                    <FieldFactory
                      form={form}
                      config={[
                        {
                          items: [
                            {
                              name: 'document',
                              label: 'Document Type',
                              required: true,
                              component: () => {
                                return {
                                  type: 'select',
                                  props: {
                                    placeholder: '',
                                    options: [
                                      { value: 'terms-of-service', label: 'terms-of-service' },
                                      { value: 'privacy-policy', label: 'privacy-policy' },
                                      { value: 'cookie-policy', label: 'cookie-policy' }
                                    ]
                                  }
                                }
                              }
                            }
                          ]
                        }
                      ]}
                      words={words}
                    />
                  </form>
                )
              }}
            </Form>
          ) : null}
        </header>

        <section className={styles['toggle-container']}>
          <div className={styles.responsesBlock}>
            <div className={styles.toggle}>
              <ToggleBar
                name="active-achievements-no-active"
                data={toggleBarOptions}
                defaultChecked={selectedType}
                onChange={event => {
                  history.push(
                    `${history.location.pathname}?${queryString.stringify({
                      type:
                        event.value === EFaq.PROCESSES
                          ? EFaq.PROCESSES
                          : event.value === EFaq.WEEKENDS
                          ? EFaq.WEEKENDS
                          : EFaq.POINTSSYSTEM
                    })}`
                  )
                  setSelectedType(event)
                }}
              />
            </div>
            <Button
              className={isAdmin ? styles.addButton : styles.none}
              onClick={() => setAddPopupOpened(true)}
            >
              <div>
                <span>{words['admin.productionCalendar.buttons.add']}</span>
              </div>
            </Button>
            <div className={styles.responsesDisplay}>
              <div className={isAdmin ? styles.adminResponses : styles.responses}>
                {data &&
                  data
                    .map((elem: any) => (
                      <ItemFAQ
                        key={elem.id}
                        question={elem.question}
                        response={elem.answer}
                        isAdmin={isAdmin}
                        delete={(id: string | number) => props.deleteInformation(id)}
                        update={(id: number) => openPopupFromUpdateIcon(id)}
                        elemId={elem.id}
                      />
                    ))
                    .reverse()}
              </div>
              <div className={isAdmin ? styles.none : styles.icon}>
                <Icons icon="FAQ" className="icon" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
