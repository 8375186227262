import { TaskDeal } from 'utils/contractsMethods'
// import { IDeal } from '../Web3/web3.model'

export interface IDealInitState {
  data: TaskDeal | null
}

export const initialDealState: IDealInitState = {
  data: null
}
