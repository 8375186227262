import React, { FC, useEffect, useRef, useState } from 'react'
import { TAchievementItemProps } from './AchievementItem.modul'
import styles from './AchievementItem.module.scss'
import { Field, Form } from 'react-final-form'
import { Select } from 'Select'
import { Icons } from 'components/Icons'
import validation from 'utils/validation'
import cls from 'classnames'
import { ReactComponent as AddImgIcon } from 'assets/images/add_img_icon.svg'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Input } from 'components/UiKit/Inputs'
import { RedNote } from 'components/RedNote'
import { Textarea } from 'components/Textarea'
import { Button } from 'components/UiKit/Button'
import { imageToBlob } from 'utils/imageThroughCanvasToBlob'
import { CancelModal } from '../CancelModal'
import { ReactComponent as IconDelete } from 'assets/images/delete_icon.svg'
import { TSelectOption } from '../../../../../components/Select'
import { useSelector } from 'react-redux'
import { TState } from 'store'

interface ISetImage {
  prevState: any
}

export const AchievementItemComponent: FC<TAchievementItemProps> = ({
  achievement,
  getGoodsCategories,
  createOrEditAchievement,
  getCurrentAchievement,
  categories,
  deleteGoodsCategory,
  setNewCategory,
  match,
  history
}) => {
  const isEdit = !!match.params.id
  console.log(categories)
  useEffect(() => {
    getData()

    if (isEdit && isNaN(Number(match.params.id))) {
      history.push('/dashboard/point-system/achievements')
    }

    if (isEdit) {
      const { id } = match.params
      getCurrentAchievement(id)
    }
  }, [])

  const newCategoryRef = useRef<HTMLInputElement>(null)
  const [isImageLoad, setIsImageLoad] = useState(false)
  const [blob, setBlob] = useState()
  const [srcForView, setSrcForView] = useState()
  const [userFileProps, setUserFileProps] = useState()
  const [image, setImage] = useState<ISetImage>()
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false)
  const toggleOpenCancelModal = () => setOpenCancelModal(!openCancelModal)
  const [valuesFormData, setValuesFormData] = useState()
  const words = useSelector((state: TState) => state.global.language.words)

  useEffect(() => {
    if (isEdit) {
      setSrcForView(achievement.photo)
      setIsImageLoad(true)
    }
  }, [achievement])

  useEffect(() => {
    // @ts-ignore
    userFileProps && setImage(new File([blob], userFileProps.name, { type: userFileProps.type }))
  }, [userFileProps, blob])

  const achievementCategoriesForSelect = () => {
    return categories.map((item: any) => ({
      value: item.id,
      label: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {item.name}
          <span
            data-id={item.id}
            style={{ cursor: 'pointer' }}
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              deleteGoodsCategory(item.id, 'achievement')
            }}
          >
            {<IconDelete />}
          </span>
        </div>
      )
    }))
  }

  const [achievementCategories, setAchievementCategories] = useState<TSelectOption[]>([])

  const handleCatrgories = (categoryName: string) => {
    setAchievementCategories([
      ...achievementCategories,
      { value: `${achievementCategories.length + 1}`, label: categoryName }
    ])
    setNewCategory(categoryName, 2)
  }

  const getData = () => {
    getGoodsCategories()
  }

  const onSelectFile = ({ onChange }: any) => (event: any) => {
    const f = event.target.files[0]
    // @ts-ignore
    setSrcForView(window.URL.createObjectURL(f))
    imageToBlob(window.URL.createObjectURL(f), b =>
      // tslint:disable-next-line:no-console
      // @ts-ignore
      b ? setBlob(b) : console.log('blob is null')
    )
    // @ts-ignore
    setUserFileProps({ name: f.name, type: f.type })
    setIsImageLoad(true)
    onChange(event)
  }
  const appendValues = (values: any) => {
    const body = new FormData()
    body.append('name', values.name)
    body.append('categoryId', values.category.value)
    body.append('price', values.price)
    body.append('description', values.description)
    // @ts-ignore
    !!image && body.append('photo', image)

    createOrEditAchievement(body, match.params.id)
  }

  const handleModalSubmit = () => {
    appendValues(valuesFormData)
  }
  const handleSubmitForm = (values: any) => {
    appendValues(values)
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1>
            {isEdit
              ? words['admin.achievement.new.editAchievement']
              : words['admin.achievement.new.createAchievement']}
          </h1>
        </div>

        <div className={styles['block-go-back']}>
          <button
            type="button"
            className={styles['go-back']}
            onClick={event => {
              event.preventDefault()
              history.goBack()
            }}
          >
            <Icons icon="backArrowIcon" />
            <span className={styles['go-back-text']}>{words['back']}</span>
          </button>
        </div>

        <Form
          initialValues={
            isEdit && achievement
              ? {
                  name: achievement.name || '',
                  price: achievement.price || '',
                  description: achievement.description || '',
                  category:
                    {
                      value: achievement.categoryId && achievement.categoryId.id,
                      label: achievement.categoryId && achievement.categoryId.name
                    } || ''
                }
              : {}
          }
          onSubmit={handleSubmitForm}
          render={({ form, handleSubmit }) => {
            const formState = form.getState()
            return (
              <form onSubmit={handleSubmit}>
                <div className={styles['content-container']}>
                  <div className={styles['picture-container']}>
                    <Field
                      name={'photo'}
                      validate={
                        !isEdit
                          ? validation.composeValidators(
                              validation.required(words['user.requiredMessage'])
                            )
                          : undefined
                      }
                      render={({ input, meta }) => (
                        <div
                          className={cls({
                            [styles.picture]: true,
                            [styles.invalid]: meta.error && meta.touched
                          })}
                          style={{
                            backgroundImage: `url(${srcForView})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat'
                          }}
                          data-tip=""
                          data-for="photo"
                          id="photoError"
                        >
                          <div>
                            <label
                              htmlFor="photo"
                              className={isImageLoad ? styles.right : styles.center}
                            >
                              <AddImgIcon />
                              <input
                                {...input}
                                id="photo"
                                type="file"
                                onChange={onSelectFile(input)}
                              />
                            </label>

                            {meta.error && meta.touched && (
                              <ReactTooltip
                                id="photoError"
                                anchorSelect="#photoError"
                                place="top"
                                variant="error"
                                content={meta.error}
                              />
                            )}
                          </div>
                          {!srcForView && (
                            <div className={styles.info}>
                              {`${words['admin.shop.new.imageFormat']}: JPEG, JPG, PNG`}
                              <br />
                              {`${words['admin.shop.new.imageSize']}: 550 x 335 px`}
                              <br />
                              {`${words['admin.shop.new.imageWeight']}: ${
                                words['user.pointsSystem.market.table.to']
                              } 10 MB`}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className={styles.information}>
                    <div className={styles['information-row']}>
                      <Field
                        name="name"
                        validate={validation.composeValidators(
                          validation.required(words['user.requiredMessage']),
                          validation.minValue(2),
                          validation.maxValue(99)
                        )}
                        render={({ input, meta }) => (
                          <div className={styles['input-name']}>
                            <label className={styles['label-for-field']}>
                              {words['admin.productionCalendar.new.name']}
                              <RedNote />
                            </label>
                            <Input
                              {...input}
                              variant="outlined"
                              className={styles['input']}
                              isInvalid={meta.error && meta.touched}
                              errorMessage={meta.error}
                            />
                          </div>
                        )}
                      />
                    </div>
                    <div className={styles['information-row']}>
                      <Field
                        name={'category'}
                        validate={validation.composeValidators(
                          validation.required(words['user.requiredMessage'])
                        )}
                        render={({ input, meta }) => (
                          <div className={styles['input-categoryId']}>
                            <label className={styles['label-for-field']}>
                              {words['user.pointsSystem.market.table.category']}
                              <RedNote />
                            </label>
                            <Select
                              value={input.value}
                              inRef={newCategoryRef as any}
                              placeholder=""
                              options={achievementCategoriesForSelect()}
                              isAddNewItem={true}
                              isInvalid={meta.error && meta.touched}
                              onChange={event => {
                                return form.change(input.name, event)
                              }}
                              emptyMessage={words['noOption']}
                              errorMessage={meta.error}
                              addCategory={handleCatrgories}
                              getData={getData}
                              isClearable={true}
                            />
                          </div>
                        )}
                      />
                      <Field
                        name={'price'}
                        validate={validation.composeValidators(
                          validation.required(words['user.requiredMessage']),
                          validation.isNumber(),
                          validation.isInteger(),
                          validation.min(0),
                          validation.max(9999)
                        )}
                        render={({ input, meta }) => (
                          <div className={styles['input-price']}>
                            <label className={styles['label-for-field']}>
                              {words['user.profile.table.reward']}
                              <RedNote />
                            </label>
                            <Input
                              {...input}
                              variant="outlined"
                              type="number"
                              className={styles['input']}
                              isInvalid={meta.error && meta.touched}
                              errorMessage={meta.error}
                            />
                          </div>
                        )}
                      />
                    </div>
                    <div className={styles['information-row']}>
                      <Field
                        name={'description'}
                        validate={validation.composeValidators(
                          validation.required(words['user.requiredMessage']),
                          validation.maxValue(1000)
                        )}
                        render={({ input, meta }) => (
                          <div>
                            <label className={styles['label-for-field']}>
                              {words['admin.productionCalendar.new.description']}
                              <RedNote />
                            </label>
                            <Textarea
                              {...input}
                              className={styles['description']}
                              placeholder={`${
                                words['admin.achievement.new.descriptionPlaceholder']
                              }...`}
                              isInvalid={meta.error && meta.touched}
                              errorMessage={meta.error}
                            />
                          </div>
                        )}
                      />
                    </div>
                    <div className={styles['btn-container']}>
                      {isEdit && (
                        <Button
                          type="button"
                          disabled={false}
                          outline={true}
                          className={styles['btn']}
                          onClick={() => {
                            // @ts-ignore
                            setValuesFormData(formState.values)
                            toggleOpenCancelModal()
                          }}
                        >
                          {words['admin.productionCalendar.new.buttons.cancel']}
                        </Button>
                      )}

                      <Button type="submit" disabled={!formState.valid} className={styles['btn']}>
                        {isEdit
                          ? words['admin.productionCalendar.new.buttons.save']
                          : words['user.dayOffTracker.create']}
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            )
          }}
        />
      </div>
      <CancelModal
        open={openCancelModal}
        toggle={toggleOpenCancelModal}
        onHandleSubmit={handleModalSubmit}
      />
    </>
  )
}
