import React, { FC, InputHTMLAttributes, useRef, useState } from 'react'
import cls from 'classnames'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import PhoneInput from 'react-phone-input-2'

import styles from './InputPhoneNumber.module.scss'
import { startsWith } from 'lodash'

export type TBaseInputProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string
  disabled?: boolean
  errorMessage?: string
  isInvalid?: boolean
  name: string
  placeholder?: string
  rest?: any
  required?: boolean
  label?: string
  value?: string | number
  variant?: 'default' | 'outlined'
  withoutError?: boolean
  style?: any
}

export const InputPhoneNumber: FC<TBaseInputProps> = ({
  className = '',
  disabled,
  errorMessage,
  isInvalid,
  name,
  placeholder,
  label,
  variant = 'default',
  withoutError,
  required,
  value = '',
  rest,
  ...other
}) => {
  const isOutline = variant === 'outlined'
  const inputRef = useRef<HTMLInputElement>(null)
  const [tip, setTip] = useState('')

  return (
    <>
      {isOutline && label && (
        <label
          htmlFor={name}
          className={cls({
            [styles['label-outlined']]: true,
            [styles['label-outlined-required']]: required,
            [styles['label-outlined-error']]: isInvalid && errorMessage
          })}
        >
          {label && label}
        </label>
      )}
      <div
        className={cls({
          [styles.wrapper]: true,
          [className]: true,
          [styles['wrapper-outlined']]: isOutline,
          [styles['wrapper-outlined-required']]: isOutline && required,
          [styles['wrapper-outlined-disabled']]: isOutline && disabled,
          [styles['wrapper-outlined-error']]: isOutline && isInvalid && errorMessage,
          [styles['wrapper-required']]: required,
          [styles['wrapper-error']]: isInvalid && errorMessage,
          [styles['wrapper-disabled']]: disabled
        })}
        id={`wrapperId${name}`}
      >
        <PhoneInput
          disableDropdown
          inputProps={{
            name: name,
            id: name
          }}
          enableAreaCodes
          value={value}
          placeholder=""
          disabled={disabled}
          specialLabel=""
          isValid={(inputNumber: any, country: any, countries: any) => {
            if (country) {
              const a = country.format.split(' ')
              if (a.length) {
                a[0] = `+${country.dialCode}`
                const c = a.join(' ')
                setTip(c)
              }
              return false
            }

            return countries.some((someCountry: any) => {
              const checkValid =
                startsWith(inputNumber, someCountry.dialCode) ||
                startsWith(someCountry.dialCode, inputNumber)
              return checkValid
            })
          }}
          {...rest}
          {...other}
        />
        {typeof value === 'string' && !value.length && (
          <span
            onClick={() => {
              if (inputRef.current !== null) {
                inputRef.current.focus()
              }
            }}
            className={styles.label}
          >
            {placeholder}
          </span>
        )}
      </div>
      {!value && !withoutError && isInvalid && errorMessage && (
        <ReactTooltip
          id={name}
          anchorSelect={`#${name}`}
          place="bottom"
          variant="error"
          content={errorMessage}
          className={styles.tooltip}
        />
      )}
      {value && (
        <ReactTooltip
          id={name}
          anchorSelect={`#${name}`}
          place="bottom"
          variant="error"
          content={tip}
          className={styles.tooltipInfo}
        />
      )}
    </>
  )
}
