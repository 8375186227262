import { createContext } from 'react'

const superAdmin = 'superAdmin'
const admin = 'admin'
const manager = 'manager'
const user = 'user'
const ledgenManager = 'ledgenManager' // for technorely site
const teamMember = 'teamMember'

export const userRoles: { [key: string]: string } = {
  '1': superAdmin,
  '2': admin,
  '3': manager,
  '4': user,
  '5': ledgenManager,
  '6': teamMember
}

export type TUserDataWithoutTokens = {
  id: number
  email: string
  fullName: string
  balance: number
  role: number
  partOfService: string
  photo: string
  iat: number
  exp: number
}

export type TContextValue = {
  data?: TUserDataWithoutTokens
  superAdmin: boolean
  admin: boolean
  manager: boolean
  user: boolean
  teamMember: boolean
}

export const defaultContextValue: TContextValue = {
  superAdmin: false,
  admin: false,
  manager: false,
  user: false,
  teamMember: false
}

export const UserContext = createContext(defaultContextValue)

export const localStorageUserKey = 'user'
export const technorelyLanguage = 'TechnorelyLanguage'
export const isSuperAdmin = (role: string) => userRoles[role] === superAdmin
export const isAdmin = (role: string) => userRoles[role] === admin
export const isManager = (role: string) => userRoles[role] === manager
export const isUser = (role: string) => userRoles[role] === user
export const isTeamMember = (role: string) => userRoles[role] === teamMember
