import jwt_decode from 'jwt-decode'
import { IDecodedToken } from 'screens/auth/Login/Login.model'

export const jwtDecode = (token: string): IDecodedToken | null => {
  try {
    return jwt_decode(token)
  } catch (e) {
    return null
  }
}
