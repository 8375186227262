import React from 'react'
import { Field } from 'react-final-form'
import { useSelector } from 'react-redux'
import { TState } from 'store'

import { Icons } from 'components/Icons'
import { Input } from 'components/UiKit/Inputs'
import styles from '../../EditProfile.module.scss'
import style from './EditPointsSystem.module.scss'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { editPSConfig } from './EditPointsSystem.config'
import { EPointsEdit } from 'globalConfigs'
import validation from 'utils/validation'

export const EditPointsSytem: any = ({ form, isAdmin, user, formState }: any) => {
  const words = useSelector((state: TState) => state.global.language.words)
  console.log('***form=', form)
  console.log('***formState=', formState)
  console.log('***user=', user)

  return isAdmin ? (
    <>
      <section className={styles.section}>
        <h3>{words['admin.team.editPoints']}</h3>
        <div className={style['form-row']}>
          <div>
            <div className={style['input-label']}>
              {`${words['admin.settings.pointsSystem.balanceEdit.points']}:`}
              <span className={style['red-note']}>*</span>
            </div>
            <Field
              name="points"
              validate={validation.composeValidators(
                validation.required(words['user.requiredMessage']),
                validation.isNumber()
              )}
            >
              {({ input, meta }) => {
                console.log('***input=', input)
                console.log('***meta=', meta)
                return (
                  <div className={style['div-wrapper']}>
                    <button
                      onClick={() => {
                        if (
                          !formState.values.points ||
                          typeof formState.values.points !== 'number'
                        ) {
                          form.change('points', -EPointsEdit.STEP)
                        } else {
                          form.change('points', Number(formState.values.points) - EPointsEdit.STEP)
                        }
                      }}
                      type="button"
                    >
                      <Icons icon="remove" />
                    </button>
                    <div className={style['input-wrapper']}>
                      <Input
                        variant="outlined"
                        type="number"
                        isInvalid={meta.error && meta.submitFailed}
                        errorMessage={meta.error}
                        {...input}
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        if (
                          !formState.values.points ||
                          typeof formState.values.points !== 'number'
                        ) {
                          form.change('points', EPointsEdit.STEP)
                        } else {
                          form.change('points', Number(formState.values.points) + EPointsEdit.STEP)
                        }
                      }}
                    >
                      <Icons icon="add" />
                    </button>
                  </div>
                )
              }}
            </Field>
          </div>
          <div className={style['balance-info']}>
            {`${words['admin.users.table.balanceEdit.currentBalance']}:`}{' '}
            <span className={style['current-balance']}>{user ? user.balance : '0'}</span>
          </div>
        </div>

        <FieldFactory
          form={form}
          config={editPSConfig({
            words
          })}
          words={words}
        />
      </section>
    </>
  ) : (
    <></>
  )
}
