import { getProjects, getManagers } from './ForeignProfile.thunk'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import ForeignProfileComponent from './ForeignProfile'
import { TState } from 'store'
import { getBalance } from '../NewRequestTimeOff/NewRequestTimeOff.thunx'
import { getUserProfileInfo } from '../Profile/Profile.thunx'
import { getForeignProfile } from '../Team/Team.thunx'

export const mapStateToProps = (state: TState) => {
  return {
    user: state.auth.data,
    role: state.auth.decodedToken.role,
    profileInfo: state.client.profile.user,
    timeBalance: state.client.newRequestTimeOff.balance,
    foreinProfile: state.client.team.foreinProfile,
    projects: state.client.foreignProfile.projects,
    managers: state.client.foreignProfile.managers,
    projectsLoading: state.client.foreignProfile.loading
  }
}

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      getUserProfileInfo,
      getBalance,
      getForeignProfile,
      getProjects,
      getManagers
    },
    dispatch
  )

export const ForeignProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(ForeignProfileComponent)
