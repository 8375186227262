import {
  SET_ACCESS_TOKEN,
  SET_USER_DATA,
  SIGN_IN_CLEAR,
  PULLING_SUCCESS,
  PULLING_ERROR,
  CHANGE_USER_DAY_OF_BIRTH_REQUEST,
  CHANGE_USER_DAY_OF_BIRTH_SUCCESS,
  CHANGE_USER_DAY_OF_BIRTH_ERROR,
  CHANGE_USER_CITY_REQUEST,
  CHANGE_USER_CITY_SUCCESS,
  CHANGE_USER_CITY_ERROR,
  CHANGE_USER_POINTS_TRANSFER_SUCCESS,
  CHANGE_USER_POINTS_TRANSFER_ERROR,
  SET_USER_AVATAR,
  SET_USER_VERIFICATION,
  SET_ACCEPT_POLICY,
  toggleBarOptions,
  SET_SELECTED_ROLE
} from './Login.constants'
import { ILoginInitialState } from './Login.model'
import { IAction } from 'models'
import { TState } from 'store'
import moment from 'moment'

export const loginInitialState: ILoginInitialState = {
  role: toggleBarOptions[0].value,
  data: {},
  status: 0,
  error: '',
  avatar: '',
  token: null,
  decodedToken: {
    allowedRoles: [],
    role: '',
    sub: '',
    email: '',
    exp: 0
  }
}

export const getDataSelector = (state: TState) => state.auth.data
export const getErrorSelector = (state: TState) => state.auth.error

export default (state = { ...loginInitialState }, action: IAction) => {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        decodedToken: action.payload.decodedToken
      }
    case SET_USER_DATA:
      return {
        ...state,
        data: action.payload,
        loading: false
      }

    case SIGN_IN_CLEAR:
      return {
        ...loginInitialState
      }

    case PULLING_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload
        }
      }
    }

    case PULLING_ERROR: {
      return {
        ...state,
        error: action.payload
      }
    }

    case CHANGE_USER_DAY_OF_BIRTH_REQUEST:
      return {
        ...state,
        loading: true
      }

    case CHANGE_USER_DAY_OF_BIRTH_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          dateOfBirth: moment(action.payload.dateOfBirth).format('MM-DD-YYYY')
        },
        loading: false
      }

    case CHANGE_USER_DAY_OF_BIRTH_ERROR:
      return {
        ...state,
        error: action.payload.data,
        loading: false
      }

    case CHANGE_USER_CITY_REQUEST:
      return {
        ...state,
        loading: true
      }

    case CHANGE_USER_CITY_SUCCESS: {
      return {
        ...state,
        data: { ...state.data, city: action.payload.city },
        loading: false
      }
    }

    case CHANGE_USER_CITY_ERROR:
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }

    case CHANGE_USER_POINTS_TRANSFER_SUCCESS: {
      return {
        ...state
      }
    }

    case CHANGE_USER_POINTS_TRANSFER_ERROR: {
      return {
        ...state
      }
    }

    case SET_USER_AVATAR: {
      return {
        ...state,
        avatar: action.payload
      }
    }

    case SET_USER_VERIFICATION: {
      return {
        ...state,
        data: { ...state.data, ...action.payload }
      }
    }

    case SET_ACCEPT_POLICY: {
      return {
        ...state,
        data: { ...state.data, acceptPolicy: action.payload }
      }
    }

    case SET_SELECTED_ROLE: {
      return {
        ...state,
        role: action.payload
      }
    }

    default:
      return state
  }
}
