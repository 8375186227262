import { History } from 'history'

const checkActiveLink = ({
  link,
  history,
  tab
}: {
  link: string
  history: History
  tab?: string
}) => {
  return tab
    ? link.includes(history.location.pathname) // && history.location.search.includes(tab)
    : history.location.pathname.includes(link.split('?')[0])
}

export { checkActiveLink }
