import { Dispatch } from 'redux'
import { isTaskInTransaction } from './web3.action'
import getWeb3 from 'getWeb3'
import { getContractAbi } from '../../../utils/getContractAbi'
import { getHashSignature } from 'utils/getHashSignature'
import { EContractAddress, address0 } from 'globalConfigs'
import { api, API } from 'services/api'
import { getMessageHash } from 'utils/getMessageHash'
import toastr, { EToastrTypeMessage } from 'utils/toastr'
import { AbiItem } from 'web3-utils'
import { EDealStatus, IDeal } from './web3.model'
import { changeTaskStatus } from '../Subcontract/Subcontract.actions'
import { ETaskStatus } from '../Subcontract/components/CreateTask/CreateTask.model'
import { ETransactionMessage } from 'components/Spinner/types'
import { TState } from 'store'
import { permit } from 'utils/permit'

export const activateMetaMask = async (
  setIsActiveWallet: (value: boolean) => void
): Promise<void> => {
  if (typeof window !== 'undefined' && window.ethereum) {
    try {
      await window.ethereum.request({
        method: 'eth_requestAccounts'
      })
      setIsActiveWallet(true)
    } catch (error) {
      console.log(error)
    }
  } else {
    console.log('MetaMask is not installed.')
  }
}

export const acceptTask = (task: any) => async (dispatch: Dispatch, getData: () => TState) => {
  const words = getData().global.language.words
  const web3 = await getWeb3()
  const { taskHash } = task

  try {
    const addresses = await web3.eth.getAccounts()
    const acceptTaskSignature = await getHashSignature(addresses[0], taskHash)
    const contractAbi: AbiItem[] = await getContractAbi(EContractAddress.FIX_DEAL_ESCROW)
    const contractInstance = new web3.eth.Contract(contractAbi, EContractAddress.FIX_DEAL_ESCROW)

    const updatedTask = {
      ...task,
      acceptTaskSignature,
      status: { id: ETaskStatus.COMPLETED },
      dealStatus: EDealStatus.DONE,
      finishedAt: new Date().toISOString()
    }

    const gasPrice = await web3.eth.getGasPrice()
    const gasLimit = await contractInstance.methods
      .acceptTask(taskHash, acceptTaskSignature)
      .estimateGas({ from: addresses[0] })

    await contractInstance.methods
      .acceptTask(taskHash, acceptTaskSignature)
      .send({
        from: addresses[0],
        gasPrice,
        gas: gasLimit
      })
      .on('transactionHash', () => {
        dispatch(isTaskInTransaction(true))
        toastr(EToastrTypeMessage.INFO, words[`${ETransactionMessage.TRANSACTION_MESSAGE}`])
      })
      .on('receipt', async () => {
        await api.put(`${API.URL}/tasks/${task.id}`, updatedTask)
        toastr(EToastrTypeMessage.SUCCESS, words[`${ETransactionMessage.TRANSACTION_SUCCESS}`])
      })
      .on('error', () => {
        toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
        dispatch(isTaskInTransaction(false))
      })

    dispatch(changeTaskStatus(updatedTask))
  } catch (err) {
    console.log(err)
  } finally {
    dispatch(isTaskInTransaction(false))
  }
}

export const signTaskThunk = (task: any, history: any) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const words = getData().global.language.words
  const { title, description, id } = task
  const taskPrice = Number(task.price) || 0
  const web3 = await getWeb3()

  const taskHash = getMessageHash([id, title, description, taskPrice, EContractAddress.TRL_TOKEN])

  try {
    const addresses = await web3.eth.getAccounts()

    if (!addresses[0]) {
      toastr('error', 'Please, connect metamask')
    }

    const contractAbi: AbiItem[] = await getContractAbi(EContractAddress.FIX_DEAL_ESCROW)
    const contractInstance = new web3.eth.Contract(contractAbi, EContractAddress.FIX_DEAL_ESCROW)
    const signTaskSignature = await getHashSignature(addresses[0], taskHash)

    const { data } = await api.get(`${API.URL}/tokens/${task.currency}`)
    const taskCurrency = data.address || address0

    const gasPrice = await web3.eth.getGasPrice()

    const gasLimit = await contractInstance.methods
      .signTask(taskHash, taskCurrency, taskPrice, address0, signTaskSignature, task.isRepeatable)
      .estimateGas({ from: addresses[0] })

    const result = contractInstance.methods
      .signTask(taskHash, taskCurrency, taskPrice, address0, signTaskSignature, task.isRepeatable)
      .send({
        from: addresses[0],
        gasPrice,
        gas: gasLimit
      })

    const updatedTask = {
      ...task,
      dealStatus: EDealStatus.CREATED,
      status: {
        id: ETaskStatus.CREATED
      },
      signTaskSignature,
      taskHash
    }

    await result
      .on('transactionHash', () => {
        dispatch(isTaskInTransaction(true))
        toastr(EToastrTypeMessage.INFO, words[`${ETransactionMessage.TRANSACTION_MESSAGE}`])
      })
      .on('receipt', async () => {
        await api.put(`${API.URL}/tasks/${id}`, updatedTask)
        history.push('/dashboard/subcontract?page=1&task=all-tasks')
        toastr(EToastrTypeMessage.SUCCESS, words[`${ETransactionMessage.TRANSACTION_SUCCESS}`])
      })
      .on('error', () => {
        toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
        dispatch(isTaskInTransaction(false))
      })
  } catch (error) {
    console.log(error)
    toastr('error', 'Что-то пошло не так :(')
  } finally {
    dispatch(isTaskInTransaction(false))
  }
}

//TODO rework methods approveThunk

// const approveThunk = (proposal: any) => async (dispatch: Dispatch, getData: () => TState) => {
//   const web3 = await getWeb3()
//   const words = getData().global.language.words

//   try {
//     const addresses = await web3.eth.getAccounts()
//     const contractAbi: AbiItem | AbiItem[] = await getContractAbi(EContractAddress.TRL_TOKEN)
//     const contractInstance = new web3.eth.Contract(contractAbi, EContractAddress.TRL_TOKEN)
//     const gasPrice = await web3.eth.getGasPrice()
//     const gasLimit = await contractInstance.methods
//       .approve(EContractAddress.FIX_DEAL_ESCROW, proposal.budget)
//       .estimateGas({ from: addresses[0], value: proposal.budget })

//     await contractInstance.methods
//       .approve(EContractAddress.FIX_DEAL_ESCROW, proposal.budget)
//       .send({ from: addresses[0], gasPrice, gas: gasLimit })
//       .on('transactionHash', () => {
//         dispatch(isTaskInTransaction(true))
//         toastr(EToastrTypeMessage.INFO, words[`${ETransactionMessage.TRANSACTION_MESSAGE}`])
//       })
//       .on('error', (err: Error) => {
//         toastr('error', 'Что-то пошло не так :(')
//         dispatch(dispatch(isTaskInTransaction(false)))
//         console.log(err)
//       })
//   } catch (err) {
//     console.log(err)
//   } finally {
//     dispatch(dispatch(isTaskInTransaction(false)))
//   }
// }

export const transferTokensToContract = (task: any, history: any) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const words = getData().global.language.words
  const web3 = await getWeb3()
  const { taskHash } = task

  try {
    const addresses = await web3.eth.getAccounts()
    const transferTokensToContractSignature = await getHashSignature(addresses[0], taskHash)

    const contractAbi: AbiItem[] = await getContractAbi(EContractAddress.FIX_DEAL_ESCROW)
    const contractInstance = new web3.eth.Contract(contractAbi, EContractAddress.FIX_DEAL_ESCROW)
    const deal: IDeal = await contractInstance.methods.deals(taskHash).call()

    const gasPrice = await web3.eth.getGasPrice()
    const taskAmount = deal.proposals[deal.chousedProposal as any].amount

    const gasLimit = await contractInstance.methods
      .deposit(taskHash, transferTokensToContractSignature)
      .estimateGas({
        from: addresses[0],
        value: taskAmount
      })

    const updatedTask = {
      ...task,
      transferTokensToContractSignature,
      status: {
        id: ETaskStatus.IN_WORK
      },
      dealStatus: EDealStatus.IN_PROGRESS
    }

    await contractInstance.methods
      .deposit(taskHash, transferTokensToContractSignature)
      .send({
        from: addresses[0],
        value: taskAmount,
        gasPrice,
        gas: gasLimit
      })
      .on('transactionHash', () => {
        dispatch(isTaskInTransaction(true))
        toastr(EToastrTypeMessage.INFO, words[`${ETransactionMessage.TRANSACTION_MESSAGE}`])
      })
      .on('receipt', async () => {
        await api.put(`${API.URL}/tasks/${task.id}`, updatedTask)
        toastr(EToastrTypeMessage.SUCCESS, words[`${ETransactionMessage.TRANSACTION_SUCCESS}`])

        history.push('/dashboard/subcontract?task=all-tasks&page=1&fromPrice=')
      })
      .on('error', () => {
        toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
        dispatch(isTaskInTransaction(false))
      })

    return true
  } catch (error) {
    console.log(error)

    return false
  } finally {
    dispatch(isTaskInTransaction(false))
  }
}

export const acceptProposalThunk = (task: any, proposal: any) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const words = getData().global.language.words
  const web3 = await getWeb3()
  const { taskHash } = task
  const amount = proposal.budget

  try {
    const contractorAddress = await web3.eth.personal.ecRecover(
      taskHash,
      proposal.makeProposalSignature
    )

    const addresses = await web3.eth.getAccounts()
    let acceptProposalSignature: string
    const contractAbi: AbiItem[] = await getContractAbi(EContractAddress.FIX_DEAL_ESCROW)
    const contractInstance = new web3.eth.Contract(contractAbi, EContractAddress.FIX_DEAL_ESCROW)

    const deal: IDeal = await contractInstance.methods.deals(taskHash).call()

    const gasPrice = await web3.eth.getGasPrice()
    const proposalId = deal.proposals.findIndex(
      currentProposal => currentProposal.from.toLowerCase() === contractorAddress
    )

    const updatedTask = {
      ...task,
      acceptProposalSignature: null,
      status: {
        id: ETaskStatus.ACCEPTED
      },
      dealStatus: EDealStatus.ACCEPTED,
      price: amount
    }

    const { data } = await api.get(`${API.URL}/tokens/${task.currency}`)
    const taskCurrency = data.address || address0

    if (taskCurrency === address0) {
      acceptProposalSignature = await getHashSignature(addresses[0], taskHash)
      const gasLimit = await contractInstance.methods
        .acceptProposal(proposalId, taskHash, acceptProposalSignature)
        .estimateGas({ from: addresses[0] })

      updatedTask.acceptProposalSignature = acceptProposalSignature

      await contractInstance.methods
        .acceptProposal(proposalId, taskHash, acceptProposalSignature)
        .send({
          from: addresses[0],
          gasPrice,
          gas: gasLimit
        })
        .on('transactionHash', () => {
          dispatch(isTaskInTransaction(true))
          toastr(EToastrTypeMessage.INFO, words[`${ETransactionMessage.TRANSACTION_MESSAGE}`])
        })
        .on('receipt', async () => {
          await api.put(`${API.URL}/tasks/${task.id}`, updatedTask)
          toastr(EToastrTypeMessage.SUCCESS, words[`${ETransactionMessage.TRANSACTION_SUCCESS}`])
        })
        .on('error', () => {
          toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
          dispatch(isTaskInTransaction(false))
        })
    } else {
      const deadline = Math.floor(Date.now() / 1000) + 3600 * 24 * 365 // one year
      const abi: AbiItem[] = await getContractAbi(taskCurrency)
      const isPermit = abi.some(item => item.name === 'permit')
      const tokenContractInstance = new web3.eth.Contract(abi, taskCurrency)
      const INITIAL_INT = web3.utils.toWei('1000000', 'ether')
      const allowance = Number(
        await tokenContractInstance.methods
          .allowance(addresses[0], EContractAddress.FIX_DEAL_ESCROW)
          .call()
      )

      if (allowance < amount && isPermit && amount < INITIAL_INT) {
        const nonce = await tokenContractInstance.methods.nonces(addresses[0]).call()

        await permit(
          tokenContractInstance,
          addresses[0],
          EContractAddress.FIX_DEAL_ESCROW,
          deadline,
          nonce,
          gasPrice,
          INITIAL_INT,
          dispatch,
          words
        )
      }

      acceptProposalSignature = await getHashSignature(addresses[0], taskHash)
      updatedTask.acceptProposalSignature = acceptProposalSignature

      const gasLimit = await contractInstance.methods
        .acceptProposal(proposalId, taskHash, acceptProposalSignature)
        .estimateGas({ from: addresses[0] })

      await contractInstance.methods
        .acceptProposal(proposalId, taskHash, acceptProposalSignature)
        .send({
          from: addresses[0],
          gasPrice,
          gas: gasLimit
        })
        .on('transactionHash', () => dispatch(isTaskInTransaction(true)))
        .on('receipt', async () => {
          await api.put(`${API.URL}/tasks/${task.id}`, updatedTask)
          toastr(EToastrTypeMessage.SUCCESS, words[`${ETransactionMessage.TRANSACTION_SUCCESS}`])
        })
        .on('error', () => {
          toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
          dispatch(isTaskInTransaction(false))
        })
    }
  } catch (err) {
    console.log(err)
  } finally {
    dispatch(isTaskInTransaction(false))
  }
}
