import React from 'react'
import { SetColumns } from 'components/UiKit/Table/components/SetColumns'
import { FormatCell } from 'components/UiKit/Table/components/FormatCell'
import { IRow, TGetColumns } from './TeamAdmin.model'
//@ts-ignore
export const getTeamColumns: TGetColumns = (words, checkedItems, deleted) => {
  const currentLang = localStorage.getItem('TechnorelyLanguage')
  const columns = [
    {
      Header: (
        <SetColumns checkedItems={checkedItems} bottom={deleted === 'true' ? '-322px' : '-288px'} />
      ),
      accessor: 'i',
      width: 20
    },
    {
      Header: words['admin.users.table.filters.userName'],
      accessor: 'fullName',
      minWidth: 180
    },
    {
      Header: words['user.profile.career.position'],
      accessor: 'position',
      minWidth: currentLang !== 'en' ? 180 : 130
    },
    {
      Header: words['user.profile.career.level'],
      accessor: 'rank',
      width: 100
    },
    {
      Header: words['user.profile.mainInfo.location'],
      accessor: 'city',
      width: 100
    },
    {
      Header: words['user.profile.career.department'],
      accessor: 'department',
      width: 110
    },
    {
      Header: words['user.profile.career.occupation'],
      accessor: 'typeOfEmployment',
      minWidth: 150
    },
    {
      Header: words['user.profile.career.workingHours'],
      accessor: 'workingHoursPerDay',
      Cell: ({ row }: IRow) => (
        <FormatCell content={row.original.workingHoursPerDay} customClass="formatCell" />
      ),
      minWidth: 185
    },
    {
      Header: words['user.profile.career.hiringDate'],
      accessor: 'firstWorkingDay',
      minWidth: currentLang !== 'en' ? 200 : 130
    },
    {
      Header: words['user.profile.career.probationEndDate'],
      accessor: 'probationEndDate',
      minWidth: currentLang !== 'en' ? 165 : 150
    },
    {
      Header: <FormatCell content={words['user.profile.career.salary']} customClass="formatCell" />,
      accessor: 'salary',
      Cell: ({ row }: IRow) => (
        <FormatCell content={row.original.salary} customClass="formatCell" />
      ),
      minWidth: 145
    },
    {
      Header: words['user.profile.mainInfo.dob'],
      accessor: 'dateOfBirth',
      minWidth: 130
    },
    {
      Header: words['user.profile.mainInfo.martialStatus'],
      accessor: 'maritalStatus',
      minWidth: currentLang !== 'en' ? 190 : 150
    },
    {
      Header: words['user.profile.mainInfo.numberOfChildren'],
      accessor: 'numberOfChildren',
      Cell: ({ row }: IRow) => (
        <FormatCell content={row.original.numberOfChildren} customClass="formatCell" />
      ),
      minWidth: 155
    },
    {
      Header: words['user.profile.career.englishLevel'],
      accessor: 'englishLevel',
      minWidth: 180
    }
  ]

  if (deleted === 'true') {
    columns.splice(
      4,
      0,
      {
        Header: words['admin.team.terminationDate'],
        accessor: 'terminationDate',
        minWidth: 170
      },
      {
        Header: words['admin.team.terminationInitiator'],
        accessor: 'terminationInitiator',
        minWidth: 230
      }
    )
  }

  return columns
}
