import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from './store'
import history from './utils/history'
import './assets/style/_global.scss'
import 'toastr/build/toastr.min.css'
import './utils/locale/i18n'
import Spinner from './components/Spinner'
import uk from 'date-fns/locale/uk'
import ru from 'date-fns/locale/ru'
import { registerLocale } from 'react-datepicker'
import { config } from 'globalConfigs'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'

import { WagmiProvider } from 'wagmi'
import { bscTestnet } from 'wagmi/chains'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Auth0ProviderWithHistory } from 'auth0-provider-with-history'
import App from 'elements/App'

registerLocale('ua', uk) // Need for datepicker
registerLocale('ru', ru) // Need for datepicker
// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'))

// 0. Setup queryClient
const queryClient = new QueryClient()

// 1. Get projectId from https://cloud.walletconnect.com
const projectId = config.walletConnectProjectId

// 2. Create wagmiConfig
const metadata = {
  name: 'Platform',
  description: 'Platform',
  url: 'https://web3modal.com', // origin must match your domain & subdomain
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [bscTestnet] as const
export const wagmiConfig = defaultWagmiConfig({
  chains,
  projectId,
  metadata
})

// 3. Create modal
createWeb3Modal({
  wagmiConfig,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
  enableOnramp: true // Optional - false as default
})

root.render(
  <WagmiProvider config={wagmiConfig}>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate
          loading={
            <div style={{ margin: '75px auto' }}>
              <Spinner />
            </div>
          }
          persistor={persistor}
        >
          <Router history={history}>
            <Auth0ProviderWithHistory>
              <App />
            </Auth0ProviderWithHistory>
          </Router>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </WagmiProvider>
)
