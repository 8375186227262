import React, { FC, useEffect, useState } from 'react'
import Modal from 'components/Modal'
import { Field, Form } from 'react-final-form'
import styles from './UsersModalTimeEditForm.module.scss'
import cls from 'classnames'
import { Button } from 'components/UiKit/Button'
import { Input } from 'components/UiKit/Inputs'
import { TBalance, TUsersModalTimeEditForm } from './UsersModalTimeEditForm.model'
import { formattingObjectValuesToNumber } from 'utils/formatting'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { DatePicker } from 'components/UiKit/DatePicker'
import { config } from 'globalConfigs'
import { addCurrentTime, formatterDateForDatePicker } from 'components/UiKit/Filter/Filter'
import moment from 'moment'

export const UsersModalTimeEditForm: FC<TUsersModalTimeEditForm> = ({
  open,
  toggleTimeEditModal,
  id,
  balance,
  updateCurrentTimeBalance,
  user,
  updateUserSuccess
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)
  const [trackerStatus, setTrackerStatus] = useState(false)

  const initialValues = {
    dayOffBalance: balance.dayOffBalance ? parseFloat(balance.dayOffBalance.toFixed(3)) : 0,
    dayOffLimit: balance.dayOffLimit,
    totalRemote: balance.totalRemote,
    remoteLimit: balance.remoteLimit,
    totalSick: balance.totalSick,
    sickLimit: balance.sickLimit,
    isTerminating: user && user.firstTerminatingDay ? true : false,
    firstTerminatingDay:
      user && user.firstTerminatingDay
        ? formatterDateForDatePicker(moment(user.firstTerminatingDay).valueOf())
        : null
  }

  useEffect(() => {
    user && user.firstTerminatingDay ? setTrackerStatus(true) : setTrackerStatus(false)
  }, [user])

  return (
    <Modal
      isShow={open}
      onClose={() => {
        setTrackerStatus(user && user.firstTerminatingDay ? true : false)
        toggleTimeEditModal()
      }}
      className={`${styles.modal}`}
    >
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          if (id === null) {
            return
          }
          const body = formattingObjectValuesToNumber<TBalance>(values as TBalance)
          updateCurrentTimeBalance(id, body)

          const userBody = { ...user }
          userBody.firstTerminatingDay = values.firstTerminatingDay
          updateUserSuccess(userBody)
        }}
      >
        {({ form, handleSubmit }) => {
          const formState = form.getState()
          return (
            <form onSubmit={handleSubmit} className={styles.form}>
              <div
                className={cls({
                  [styles['form-row']]: true,
                  [styles['form-row-header']]: true
                })}
              >
                <header>{words['admin.users.table.Edit.editTime']}</header>
              </div>
              <div className={styles['form-row']}>
                <span className={styles['header-type']} />
                <span
                  className={cls({
                    [styles['header-balance']]: true,
                    [styles['header-balance-margin']]: true
                  })}
                >
                  {words['admin.users.table.balanceEdit.currentBalance']}
                </span>
                <span className={styles['header-balance']}>
                  {words['admin.users.table.Edit.maximalValue']}
                </span>
              </div>

              <div className={styles['form-row']}>
                <span className={styles['header-type']}> Day-off </span>
                <div className={styles['current-balance']}>
                  <Field name="dayOffBalance">
                    {({ input }) => {
                      return (
                        <div className={styles['div-wrapper']}>
                          <Input
                            variant="outlined"
                            type="number"
                            min={-formState.values.dayOffLimit}
                            {...input}
                          />
                        </div>
                      )
                    }}
                  </Field>
                </div>
                <div>
                  <Field name="dayOffLimit">
                    {({ input }) => {
                      return (
                        <div className={styles['div-wrapper']}>
                          <Input variant="outlined" type="number" min={0} {...input} />
                        </div>
                      )
                    }}
                  </Field>
                </div>
              </div>
              <div className={styles['form-row']}>
                <span className={styles['header-type']}> Remote </span>
                <div className={styles['current-balance']}>
                  <Field name="totalRemote">
                    {({ input }) => {
                      return (
                        <div className={styles['div-wrapper']}>
                          <Input variant="outlined" type="number" min={0} {...input} />
                        </div>
                      )
                    }}
                  </Field>
                </div>
                <div>
                  <Field name="remoteLimit">
                    {({ input }) => {
                      return (
                        <div className={styles['div-wrapper']}>
                          <Input variant="outlined" type="number" min={0} {...input} />
                        </div>
                      )
                    }}
                  </Field>
                </div>
              </div>
              <div className={styles['form-row']}>
                <span className={styles['header-type']}> Sick </span>
                <div className={styles['current-balance']}>
                  <Field name="totalSick">
                    {({ input }) => {
                      return (
                        <div className={styles['div-wrapper']}>
                          <Input variant="outlined" type="number" min={0} {...input} />
                        </div>
                      )
                    }}
                  </Field>
                </div>
                <div>
                  <Field name="sickLimit">
                    {({ input }) => {
                      return (
                        <div className={styles['div-wrapper']}>
                          <Input variant="outlined" type="number" min={0} {...input} />
                        </div>
                      )
                    }}
                  </Field>
                </div>
              </div>
              <div className={`${styles['form-row']} ${styles['time-tracker']}`}>
                <div className={`${styles['current-balance']} ${styles['time-tracker']}`}>
                  <Field name="isTerminating" type="checkbox">
                    {({ input }) => {
                      return (
                        <div className={`${styles['div-wrapper']} ${styles['checkbox-container']}`}>
                          <div className={styles['checkbox-container']}>
                            <Input
                              id="stop-timer-checkbox"
                              variant="outlined"
                              type="checkbox"
                              onClick={() => {
                                setTrackerStatus(!trackerStatus)
                                if (trackerStatus) {
                                  form.change('firstTerminatingDay', null)
                                } else if (user && user.firstTerminatingDay) {
                                  form.change(
                                    'firstTerminatingDay',
                                    formatterDateForDatePicker(
                                      moment(user.firstTerminatingDay).valueOf()
                                    )
                                  )
                                } else {
                                  form.change('firstTerminatingDay', moment().valueOf())
                                }
                              }}
                              {...input}
                            />
                            <label
                              htmlFor="stop-timer-checkbox"
                              className={styles['checkbox-label']}
                            >
                              {words['admin.users.table.Edit.stopTimer']}
                            </label>
                          </div>
                        </div>
                      )
                    }}
                  </Field>
                </div>
                <div>
                  <Field name="firstTerminatingDay">
                    {({ input }) => {
                      return (
                        <div className={`${styles['div-wrapper']} ${styles['time-tracker']}`}>
                          <label className={styles['field-label']}>
                            {words['admin.users.table.Edit.stopTimerDate']}
                          </label>
                          <DatePicker
                            disabled={!trackerStatus}
                            locale={currentLanguage}
                            name={input.name}
                            autoComplete="off"
                            dateFormat={config.dateFormat}
                            value={trackerStatus ? input.value : ''}
                            selected={input.value && trackerStatus ? new Date(input.value) : null}
                            onChange={event => {
                              if (event) {
                                form.change(
                                  input.name,
                                  formatterDateForDatePicker(addCurrentTime(event))
                                )
                              }
                            }}
                          />
                        </div>
                      )
                    }}
                  </Field>
                </div>
              </div>

              <div
                className={cls({
                  [styles['form-row']]: true,
                  [styles['form-row-btn']]: true
                })}
              >
                <Button type="submit" className={styles['div-wrapper']}>
                  {words['admin.productionCalendar.new.buttons.save']}
                </Button>
              </div>
            </form>
          )
        }}
      </Form>
    </Modal>
  )
}
