import React, { FC } from 'react'
import styles from './LoginLottie.module.scss'
import UncontrolledLottie from 'elements/Lotties/UncontrolledLottie'

export const LoginLottie: FC = () => {
  return (
    <div className={styles.loadingDiv}>
      <UncontrolledLottie />
    </div>
  )
}
