export const SET_TIME_OFF_PERIOD_REQUEST = 'SET_TIME_OFF_PERIOD_REQUEST'
export const SET_TIME_OFF_PERIOD_SUCCESS = 'SET_TIME_OFF_PERIOD_SUCCESS'
export const SET_TIME_OFF_PERIOD_ERROR = 'SET_TIME_OFF_PERIOD_ERROR'

export const GET_MANAGER_REQUEST = 'GET_MANAGER_REQUEST'
export const GET_MANAGER_SUCCESS = 'GET_MANAGER_SUCCESS'
export const GET_MANAGER_ERROR = 'GET_MANAGER_ERROR'

export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_ERROR = 'GET_USER_ERROR'

export const GET_CURRENT_REQUEST_REQUEST = 'GET_CURRENT_REQUEST_REQUEST'
export const GET_CURRENT_REQUEST_SUCCESS = 'GET_CURRENT_REQUEST_SUCCESS'
export const GET_CURRENT_REQUEST_ERROR = 'GET_CURRENT_REQUEST_ERROR'

export const GET_BALANCE_REQUEST = 'GET_BALANCE_REQUEST'
export const GET_BALANCE_SUCCESS = 'GET_BALANCE_SUCCESS'
export const GET_BALANCE_ERROR = 'GET_BALANCE_ERROR'

export const UPDATE_CURRENT_TIME_BALANCE_REQUEST = 'UPDATE_CURRENT_TIME_BALANCE_REQUEST'
export const UPDATE_CURRENT_TIME_BALANCE_SUCCESS = 'UPDATE_CURRENT_TIME_BALANCE_SUCCESS'
export const UPDATE_CURRENT_TIME_BALANCE_ERROR = 'UPDATE_CURRENT_TIME_BALANCE_ERROR'

export const GET_PRODUCT_CALENDAR_DAYS_REQUEST = 'GET_PRODUCT_CALENDAR_DAYS_REQUEST'
export const GET_PRODUCT_CALENDAR_DAYS_SUCCESS = 'GET_PRODUCT_CALENDAR_DAYS_SUCCESS'
export const GET_PRODUCT_CALENDAR_DAYS_ERROR = 'GET_PRODUCT_CALENDAR_DAYS_ERROR'

export const GET_ACTIVE_TEAM_MEMBERS_LIST = 'GET_ACTIVE_TEAM_MEMBERS_LIST'
