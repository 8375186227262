import Modal from 'components/Modal'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { Field, Form } from 'react-final-form'
import { Button } from 'components/UiKit/Button'
import { TUsersModalEditForm } from './UsersModalEditForm.model'
import { Select } from 'Select'
import { config, FILTER_SELECT_ROLES_FOR_USERS } from 'globalConfigs'
import { TUpdateUserPayload, TInitialValues } from '../../Users.model'
import styles from './UsersModalEditForm.module.scss'
import { TSelectOption } from 'Select/Select.model'
import { Input } from 'components/UiKit/Inputs'
import { DatePicker } from 'components/UiKit/DatePicker'
import cls from 'classnames'
import { addCurrentTime, formatterDateForDatePicker } from 'components/UiKit/Filter/Filter'
import validation from 'utils/validation'
import { useSelector } from 'react-redux'
import { TState } from 'store'

const managerKey = '3'
const userKey = '4'

export const UsersModalEditForm: FC<TUsersModalEditForm> = ({
  dataManagers,
  // dataPositions,
  openEditModal,
  toggleEditModal,
  updateUser,
  id,
  user
}) => {
  const [managersData, setManagersData] = useState<TSelectOption[]>([{ value: '', label: '' }])
  const words = useSelector((state: TState) => state.global.language.words)
  const positions = useSelector((state: TState) => state.client.editProfile.positions)
  const currentLanguage = useSelector((state: TState) => state.global.language.currentLanguage)
  // const [positionsData, setPositionsData] = useState<TSelectOption[]>([{ value: '', label: '' }])  // todo - needed to autoselect

  useEffect(() => {
    const filteredData: TSelectOption[] =
      dataManagers &&
      dataManagers.map(item => ({
        value: `${item.id}`,
        label: item.fullName
      }))

    setManagersData(filteredData)
  }, [dataManagers])

  // todo - needed to autoselect
  // useEffect(() => {
  //   const filteredData = dataPositions.map(item => ({
  //     value: `${item.id}`,
  //     label: item.name
  //   }))
  //   setPositionsData(filteredData)
  // }, [dataPositions])

  const getInitialValues = () => {
    return {
      fullName: user && user.fullName,
      firstWorkingDay: formatterDateForDatePicker(addCurrentTime(user && user.firstWorkingDay)),
      workingHoursPerDay: user && user.workingHoursPerDay,
      email: user && user.email,
      position: user && user.position && user.position,
      userPosition: user && user.userPosition && user.userPosition.id,
      reviewerId: user && user.reviewerId && user.reviewerId.id,
      roles:
        user && user.roles
          ? user.roles.map(item => {
              return FILTER_SELECT_ROLES_FOR_USERS(words).find(
                predicate => predicate.value === item
              )
            })
          : null
    }
  }
  const userData = useMemo(() => getInitialValues(), [openEditModal])
  return (
    <Modal isShow={openEditModal} onClose={toggleEditModal} className={styles.modal}>
      <Form
        initialValues={userData}
        onSubmit={values => {
          if (id === null) {
            return
          }

          const roles: string[] = values && (values as TInitialValues).roles.map(item => item.value)
          const reviewerId = Number((values as TInitialValues).reviewerId)
          const body: TUpdateUserPayload = {
            id,
            ...(values as TInitialValues),
            reviewerId: reviewerId !== 0 ? reviewerId : null,
            roles
          }
          updateUser(body)
        }}
      >
        {({ form, handleSubmit }) => {
          const formState = form.getState()
          return (
            <form onSubmit={handleSubmit} className={styles.form}>
              <div
                className={cls({
                  [styles['form-row']]: true,
                  [styles['form-row-header']]: true
                })}
              >
                <header>{words['admin.users.table.Edit.header']}</header>
              </div>
              <div className={styles['form-row']}>
                <Field name="fullName">
                  {({ input }) => {
                    return (
                      <div className={styles.left}>
                        <Input
                          variant="outlined"
                          label={words['admin.users.table.Edit.name']}
                          placeholder={words['admin.users.table.Edit.name']}
                          {...input}
                        />
                      </div>
                    )
                  }}
                </Field>

                <Field name="firstWorkingDay">
                  {({ input }) => {
                    return (
                      <div className={styles.workDay}>
                        <label className={styles['label-for-field']}>{`${
                          words['admin.users.table.Edit.firstWorkingDay']
                        }:`}</label>
                        <DatePicker
                          locale={currentLanguage}
                          name={input.name}
                          autoComplete="off"
                          dateFormat={config.dateFormat}
                          value={input.value}
                          selected={input.value ? new Date(input.value) : null}
                          onChange={event => {
                            if (event) {
                              form.change(
                                input.name,
                                formatterDateForDatePicker(addCurrentTime(event))
                              )
                            }
                          }}
                        />
                      </div>
                    )
                  }}
                </Field>

                <Field name="workingHoursPerDay">
                  {({ input }) => {
                    return (
                      <div className={styles.workHour}>
                        <Input
                          variant="outlined"
                          label={words['admin.users.table.Edit.workingHourdPerDay']}
                          {...input}
                        />
                      </div>
                    )
                  }}
                </Field>
              </div>
              <div className={styles['form-row']}>
                <Field
                  name="email"
                  validate={validation.composeValidators(
                    validation.required(words['user.requiredMessage']),
                    validation.isEmail()
                  )}
                >
                  {({ input, meta }) => {
                    return (
                      <div className={styles.left}>
                        <Input
                          variant="outlined"
                          label="E-mail"
                          placeholder="E-mail"
                          {...input}
                          isInvalid={meta.error && meta.submitFailed}
                          errorMessage={meta.error}
                        />
                      </div>
                    )
                  }}
                </Field>
                <Field name="position">
                  {({ input }) => {
                    return (
                      <div className={styles.right}>
                        <label className={styles['label-for-field']}>{`${
                          words['admin.users.table.position']
                        }:`}</label>
                        <Select
                          styles={{
                            container: provided => ({
                              ...provided,
                              margin: 0
                            })
                          }}
                          placeholder={words['admin.users.table.position']}
                          options={positions}
                          {...input}
                          value={
                            positions &&
                            positions.find(
                              (predicate: any) => Number(predicate.value) === Number(input.value.id)
                            )
                          }
                          onChange={event => {
                            return form.change(input.name, Number((event as TSelectOption).value))
                          }}
                          emptyMessage={words['noOption']}
                        />
                      </div>
                    )
                  }}
                </Field>
                {/* // todo - needed to autoselect*/}
                {/*<Field name="userPosition">*/}
                {/*  {({ input }) => {*/}
                {/*    return (*/}
                {/*      <div className={styles.right}>*/}
                {/*        <label className={styles['label-for-field']}>Должность: </label>*/}
                {/*        <Select*/}
                {/*          // isAddNewItem={true}*/}
                {/*          placeholder="Должность"*/}
                {/*          options={positionsData}*/}
                {/*          {...input}*/}
                {/*          value={positionsData.find(*/}
                {/*            predicate => Number(predicate.value) === Number(input.value)*/}
                {/*          )}*/}
                {/*          onChange={event => {*/}
                {/*            return form.change(input.name, (event as TSelectOption).value)*/}
                {/*          }}*/}
                {/*        />*/}
                {/*      </div>*/}
                {/*    )*/}
                {/*  }}*/}
                {/*</Field>*/}
              </div>
              <div className={styles['form-row']}>
                <Field name="reviewerId">
                  {({ input }) => (
                    <div className={styles.left}>
                      <label className={styles['label-for-field']}>{`${
                        words['admin.users.table.Edit.manager']
                      }:`}</label>
                      <Select
                        placeholder={words['admin.users.table.Edit.manager']}
                        options={managersData}
                        {...input}
                        value={managersData.find(
                          predicate => Number(predicate.value) === Number(input.value)
                        )}
                        onChange={event => {
                          return form.change(input.name, (event as TSelectOption).value)
                        }}
                        emptyMessage={words['noOption']}
                      />
                    </div>
                  )}
                </Field>
                <Field name="roles">
                  {({ input }) => {
                    return (
                      <div className={styles.right}>
                        <label className={styles['label-for-field']}>{`${
                          words['admin.users.table.Edit.roles']
                        }:`}</label>
                        <Select
                          placeholder={words['admin.users.table.Edit.choiceRole']}
                          {...input}
                          //@ts-ignore
                          isMulti={true}
                          options={FILTER_SELECT_ROLES_FOR_USERS(words)}
                          emptyMessage={words['noOption']}
                          onChange={event => {
                            if (!event) {
                              return form.change(input.name, [])
                            }
                            const valuesHasRole = (key: string) =>
                              formState.values[input.name] &&
                              (formState.values[input.name] as TSelectOption[]).findIndex(
                                predicate => predicate.value === key
                              ) >= 0
                            const itemHasRole = (key: string) =>
                              ((event as unknown) as TSelectOption[]).findIndex(
                                predicate => predicate.value === key
                              ) >= 0

                            if (valuesHasRole(managerKey) && itemHasRole(userKey)) {
                              return form.change(
                                input.name,
                                ((event as unknown) as TSelectOption[]).filter(
                                  predicate => predicate.value !== managerKey
                                )
                              )
                            }

                            if (valuesHasRole(userKey) && itemHasRole(managerKey)) {
                              return form.change(
                                input.name,
                                ((event as unknown) as TSelectOption[]).filter(
                                  predicate => predicate.value !== userKey
                                )
                              )
                            }

                            form.change(input.name, event)
                          }}
                        />

                        {formState.values[input.name] &&
                          !(formState.values[input.name] as []).length &&
                          user &&
                          user.fullName && (
                            <div className={styles.warning}>
                              <span>{words['admin.users.table.Edit.leadToBan']}</span>{' '}
                              {user.fullName}
                            </div>
                          )}
                      </div>
                    )
                  }}
                </Field>
              </div>
              <div
                className={cls({
                  [styles['form-row']]: true,
                  [styles['form-row-btn']]: true
                })}
              >
                <Button type="submit" className={styles.btn}>
                  {words['admin.productionCalendar.new.buttons.save']}
                </Button>
              </div>
            </form>
          )
        }}
      </Form>
    </Modal>
  )
}
