import { Dispatch } from 'redux'
import { API, api } from 'services/api'
import toastr from 'utils/toastr'
import {
  requestSetTimeOffPeriod,
  successSetTimeOffPeriod,
  errorSetTimeOffPeriod,
  requestGetManagers,
  successGetManagers,
  errorGetManagers,
  requestGetUsers,
  successGetUsers,
  errorGetUsers,
  requestGetCurrentRequest,
  successGetCurrentRequest,
  errorGetCurrentRequest,
  requestGetBalance,
  successGetBalance,
  errorGetBalance,
  getProductCalendarDaysRequest,
  getProductCalendarDaysSuccess,
  getProductCalendarDaysError,
  getActiveTeamMembersList
} from './NewRequestTimeOff.actions'
import history from 'utils/history'
import { TManagersData } from './NewRequestTimeOff.reducer'
import { TState } from 'store'
import { AxiosError } from 'axios'

export const getActiveTeamMembers = () => async (dispatch: Dispatch): Promise<void> => {
  try {
    const { data } = await api.get(`${API.ALL_ACTIVE_TEAM_MEMBERS}`)
    dispatch(getActiveTeamMembersList(data.payload))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export const setTimeOffPeriod = (body: any, id?: string) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const method = id ? 'put' : 'post'
  const url = id ? `${API.URL}${API.CALENDAR}/${id}` : `${API.URL}${API.CALENDAR}`

  dispatch(requestSetTimeOffPeriod())
  const words = getData().global.language.words

  try {
    await api[method](url, body)
    dispatch(successSetTimeOffPeriod())
    history.push('/dashboard/requests?status=1')
    toastr('info', id ? words['SUCCESS_UPDATED'] : words['SUCCESS_CREATED'])
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(errorSetTimeOffPeriod(err))
    toastr('error', words['FILED_CREATE_OR_UPDATE_REQUEST'], err)
  }
}

export type TPeopleProps = { limit?: number }
export const getManagers = ({ limit = 10 }: TPeopleProps) => async (dispatch: Dispatch) => {
  dispatch(requestGetManagers())

  try {
    const {
      data: { payload }
    } = await api.get<{ payload: TManagersData[] }>(`${API.MANAGERS}?limit=${limit}`)
    dispatch(
      successGetManagers({
        total: payload.length,
        results: payload
      })
    )
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(errorGetManagers(err))
  }
}

export const getUsers = ({ limit = 10 }: TPeopleProps) => async (dispatch: Dispatch) => {
  dispatch(requestGetUsers())

  try {
    const {
      data: { payload }
    } = await api.get<{ payload: { total: number; results: TManagersData[] } }>(
      `${API.CLIENT}?limit=${limit}`
    )
    dispatch(
      successGetUsers({
        total: payload.total || payload.results.length,
        results: payload.results
      })
    )
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(errorGetUsers(err))
  }
}

export const getCurrentRequest = (id: string) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(requestGetCurrentRequest())
  const words = getData().global.language.words

  try {
    const response = await api.get(`${API.URL}${API.CALENDAR}/${id}`)
    const result = response.data.payload
    dispatch(successGetCurrentRequest(result))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(errorGetCurrentRequest(err))
    toastr('error', words['NOT_FOUND'], err)
  }
}

export const getBalance = (id?: string) => async (dispatch: Dispatch, getData: () => TState) => {
  const url = id
    ? `${API.CALENDAR}${API.TIME_BALANCE}?id=${id}`
    : `${API.CALENDAR}${API.TIME_BALANCE}`
  dispatch(requestGetBalance())
  const words = getData().global.language.words

  try {
    const response = await api.get(url)
    const result = response.data.payload
    dispatch(successGetBalance(result))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(errorGetBalance(err))
    toastr('error', words['NOT_FOUND'], err)
  }
}

export const getProductCalendarDays = (page: number = 1, search: string = '') => async (
  dispatch: Dispatch
) => {
  dispatch(getProductCalendarDaysRequest())
  try {
    const { data } = await api.get(
      `${API.PRODUCT_CALENDAR}${search}${search ? '&' : '?'}page=${page}&limit=${3000}`
    )
    dispatch(getProductCalendarDaysSuccess(data.payload))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getProductCalendarDaysError(err))
  }
}
