import React, { FC, useEffect, useState } from 'react'
import styles from './Header.module.scss'
import { IHeader } from './Header.model'
import { OpenBasketPopup } from 'screens/client/Basket/components/OpenBasketPopup/OpenBasketPopup'
import { getTimer } from 'screens/auth/Login/Login.thunx'
import { useUser } from '../../hooks/useUser'
import { HeaderMenu } from './components/HeaderMenu'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import PolicyModal from 'components/PolicyModal/PolicyModal'
import Spinner from 'components/Spinner'
import cls from 'classnames'
import { OpenNotificationsPopup } from 'screens/client/Notifications/components/OpenNotificationsPopup/OpenNotificationsPopup'
import { useAccount } from 'wagmi'
import { getBalance } from '@wagmi/core'
import { wagmiConfig } from 'index'
import { EContractAddress } from 'globalConfigs'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { getAccessTokenFromStorage } from 'helpers/getAccessTokenFromStorage'
interface PolicyArray {
  [key: string]: boolean | undefined
}

export const Header: FC<IHeader> = ({
  user,
  userInfo,
  userPhoto,
  totalQuantity,
  totalPrice,
  basket,
  addToCart,
  removeFromCart,
  pulling,
  totalNotifications,
  notifications,
  fetchNotifications,
  deleteNotifications,
  removeSelectedNotification,
  viewNotification,
  getPointsSettings,
  getUserPhoto,
  getPolicy,
  comparePolicy,
  policy,
  policyLoading,
  acceptPolicy,
  setAcceptPolicyThunk,
  isSideBarCollapsed,
  updateUserInfo,
  currentLanguage,
  getCurrentUserData
}) => {
  const { userData } = useUser()
  const isTeamMember = userData.teamMember
  const isUserSide = userData.user || isTeamMember || userData.manager
  const words = useSelector((state: TState) => state.global.language.words)
  const bids = user && user.bidsAmount ? user.bidsAmount : 0
  const interview = user && user.interviewsAmount ? user.interviewsAmount : 0

  const [modalState, setModalState] = useState(false)
  const [acception, setAcception] = useState<PolicyArray>({
    ['dashboard/cookie-policy']: false,
    ['dashboard/privacy-policy']: false,
    ['dashboard/terms-of-service']: false
  })
  const [userBalance, setUserBalance] = useState<{ symbol: string; value: string } | null>(null)

  const { address, isConnected } = useAccount()
  const { open } = useWeb3Modal()
  const token = getAccessTokenFromStorage()

  const isUserVerified =
    userInfo.verificationStatus === 'approved' || userInfo.verification === 'true'

  const handleOpenWalletConnect = () => {
    if (isUserVerified) {
      open()
    }
  }

  useEffect(() => {
    if (isUserVerified && !userInfo.walletAddress && address) {
      updateUserInfo({ ...user, walletAddress: address })
    }
  }, [address])

  const getUserBalance = async () => {
    if (!address) return

    const balance = await getBalance(wagmiConfig, {
      address: address,
      token: EContractAddress.TRL_TOKEN
    })

    if (balance) {
      setUserBalance({ symbol: balance.symbol, value: balance.formatted })
    }
  }

  function onOpenModal() {
    setModalState(!modalState)
  }

  useEffect(() => {
    if (isConnected) {
      getUserBalance()
    }
  }, [isConnected])

  function findAcception(): any {
    // eslint-disable-next-line prefer-const
    for (let key in acception) {
      if (!acception[key]) {
        return key
      }
    }
    return 'dashboard/cookie-policy'
  }

  useEffect(() => {
    if (acceptPolicy !== undefined && !acceptPolicy) {
      getPolicy(findAcception())
    }
    if (!modalState && !Object.keys(acception).every(val => acception[val] === true)) {
      onOpenModal()
    }
  }, [acception])

  useEffect(() => {
    pulling()
    if (token) {
      getCurrentUserData()
    }
    return () => {
      clearTimeout(getTimer())
    }
  }, [])

  useEffect(() => {
    if (isTeamMember) {
      getPointsSettings()
    }
  }, [isTeamMember])

  useEffect(() => {
    if (!userPhoto) {
      setTimeout(() => {
        getUserPhoto(user.id)
      }, 500)
    }
  }, [userPhoto])

  return (
    <>
      <section
        className={cls({
          [styles.container]: true,
          [styles.sidebar_collapsed]: isSideBarCollapsed
        })}
      >
        {isUserSide && (
          <>
            <div className={styles.points}>
              <div className={styles.subcontract_balance}>
                <p className={styles.subcontract_balance_item}>
                  {words['user.subcontract.bids']}:<span>{bids}</span>
                </p>
                <p className={styles.subcontract_balance_item}>
                  {words['user.subcontract.interview']}:<span>{interview}</span>
                </p>
              </div>
              {(isTeamMember || userData.manager) && (
                <OpenBasketPopup
                  totalQuantity={totalQuantity}
                  totalPrice={totalPrice}
                  basket={basket}
                  addToCart={addToCart}
                  removeFromCart={removeFromCart}
                  balance={user.balance}
                />
              )}
              <OpenNotificationsPopup
                totalQuantity={totalNotifications}
                notifications={notifications}
                fetchNotifications={fetchNotifications}
                deleteNotifications={deleteNotifications}
                selectNotification={viewNotification}
                removeSelectedNotification={removeSelectedNotification}
              />
              <HeaderMenu
                className={styles.header_menu}
                photo={userPhoto || ''}
                userSide={isUserSide}
                currentLanguage={currentLanguage}
              />
              <div className={styles.points_trl}>
                <BaseButton
                  children={!isConnected ? 'Connect' : words['user.subcontract.buyTRL']}
                  size="smb"
                  type="submit"
                  onClick={!isConnected ? handleOpenWalletConnect : () => null}
                  disabled={!isUserVerified}
                  title={isUserVerified ? 'Connect wallet' : 'User is not verified'}
                />

                {isConnected && userBalance && (
                  <span className={styles.points_balance}>
                    {userBalance.value}

                    <span>{userBalance.symbol}</span>
                  </span>
                )}
              </div>
            </div>
          </>
        )}
        {isUserSide ? null : (
          <div className={styles.points}>
            <OpenNotificationsPopup
              totalQuantity={totalNotifications}
              notifications={notifications}
              popupClasses={styles['admin-notification']}
              fetchNotifications={fetchNotifications}
              deleteNotifications={deleteNotifications}
              selectNotification={viewNotification}
              removeSelectedNotification={removeSelectedNotification}
            />
            <HeaderMenu
              photo={userPhoto || ''}
              userSide={isUserSide}
              currentLanguage={currentLanguage}
            />
          </div>
        )}
        {/* <Modal isShow={openModal} onClose={closeRedirectModal} className={styles['redirect-modal']}>
          <p>{words['user.popup.infoIsRequired']}</p>
          <div className={styles['required-info-image']} />
          <div className={styles['btn-container']}>
            <Button type="button" className={styles['send-friend']} onClick={closeRedirectModal}>
              {words['user.popup.fillIn']}
            </Button>
          </div>
        </Modal> */}
        {policyLoading ? (
          <div className={styles.loader}>
            <Spinner />
          </div>
        ) : !acceptPolicy &&
          userInfo.id &&
          !Object.keys(acception).every(val => acception[val] === true) ? (
          <PolicyModal
            toggle={modalState}
            action={onOpenModal}
            acception={acception}
            setAcception={setAcception}
            policy={policy}
            findAcception={findAcception}
            comparePolicy={comparePolicy}
            setAcceptPolicyThunk={setAcceptPolicyThunk}
          />
        ) : null}
      </section>
    </>
  )
}
