import { IAction } from 'models'
import {
  DELETE_ENTREPRENEUR_INVOICE,
  DELETE_FROM_SELECTED_ENTREPRENEUR_INVOICE,
  FILTER_INVOICES,
  GET_ENTREPRENEURS_INVOICES_REQUEST,
  GET_ENTREPRENEURS_INVOICES_SUCCESS,
  SET_ENTREPRENEURS,
  GET_INVOICES_SUCCESS,
  SET_INVOICE,
  SET_SELECTED_ALL_ENTREPRENEUR_INVOICES,
  SET_SELECTED_ENTREPRENEUR_INVOICE,
  SET_DOWNLOAD_INVOICES,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_ERROR
} from './Entrepreneurs.constants'
import { IEntrepreneur, TFormulatedInvoices } from './Entrepreneurs.model'
import { TBank, TUserData /*, TGetUsersSuccess*/ } from '../Users/Users.model'

export type IInitialState = {
  error: string
  loading: boolean
  task: any
  invoices: TFormulatedInvoices[]
  selectedInvoices: any
  users: TUserData[]
  banks: TBank[]
  entrepreneurs: IEntrepreneur[]
  downloadInvoices: any[]
  invoice: any
}

export const initialState: IInitialState = {
  error: '',
  loading: false,
  task: {},
  users: [],
  banks: [],
  entrepreneurs: [],
  invoices: [],
  downloadInvoices: [],
  selectedInvoices: [],
  invoice: {}
}

export default (state = initialState, action: IAction): any => {
  switch (action.type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_USERS_SUCCESS:
      const total = action.payload.total
      if (total < 10) {
        return {
          ...state,
          loading: false,
          users: [...action.payload.results],
          banks: action.payload.banks ? [...action.payload.banks.result] : []
        }
      }
      return {
        ...state,
        loading: false,
        users: [...state.users, ...action.payload.results]
      }

    case GET_USERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    case GET_INVOICES_SUCCESS: {
      return {
        ...state,
        invoices: [...action.payload]
      }
    }
    case DELETE_ENTREPRENEUR_INVOICE: {
      return {
        ...state,
        invoices: state.invoices.filter(
          (invoice: TFormulatedInvoices) => invoice.id !== action.payload
        )
      }
    }
    case SET_SELECTED_ENTREPRENEUR_INVOICE: {
      return {
        ...state,
        selectedInvoices: [...state.selectedInvoices, action.payload]
      }
    }
    case DELETE_FROM_SELECTED_ENTREPRENEUR_INVOICE: {
      return {
        ...state,
        selectedInvoices: state.selectedInvoices.filter(
          (invoice: any) => invoice.id !== action.payload.id
        )
      }
    }
    case SET_SELECTED_ALL_ENTREPRENEUR_INVOICES: {
      return {
        ...state,
        selectedInvoices: [...action.payload]
      }
    }
    case SET_INVOICE: {
      return {
        ...state,
        invoice: action.payload
      }
    }
    case FILTER_INVOICES: {
      const { payload } = action
      if (payload.fullName) {
        return {
          ...state,
          invoices: state.invoices.filter((item: any) => {
            const lowerItem = item.name.toLowerCase()
            return lowerItem.includes(payload.fullName.toLowerCase())
          })
        }
      } else {
        return {
          ...state
        }
      }
    }
    case SET_ENTREPRENEURS: {
      return {
        ...state,
        entrepreneurs: action.payload
      }
    }
    case GET_ENTREPRENEURS_INVOICES_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_ENTREPRENEURS_INVOICES_SUCCESS: {
      return {
        ...state,
        loading: false,
        invoices: action.payload
      }
    }
    case SET_DOWNLOAD_INVOICES: {
      return {
        ...state,
        downloadInvoices: action.payload
      }
    }
    default:
      return state
  }
}
