import React, { FC, useEffect, useState } from 'react'
import BackButton from 'components/BackButton/BackButton'
import cls from 'classnames'
import styles from './VotingDetails.module.scss'
import { VotingStatusBar } from './components/VotingStatusBar'
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'
import { TVotingDetailsProps } from './VotingDetails.model'
import { TYPE_OF_CURRENCY, EContractAddress } from 'globalConfigs'
import moment from 'moment'
import { categoryOptions } from 'components/Subcontract/SubcontractTasksFilter/SubcontractTasksFilter'
import { countPercent } from './libs/helpers/countPercent'
import { VotingModal } from '../VotingModal/VotingModal'
import { TComment, TCommentType } from '../VotingModal/VotingModal.model'
import { UserAvatar } from 'components/UserAvatar'
import { useParams } from 'react-router'
import { TVoice } from '../../VotingList.model'
import { handleComment } from './libs/helpers/handleComment'
import getWeb3 from 'getWeb3'
import toastr, { EToastrTypeMessage } from 'utils/toastr'
import { getContractAbi } from 'utils/getContractAbi'
import { ETransactionMessage } from 'components/Spinner/types'
import { Button } from 'components/UiKit/Button'
import { ETaskStatus } from 'screens/client/Subcontract/components/CreateTask/CreateTask.model'
import { EDealStatus } from 'screens/client/Web3/web3.model'
import { getDealThunk } from 'screens/client/Deal/deal.thunk'
import { clearDeal } from 'screens/client/Deal/deal.actions'

const VotingDetailsComponent: FC<TVotingDetailsProps> = ({
  voting,
  getVoting,
  vote,
  updateVotingDate,
  updateTaskInfo
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const currentUser = useSelector((state: TState) => state.auth.data)
  const deal = useSelector((state: TState) => state.client.deal.data)
  const dispatch = useDispatch()
  const { id } = useParams<{ id: string }>()
  const [isShowModal, setIsShowModal] = useState(false)
  const [modalData, setModalData] = useState<TComment>()
  const [isCurrentUserCustomerOrContractor, setIsCurrentUserCustomerOrContractor] = useState<
    boolean | null
  >(null)
  const customerPercent = voting ? countPercent(voting.customerVoices, voting.executorVoices) : 0
  const executorPercent = voting ? countPercent(voting.executorVoices, voting.customerVoices) : 0

  const currentUserSide =
    voting && currentUser.id === voting.task.customer.id ? 'customer' : 'executor'
  const isVotingDone = voting && new Date(voting.endOfVoting) < new Date()
  const isNotPaidYet = voting && voting.task.dealStatus !== EDealStatus.CANCELLED_BY_VOTING

  useEffect(() => {
    if (voting && voting.task.taskHash && !deal) {
      dispatch(getDealThunk(voting.task.taskHash))
    }
  }, [voting])

  useEffect(() => {
    return () => {
      dispatch(clearDeal())
    }
  }, [])

  useEffect(() => {
    const checkUserSide = async () => {
      if (deal) {
        const [currentUserAddress] = await (await getWeb3()).eth.getAccounts()
        const { customer, contractor } = deal
        setIsCurrentUserCustomerOrContractor(
          currentUserAddress === customer || currentUserAddress === contractor
        )
      }
    }

    if (isCurrentUserCustomerOrContractor === null && deal) checkUserSide()
  }, [isCurrentUserCustomerOrContractor, deal])

  const isVotingConfirmedByBothSides = () => {
    if (deal) {
      const { isConfirmedVotingByContractor, isConfirmedVotingByCustomer } = deal
      return isConfirmedVotingByContractor && isConfirmedVotingByCustomer
    }
    return false
  }

  const isCurrentUserConfirmedVoting = () => {
    if (deal) {
      const confirmations = {
        customer: deal.isConfirmedVotingByCustomer,
        executor: deal.isConfirmedVotingByContractor
      }
      return confirmations[currentUserSide]
    }
    return null
  }

  useEffect(() => {
    const handleVoting = async () => {
      if (deal && +deal.votingDeadline !== 0 && voting) {
        const votingDeadline = deal.votingDeadline * 1000
        await updateVotingDate(new Date(votingDeadline), voting.id)
      }
    }
    handleVoting()
  }, [deal])

  const isCurrentUserWinner = () => {
    if (voting && voting.task && isCurrentUserCustomerOrContractor) {
      const { customerVoices, executorVoices } = voting

      if (customerVoices === executorVoices) return null

      const winnerSide = executorVoices > customerVoices ? 'executor' : 'customer'

      return currentUserSide === winnerSide
    }

    return null
  }

  useEffect(() => {
    getVoting(+id)
    selectComment('customer')
  }, [id])

  const confirmVoting = async () => {
    if (voting && voting.task.taskHash) {
      const web3 = await getWeb3()

      try {
        const addresses = await web3.eth.getAccounts()
        const contractAbi = await getContractAbi(EContractAddress.VOTING)
        const contractInstance = new web3.eth.Contract(contractAbi, EContractAddress.VOTING)

        const gasPrice = await web3.eth.getGasPrice()
        const gasLimit = await contractInstance.methods
          .setTaskOnVoting(voting.task.taskHash, EContractAddress.TRL_TOKEN)
          .estimateGas({ from: addresses[0] })

        await contractInstance.methods
          .setTaskOnVoting(voting.task.taskHash, EContractAddress.TRL_TOKEN)
          .send({
            from: addresses[0],
            gasPrice,
            gas: gasLimit
          })
          .on('transactionHash', () => {
            toastr(EToastrTypeMessage.INFO, words[`${ETransactionMessage.TRANSACTION_MESSAGE}`])
          })
          .on('receipt', async () => {
            if (voting.task.taskHash) {
              dispatch(getDealThunk(voting.task.taskHash))
              if (deal) {
                toastr(EToastrTypeMessage.SUCCESS, 'Voting has been confirmed')
              }
            } else {
              throw new Error('Deal not found')
            }
          })
          .on('error', () => {
            toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
          })
      } catch (setTaskOnVotingError) {
        console.log(setTaskOnVotingError)
        throw setTaskOnVotingError
      }
    }
  }

  const selectTaskVotingSide = async (voteFor: TVoice) => {
    const isCustomerSide = voteFor === 'customer'

    if (voting && voting.task.taskHash) {
      try {
        const web3 = await getWeb3()
        const { taskHash } = voting.task

        const addresses = await web3.eth.getAccounts()
        const contractAbi = await getContractAbi(EContractAddress.VOTING)
        const contractInstance = new web3.eth.Contract(contractAbi, EContractAddress.VOTING)

        const gasPrice = await web3.eth.getGasPrice()
        const gasLimit = await contractInstance.methods
          .selectTaskVotingSide(taskHash, isCustomerSide)
          .estimateGas({ from: addresses[0] })

        await contractInstance.methods
          .selectTaskVotingSide(taskHash, isCustomerSide)
          .send({
            from: addresses[0],
            gasPrice,
            gas: gasLimit
          })
          .on('transactionHash', () => {
            toastr(EToastrTypeMessage.INFO, words[`${ETransactionMessage.TRANSACTION_MESSAGE}`])
          })
          .on('receipt', async () => {
            if (deal) {
              vote(voting.id, voteFor, deal.votingCurrency)
              toastr(EToastrTypeMessage.SUCCESS, `The ${voteFor} has received the vote`)
            } else {
              throw new Error('Deal not found')
            }
          })
          .on('error', () => {
            toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
          })
      } catch (selectTaskVotingSideError) {
        toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
        console.log(selectTaskVotingSideError)
      }
    } else {
      toastr(EToastrTypeMessage.ERROR, 'taskHash is not defined')
    }
  }

  const getPaymentByVoting = async () => {
    if (voting && voting.task.taskHash) {
      try {
        // we just switch deal status to 'VotingDone', because contract was written in this way
        await selectTaskVotingSide('customer')

        const web3 = await getWeb3()
        const { taskHash } = voting.task

        const addresses = await web3.eth.getAccounts()
        const contractAbi = await getContractAbi(EContractAddress.VOTING)
        const contractInstance = new web3.eth.Contract(contractAbi, EContractAddress.VOTING)

        const gasPrice = await web3.eth.getGasPrice()
        const gasLimit = await contractInstance.methods
          .getPaymentByVoting(taskHash)
          .estimateGas({ from: addresses[0] })

        await contractInstance.methods
          .getPaymentByVoting(taskHash)
          .send({
            from: addresses[0],
            gasPrice,
            gas: gasLimit
          })
          .on('transactionHash', () => {
            toastr(EToastrTypeMessage.INFO, words[`${ETransactionMessage.TRANSACTION_MESSAGE}`])
          })
          .on('receipt', async () => {
            await updateTaskInfo(voting.task.id, {
              title: voting.task.title,
              description: voting.task.description,
              statusId: ETaskStatus.CANCELLED,
              dealStatus: EDealStatus.CANCELLED_BY_VOTING
            })
            toastr(EToastrTypeMessage.SUCCESS, `You have successfully received payment`)
          })
          .on('error', () => {
            toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
          })
      } catch (getPaymentByVotingError) {
        toastr(EToastrTypeMessage.ERROR, words[`${ETransactionMessage.TRANSACTION_ERROR}`])
        console.log(getPaymentByVotingError)
      }
    } else {
      toastr(EToastrTypeMessage.ERROR, 'taskHash is not defined')
    }
  }

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!voting) {
      return
    }
    await selectTaskVotingSide(event.target.value as TVoice)
  }

  const selectComment = (type: TCommentType) => {
    if (!voting) {
      return
    }

    setModalData({
      type: type,
      desc:
        type === 'customer'
          ? voting.task.taskCancellation.commentFromCustomer
          : voting.task.taskCancellation.commentFromContractor,
      name: type === 'customer' ? voting.task.customer.fullName : voting.task.executor.fullName,
      avatar: type === 'customer' ? voting.task.customer.photo : voting.task.executor.photo,
      percent:
        type === 'customer'
          ? voting.task.taskCancellation.refundCustomerPercent
          : voting.task.taskCancellation.refundContractorPercent
    })
  }

  const openModal = () => {
    setIsShowModal(true)
  }

  const closeModal = () => {
    setIsShowModal(false)
  }
  return (
    <div className={styles['container']}>
      <div className={styles['header']}>
        <h1>{words['user.voting.jobCenter']}</h1>
      </div>
      {voting && voting.id === +id && (
        <>
          <div className={styles['content']}>
            <BackButton />
            <div className={cls(styles['desc'], styles['block'])}>
              <div className={styles['desc-body']}>
                <div>{voting.task.description}</div>
              </div>
              <div className={styles['desc-footer']}>
                <div className={styles['category-wrapper']}>
                  <div className={styles['category']}>
                    <div className={styles['category-label']}>
                      {words['user.subcontract.header.taskType']}:
                    </div>
                    <div className={styles['value']}>
                      {voting.task.type && categoryOptions[+voting.task.type].label}
                    </div>
                  </div>
                  <div className={styles['category']}>
                    <div className={styles['category-label']}>
                      {words['user.voting.endOfVoting']}:
                    </div>
                    <div className={cls(styles['value'], { [styles['value-red']]: isVotingDone })}>
                      {moment(voting.endOfVoting).format(`DD.MM.YYYY | HH:mm`)}
                    </div>
                  </div>
                  <div className={styles['category']}>
                    <div className={styles['category-label']}>
                      {words['user.subcontract.task.budget']}:
                    </div>
                    <div className={cls(styles['value'], styles['value-price'])}>
                      {voting.task.price} {TYPE_OF_CURRENCY[+voting.task.currency]}
                    </div>
                  </div>
                </div>
                {isVotingDone && isNotPaidYet && isCurrentUserWinner() && (
                  <div className={cls(styles['category'], styles['category-row'])}>
                    <span className={styles['value-green']}>You have won the vote</span>
                    <Button
                      onClick={getPaymentByVoting}
                      className={cls(styles['button'], styles['button-content'])}
                    >
                      <span>Get Payment</span>
                    </Button>
                  </div>
                )}
                {deal &&
                  !isVotingDone &&
                  !isCurrentUserConfirmedVoting() &&
                  isCurrentUserCustomerOrContractor && (
                    <div className={cls(styles['category'], styles['category-row'])}>
                      <span className={styles['value-red']}>You need to confirm voting first</span>
                      <Button
                        onClick={confirmVoting}
                        className={cls(styles['button'], styles['button-content'])}
                      >
                        <span>Confirm Voting</span>
                      </Button>
                    </div>
                  )}
              </div>
            </div>
            <div className={styles['votingWrapper']}>
              <div className={styles['comments']}>
                {voting.task.taskCancellation.commentFromCustomer && (
                  <div
                    className={cls(styles['block'], styles['comment'])}
                    onClick={() => {
                      selectComment('customer')
                      openModal()
                    }}
                  >
                    <div className={styles['header']}>
                      <h2 className={styles['subtitle']}>{words['user.voting.customerComment']}</h2>
                      <div className={styles['avatar-wrapper']}>
                        <UserAvatar
                          className={styles['avatar']}
                          photo={voting.task.customer.photo}
                        />
                        <div className={styles['name']}>{voting.task.customer.fullName}</div>
                      </div>
                    </div>
                    <div className={styles['comment-desc']}>
                      {handleComment(voting.task.taskCancellation.commentFromCustomer)}
                    </div>
                    <div className={styles['budget']}>
                      <h3 className={styles['title']}>{words['user.voting.budgetAllocation']}:</h3>
                      <div className={styles['customer']}>
                        <div className={styles['category-label']}>
                          {words['user.subcontract.task.customer']}:
                        </div>
                        <div className={styles['value']}>
                          {voting.task.taskCancellation.refundCustomerPercent}%
                        </div>
                      </div>
                      <div className={styles['executor']}>
                        <div className={styles['category-label']}>
                          {words['user.taskPayment.performer']}:
                        </div>
                        <div className={styles['value']}>
                          {100 - voting.task.taskCancellation.refundCustomerPercent}%
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {voting.task.taskCancellation.commentFromContractor && (
                  <div
                    className={cls(styles['block'], styles['comment'])}
                    onClick={() => {
                      selectComment('executor')
                      openModal()
                    }}
                  >
                    <div className={styles['header']}>
                      <h2 className={styles['subtitle']}>
                        {words['user.voting.performerComment']}
                      </h2>
                      <div className={styles['avatar-wrapper']}>
                        <UserAvatar
                          className={styles['avatar']}
                          photo={voting.task.executor.photo}
                        />
                        <div className={styles['name']}>{voting.task.executor.fullName}</div>
                      </div>
                    </div>
                    <div className={styles['comment-desc']}>
                      {handleComment(voting.task.taskCancellation.commentFromContractor)}
                    </div>
                    <div className={styles['budget']}>
                      <h3 className={styles['title']}>{words['user.voting.budgetAllocation']}:</h3>
                      <div className={styles['customer']}>
                        <div className={styles['category-label']}>
                          {words['user.subcontract.task.customer']}:
                        </div>
                        <div className={styles['value']}>
                          {100 - voting.task.taskCancellation.refundContractorPercent}%
                        </div>
                      </div>
                      <div className={styles['executor']}>
                        <div className={styles['category-label']}>
                          {words['user.taskPayment.performer']}:
                        </div>
                        <div className={styles['value']}>
                          {voting.task.taskCancellation.refundContractorPercent}%
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={cls(styles['block'], styles['voting'])}>
                <div className={styles['header']}>
                  <h2 className={styles['subtitle']}>{words['user.voting.voting']}</h2>
                </div>
                <div className={styles['percents']}>
                  {voting.isVoted || isVotingDone ? (
                    <>
                      <div className={styles['customer']}>
                        <div className={styles['avatar-wrapper']}>
                          <UserAvatar
                            className={styles['avatar']}
                            photo={voting.task.customer.photo}
                          />
                          <div className={styles['name']}>{voting.task.customer.fullName}</div>
                        </div>
                        <div className={styles['value']}>{customerPercent}%</div>
                        <VotingStatusBar color="red" percent={customerPercent} />
                      </div>
                      <div className={styles['executor']}>
                        <div className={styles['avatar-wrapper']}>
                          <UserAvatar
                            className={styles['avatar']}
                            photo={voting.task.executor.photo}
                          />
                          <div className={styles['name']}>{voting.task.executor.fullName}</div>
                        </div>
                        <div className={styles['value']}>{executorPercent}%</div>
                        <VotingStatusBar color="green" percent={executorPercent} />
                      </div>
                    </>
                  ) : isVotingConfirmedByBothSides() ? (
                    <FormControl>
                      <RadioGroup
                        name="voting"
                        className={styles['radio-group']}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="customer"
                          className={styles['radio-label']}
                          control={<Radio />}
                          label={
                            <div className={styles['avatar-wrapper']}>
                              <UserAvatar
                                className={styles['avatar']}
                                photo={voting.task.customer.photo}
                              />
                              <div className={styles['name']}>{voting.task.customer.fullName}</div>
                            </div>
                          }
                        />
                        <FormControlLabel
                          value="executor"
                          className={styles['radio-label']}
                          control={<Radio />}
                          label={
                            <div className={styles['avatar-wrapper']}>
                              <UserAvatar
                                className={styles['avatar']}
                                photo={voting.task.executor.photo}
                              />
                              <div className={styles['name']}>{voting.task.executor.fullName}</div>
                            </div>
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <div>
                      <div className={styles['value-red']}>
                        Before voting starts, the other side, either the customer or the contractor,
                        has to confirm the voting.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {modalData && (
            <VotingModal isShow={isShowModal} onClose={closeModal} comment={modalData} />
          )}
        </>
      )}
    </div>
  )
}

export { VotingDetailsComponent }
