import { Dispatch } from 'redux'
import { API, api } from '../../../services/api'
import {
  requestUserDay0fBirth,
  successUserDay0fBirth,
  errorUserDay0fBirth,
  errorUserCity,
  successUserCity,
  requestUserCity,
  successUserPointsTransfer,
  errorUserPointsTransfer,
  getHistoryRequest,
  getHistorySuccess,
  getHistoryError,
  getTransactionsRequest,
  getTransactionsSuccess,
  getTransactionsError,
  getUserProfileInfoRequest,
  getUserProfileInfoSuccess,
  getUserProfileInfoError,
  getUsersListSuccess,
  checkUserProfileRequest,
  checkUserProfileSuccess,
  checkUserProfileError
} from './Profile.actions'
import toastr from '../../../utils/toastr'
import { TUpdatePointsBalanceBody } from '../../admin/Users/Users.model'
import history from 'utils/history'
import { EPageLimits } from '../../../globalConfigs'
import queryString from 'query-string'
import { TState } from 'store'
import { AxiosError } from 'axios'
import { setUserVerification } from 'screens/auth/Login/Login.actions'

export const setDob = (body: { [key: string]: number }) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(requestUserDay0fBirth())
  const words = getData().global.language.words

  try {
    await api.put(`${API.URL}${API.CLIENT}`, body)
    dispatch(successUserDay0fBirth(body))
    toastr('success', words['BIRTHDAY_DATE_UPDATED'])
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(errorUserDay0fBirth(err.response))
    toastr('error', words['FAILED_TO_UPDATE_USER'], err)
  }
}

export const setUserCity = (body: { [key: string]: number }) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(requestUserCity())
  const words = getData().global.language.words

  try {
    await api.put(`${API.URL}${API.CLIENT}`, body)
    dispatch(successUserCity(body))
    toastr('success', words['UPDATED_CITY'])
  } catch (error) {
    const err = error as AxiosError<any>
    //@ts-ignore
    dispatch(errorUserCity(err.response))
    toastr('error', words['FILED_UPDATE_USER'], err)
  }
}

export const changeUserPointsTransfer = (body: TUpdatePointsBalanceBody) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const words = getData().global.language.words

  try {
    const response = await api.post(API.TRANSACTION_USER, body)
    dispatch(successUserPointsTransfer(body))
    const errorElement = response.data.payload.find(
      (el: { [key: string]: string }) => el.message !== 'ok'
    )
    if (Object.keys(response.data).length === 0) {
      toastr('error', words['FILED_TRANSFER_POINTS'])
    } else if (
      errorElement &&
      errorElement.message.match(/not enough points on balance to make transaction/)
    ) {
      toastr('error', words['NOT_ENOUGH_POINTS_ON_BALANCE_TO_MAKE_TRANSACTION'])
    } else {
      toastr('info', words['SUCCESS_TRANSFERRED'])
    }
    history.push('/dashboard/profile')
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(errorUserPointsTransfer(err))
    toastr('error', words['FILED_TRANSFER_POINTS'], err)
    history.push('/dashboard/point-system')
  }
}

export const getHistory = (page: number, search: string = '') => async (dispatch: Dispatch) => {
  dispatch(getHistoryRequest())
  try {
    const res = await api.get(
      `${API.HISTORY}${search}${search ? '&' : '?'}limit=${EPageLimits.HISTORY}&page=${page}`
    )
    dispatch(getHistorySuccess(res.data))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getHistoryError(err))
  }
}

export const getTransactions = (page: number, search: string = '') => async (
  dispatch: Dispatch
) => {
  dispatch(getTransactionsRequest())
  const query = queryString.stringify({
    ...queryString.parse(search),
    limit: EPageLimits.TRANSACTION,
    page
  })
  try {
    const response = await api.get(`${API.TRANSACTION}?${query}`)

    dispatch(getTransactionsSuccess(response.data))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getTransactionsError(err))
  }
}

export const getUserProfileInfo = (
  id: number,
  profileType: string,
  isAdmin: boolean,
  forVerify?: boolean
) => async (dispatch: Dispatch, getData: () => TState): Promise<void> => {
  const words = getData().global.language.words
  dispatch(getUserProfileInfoRequest())
  try {
    const result = await api.get(`${API.URL}/projects`)
    const projects = result.data.payload.results
    const managersResult = await api.get(`${API.URL}/managers`)
    const managers = managersResult.data.payload.map((item: any) => {
      return { value: String(item.id), label: item.fullName }
    })
    let data: any = { projects: projects, managers: managers }
    if (forVerify && !isAdmin) {
      const res = await api.get(`${API.CLIENT}/${id}?profileType=${profileType}`)
      data = { ...data, ...res.data }
    }
    if (forVerify && isAdmin) {
      const res = await api.get(
        `${API.USER_PROFILE_INFO_FOR_ADMIN}/${id}?profileType=${profileType}&verification=true`
      )
      data = { ...data, ...res.data }
    }
    if (isAdmin && !forVerify) {
      const res = await api.get(
        `${API.USER_PROFILE_INFO_FOR_ADMIN}/${id}?profileType=${profileType}`
      )
      data = { ...data, ...res.data }
    }
    if (!isAdmin && !forVerify) {
      const res = await api.get(`${API.USER_PROFILE_INFO}/${Number(id)}?profileType=${profileType}`)
      data = { ...data, ...res.data }
    }

    const { verification, verificationIdenfy } = data
    dispatch(setUserVerification({ verification, verificationIdenfy }))
    dispatch(getUserProfileInfoSuccess(data))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getUserProfileInfoError(err))
    toastr('error', words['REQUEST_NOT_FOUND'], err)
  }
}

export const getUsersList = () => async (dispatch: Dispatch): Promise<void> => {
  try {
    const { data } = await api.get(`${API.ALL_USERS}`)
    dispatch(getUsersListSuccess(data.payload))
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export const checkUserProfile = (id: number) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  const words = getData().global.language.words
  dispatch(checkUserProfileRequest())
  try {
    const { data } = await api.get(`${API.CHECK_USER_PROFILE}/${Number(id)}`)
    dispatch(checkUserProfileSuccess(data.payload))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(checkUserProfileError(err))
    toastr('error', words['REQUEST_NOT_FOUND'], err)
  }
}
