import { config } from 'globalConfigs'

export const getAccessTokenFromStorage = (): string | null => {
  const data = localStorage.getItem(
    `@@auth0spajs@@::${config.authClientId}::${config.authAudience}::${config.authScope}`
  )
  if (!data) return null

  return JSON.parse(data).body.access_token
}
