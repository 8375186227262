/* eslint-disable no-console */
import { IAction } from 'models'
import { GET_EVENTS_REQUEST, GET_EVENTS_SUCCESS } from './PlaningCalendar.constants'
import { TEvent } from './PlaningCalendar.model'
import { TTask } from '../Task/Task.model'

export type IInitialState = {
  error: string
  loading: boolean
  eventsArray: TEvent[]
  currentTask: TTask
}

export const initialState: IInitialState = {
  error: '',
  loading: false,
  eventsArray: [],
  currentTask: {
    agreedUsers: [],
    avatar: null,
    cancelDescription: null,
    cancelRequestDate: null,
    canceledAt: null,
    canceler: null,
    companyAddress: null,
    companyEmail: null,
    companyName: null,
    companyNumber: null,
    createdAt: null,
    currency: '',
    customer: { id: 0, fullName: '', rating: null, photo: null },
    deadline: null,
    description: '',
    devStage: null,
    executor: null,
    finishedAt: null,
    fixedDeadline: false,
    id: 0,
    interviewRequests: null,
    interviews: null,
    isCustomerPrice: false,
    level: { id: 0, name: '' },
    offerRequests: null,
    onInterviewUsers: null,
    planning: null,
    price: 0,
    pricePerHour: null,
    publishedAt: new Date(),
    refundAmount: null,
    schedule: null,
    specializations: [
      {
        label: '',
        value: ''
      }
    ],
    startProjectDate: null,
    status: { id: 0, name: '' },
    taskFiles: null,
    taskHash: null,
    taskResponses: [
      {
        id: 0,
        budget: null,
        comment: null,
        deadline: null,
        author: {
          fullName: '',
          originalPhoto: '',
          countPositiveReviews: 0,
          countNegativeReviews: 0
        },
        authorId: 0,
        createdAt: new Date(),
        taskId: 0
      }
    ],
    teamStructure: null,
    techStack: null,
    technologies: null,
    title: null,
    type: null,
    withRefund: false,
    workingHours: 0
  }
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_EVENTS_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }
    case GET_EVENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        eventsArray: action.payload.eventsArray,
        currentTask: action.payload.currentTask
      }
    }
    default:
      return state
  }
}
