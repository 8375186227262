import { TWords } from 'elements/SideBar/SideBar.config'

export const sortDays = (days: any): any => {
  const sortable = []
  const objSorted = {} as any
  if (days) {
    for (const key in days) {
      sortable.push([key, days[key]])
    }
    sortable.sort(function(a, b) {
      return a[1].order - b[1].order
    })
    sortable.forEach(function(item) {
      objSorted[item[0]] = item[1]
    })
  }
  return objSorted
}

export const convertDate = (date: Date) => {
  const currentDate = new Date(date)
  const day = currentDate.getDate()
  const month = currentDate.getMonth() + 1
  const year = currentDate.getFullYear()
  return `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`
}

export const transformFieldName = (fieldName: string, words: TWords) => {
  switch (fieldName) {
    case 'name':
      return words['project.name']
    case 'description':
      return words['project.description']
    case 'start':
      return words['project.start']
    case 'end':
      return words['project.end']
    case 'model':
      return words['project.model']
    case 'stage':
      return words['project.stage']
    case 'methodology':
      return words['project.methodology']
    case 'paymentMethod':
      return words['project.payment']
    case 'unit':
      return words['project.unit']
    case 'manager':
      return words['project.manager']
    case 'customerAssistant':
      return words['project.customerAssistant']
    case 'stack':
      return words['project.stack']
    case 'structure':
      return words['project.structure']
    case 'companyName':
      return words['project.fullName']
    case 'registerNumber':
      return words['project.registerNumber']
    case 'fullAddress':
      return words['project.fullAddress']
    case 'email':
      return words['project.email']
    default:
      return fieldName
  }
}

export const normalizeWordsWithNumbers = (number: number, locale: string) => {
  let word = ''
  switch (locale) {
    case 'ru':
      word = 'отзывов'
      if (number % 10 === 1 && number % 100 !== 11) {
        word = 'отзыв'
      } else if (
        (number % 10 === 2 && number % 100 !== 12) ||
        (number % 10 === 3 && number % 100 !== 13) ||
        (number % 10 === 4 && number % 100 !== 14)
      ) {
        word = 'отзыва'
      }
      break
    case 'ua':
      word = 'відгуків'
      if (number % 10 === 1 && number % 100 !== 11) {
        word = 'відгук'
      } else if (
        (number % 10 === 2 && number % 100 !== 12) ||
        (number % 10 === 3 && number % 100 !== 13) ||
        (number % 10 === 4 && number % 100 !== 14)
      ) {
        word = 'відгуки'
      }
      break
    default:
      if (number === 1) {
        word = 'feedback'
      } else {
        word = 'feedbacks'
      }
  }
  return `${number} ${word}`
}
