import {
  SET_USER_DATA,
  SET_ACCESS_TOKEN,
  SIGN_IN_CLEAR,
  PULLING_SUCCESS,
  PULLING_ERROR,
  SET_USER_AVATAR,
  SET_USER_VERIFICATION,
  SET_SELECTED_ROLE
} from './Login.constants'
import { IAction } from 'models'
import { ActionCreator } from 'redux'

export const fillUserData: ActionCreator<IAction> = payload => ({
  type: SET_USER_DATA,
  payload
})

export const setAccessToken: ActionCreator<IAction> = payload => {
  console.log('_ACTION_setAccessToken=', payload)

  return {
    type: SET_ACCESS_TOKEN,
    payload
  }
}

export const signInClear: ActionCreator<IAction> = () => ({
  type: SIGN_IN_CLEAR
})

export const pollingSuccess: ActionCreator<IAction> = payload => ({
  type: PULLING_SUCCESS,
  payload
})

export const pollingError: ActionCreator<IAction> = payload => ({
  type: PULLING_ERROR,
  payload
})

export const setUserAvatar: ActionCreator<IAction> = payload => ({
  type: SET_USER_AVATAR,
  payload
})

export const setUserVerification: ActionCreator<IAction> = payload => ({
  type: SET_USER_VERIFICATION,
  payload
})

export const setSelectedRole: ActionCreator<IAction> = payload => ({
  type: SET_SELECTED_ROLE,
  payload
})
