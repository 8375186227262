import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { NewRequestTimeOffComponent } from './NewRequestTimeOff'
import {
  setTimeOffPeriod,
  getManagers,
  getCurrentRequest,
  getBalance,
  getProductCalendarDays,
  getActiveTeamMembers
} from './NewRequestTimeOff.thunx'
import { TState } from 'store'

export const mapStateToProps = (state: TState) => ({
  data: state.client.newRequestTimeOff.currentRequest,
  user: state.auth.data,
  dataManagers: state.client.newRequestTimeOff.managers.results,
  activeTeamMembersList: state.client.newRequestTimeOff.activeTeamMembersList,
  totalManagers: state.client.newRequestTimeOff.managers.total,
  totalUsers: state.client.newRequestTimeOff.users.total,
  loadingSetNewRequest: state.client.newRequestTimeOff.loading,
  role: state.auth.data.role,
  balance: state.client.newRequestTimeOff.balance,
  userId: state.auth.data.id,
  productCalendar: state.client.newRequestTimeOff.productCalendar.results
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setTimeOffPeriod,
      getManagers,
      getActiveTeamMembers,
      getCurrentRequest,
      getBalance,
      getProductCalendarDays
    },
    dispatch
  )

export const NewRequestTimeOff = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewRequestTimeOffComponent)
