import { connect } from 'react-redux'
import { getDataSelector } from './Login.reducer'
import { Login as LoginComponent } from './Login'
import { TState } from 'store'
import { bindActionCreators, Dispatch } from 'redux'
import { setSelectedRole } from './Login.actions'

export const mapStateToProps = (state: TState) => ({
  data: getDataSelector(state),
  selectedRole: state.auth.role
})

export const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setSelectedRole
    },
    dispatch
  )

export const Login = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginComponent)
