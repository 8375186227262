import { Dispatch } from 'redux'
import { API, api } from '../../../services/api'
import toastr from '../../../utils/toastr'
import { TState } from '../../../store'
import { isTeamMember, isUser } from 'utils/user'
import {
  errorGetTimeOffRequests,
  requestGetTimeOffRequests,
  successGetTimeOffRequests,
  errorActionTimeOff,
  requestActionTimeOff,
  successActionTimeOff,
  errorGetCurrentRowRequest,
  successGetCurrentRowRequest
} from './TimeOffRequests.actions'
import { TDataForRequest } from './TimeOffRequests.model'
import history from '../../../utils/history'
import queryString from 'query-string'
import { TWords } from 'elements/SideBar/SideBar.config'
import { AxiosError } from 'axios'
// import { EPageLimits } from '../../../globalConfigs'

let isUserSide: boolean

export const getTimeOffRequests = (search: string = '') => async (
  dispatch: Dispatch,
  getState?: () => TState
) => {
  if (getState) {
    const currentRole = getState().auth.decodedToken.role
    const isUserRole = isUser(currentRole)
    const isTeamMeberRole = isTeamMember(currentRole)
    isUserSide = isUserRole || isTeamMeberRole
  }

  dispatch(requestGetTimeOffRequests())

  const parsed = queryString.parse(search)
  if (parsed.onlyMy) {
    delete parsed.onlyMy
  }

  const query = queryString.stringify({
    ...parsed,
    limit: 2000
  })

  let url = ''

  if (isUserSide || search.match(/onlyMy/)) {
    url = API.CALENDAR_USER
  } else {
    url = API.CALENDAR
  }

  try {
    const { data } = await api.get(`${API.URL}${url}?${query}`)
    dispatch(successGetTimeOffRequests(data.payload))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(errorGetTimeOffRequests(err))
  }
}

export const actionForTimeOffSuccessMessages = (words: TWords): { [key: string]: string } => {
  return {
    cancel: words['SUCSESS_CANCEL'],
    reject: words['SUCSESS_REJECT'],
    approve: words['SUCSESS_APROVE']
  }
}

export const actionForTimeOff = (body: TDataForRequest) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(requestActionTimeOff({ type: body.type }))
  const words = getData().global.language.words

  try {
    const response = body.search
      ? await api.get(`${API.MANAGEMENT_MAIL}${body.search}`)
      : await api.post(API.MANAGEMENT, body)

    dispatch(successActionTimeOff({ payload: response, type: body.type }))
    const errorElement = response.data.payload.find(
      (el: { [key: string]: string }) => el.message !== 'ok'
    )
    if (errorElement) {
      if (errorElement.message.match(/not found calendar/)) {
        toastr('error', words['REQUEST_NOT_FOUND'])
      } else if (errorElement.message.match(/you can't assign request to this user in calendar/)) {
        toastr('error', words['YOU_CANT_ASSIGN_REQUEST_TO_THIS_USER_IN_CALENDAR'])
      } else if (errorElement.message.match(/alredy handeled calendar/)) {
        toastr('error', words['REQUEST_ALREADY_PROCESSED_EARLIER'])
      } else if (errorElement.message.match(/not permitted to cancel calendar/)) {
        toastr('error', words['YOU_DONT_HAVE_RIGHTS'])
      } else if (errorElement.message.match(/not permitted to edit status of calendar/)) {
        toastr('error', words['YOU_DONT_HAVE_RIGHTS'])
      } else if (errorElement.message.match(/Not enough hours on balance/)) {
        toastr('error', words['YOU_DONT_HAVE_MONEY'])
      }
    } else {
      toastr('success', actionForTimeOffSuccessMessages(words)[body.type])
    }
  } catch (error) {
    dispatch(errorActionTimeOff(error))
    const err = error as AxiosError<any>
    // if (error.response && error.response.status === 422) {
    //   toastr('error', 'Недостаточно баланса')
    // } else
    if (err.response && err.response.status === 403) {
      toastr('error', words['LINK_NOT_VALID'])
    } else {
      toastr('error', words['FILED_PROCESS_REQUEST'], err)
    }
  }
  body.search && history.push(`${history.location.pathname}`)
}

export const getCurrentRowRequest = (id: number) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  const words = getData().global.language.words

  try {
    const response = await api.get(`${API.URL}${API.CALENDAR}/${id}`)
    const result = response.data.payload
    dispatch(successGetCurrentRowRequest(result))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(errorGetCurrentRowRequest(err))
    toastr('error', words['NOT_FOUND'], err)
  }
}
