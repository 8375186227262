import { Dispatch } from 'redux'
import { getGoodsRequest, getGoodsSuccess, getGoodsError } from './Goods.actions'
import { api } from 'services/api'
import { API } from '../../../services/api'
import { AxiosError } from 'axios'

export const getGoods = (page: number, search: string = '', limit: number = 10) => async (
  dispatch: Dispatch
) => {
  dispatch(getGoodsRequest())

  try {
    const response: any = await api.get(
      `${API.GOODS}${search}${search ? '&' : '?'}limit=${limit}&page=${page}`
    )

    dispatch(getGoodsSuccess(response.data))
  } catch (error) {
    const err = error as AxiosError<any>
    await dispatch(getGoodsError(err))
  }
}
