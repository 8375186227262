import PrivateRoute from './PrivatRoute'
import { bindActionCreators, Dispatch } from 'redux'
import { connect } from 'react-redux'
import { TState } from 'store'

export const mapStateToProps = ({ auth }: TState) => ({
  decodedToken: auth.decodedToken
})

export const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute)
