import { Dispatch } from 'redux'
import { API, api } from 'services/api'
import {
  getPointsSettingsError,
  getPointsSettingsRequest,
  getPointsSettingsSuccess,
  updatePointsSettingsError,
  updatePointsSettingsRequest,
  updatePointsSettingsSuccess,
  updateSettingsPointsBalanceRequest,
  updateSettingsPointsBalanceSuccess,
  updateSettingsPointsBalanceError
} from './SettingsPointsSystem.actions'
import toastr from 'utils/toastr'
import { TUpdatePointsBalanceBody } from '../../../Users/Users.model'
import { TState } from 'store'
import { AxiosError } from 'axios'

export type TUpdatePointsSettingsBody = {
  pointsFundPerMonth: number
  achievementsAmount: number
  feeCharger: number | null
}

export const getPointsSettings = () => async (dispatch: Dispatch) => {
  dispatch(getPointsSettingsRequest())
  try {
    const { data } = await api.get(API.POINTS_CONFIG)
    if (!data.payload.result) {
      return
    }
    dispatch(getPointsSettingsSuccess(data))
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(getPointsSettingsError(err))
  }
}

export const updatePointsSettings = (body: TUpdatePointsSettingsBody) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(updatePointsSettingsRequest())
  const words = getData().global.language.words

  try {
    await api.put(API.POINTS_CONFIG, body)
    dispatch(updatePointsSettingsSuccess(body))
    toastr('info', words['SUCCESS'])
  } catch (error) {
    const err = error as AxiosError<any>
    toastr('error', words['FILED_UPDATE_SETTINGS'], err)
    dispatch(updatePointsSettingsError(err))
  }
}

export const updateSettingsPointsBalance = (body: TUpdatePointsBalanceBody) => async (
  dispatch: Dispatch,
  getData: () => TState
) => {
  dispatch(updateSettingsPointsBalanceRequest())
  const words = getData().global.language.words

  try {
    const response = await api.post(`${API.TRANSACTION_ADMIN}`, body)
    dispatch(updateSettingsPointsBalanceSuccess(response.data))
    if (response.data.status === 'ok') {
      toastr('info', words['SUCCESS'])
    } else {
      toastr('error', words['FILED_UPDATE_BALANCE'])
    }
  } catch (error) {
    const err = error as AxiosError<any>
    dispatch(updateSettingsPointsBalanceError(err))
    toastr('error', words['FILED_UPDATE_BALANCE'], err)
  }
}
