import React, { FC } from 'react'
import styles from '../../AuthRoute.module.scss'
import Modal from 'components/Modal'
import { ReactComponent as Logo } from 'assets/images/logo-new.svg'

type IProps = {
  isOpen: boolean
  onClose: () => void
  text: string
}

const ErrorModal: FC<IProps> = ({ isOpen, onClose, text }) => {
  return (
    <Modal className={styles['modal']} isShow={isOpen} onClose={onClose}>
      <div className={styles['modal-container']}>
        <Logo />
        <div className={styles['modal-err-desc']}>{text}</div>
      </div>
    </Modal>
  )
}

export default ErrorModal
