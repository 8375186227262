export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'GET_USERS_ERROR'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const CLEAR_USER_DATA = 'CLEAR_USER_DATA'

export const UPDATE_POINTS_BALANCE_REQUEST = 'UPDATE_POINTS_BALANCE_REQUEST'
export const UPDATE_POINTS_BALANCE_SUCCESS = 'UPDATE_POINTS_BALANCE_SUCCESS'
export const UPDATE_POINTS_BALANCE_ERROR = 'UPDATE_POINTS_BALANCE_ERROR'

export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST'
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS'
export const DELETE_USERS_ERROR = 'DELETE_USERS_ERROR'

export const RESTORE_USERS_REQUEST = 'RESTORE_USERS_REQUEST'
export const RESTORE_USERS_SUCCESS = 'RESTORE_USERS_SUCCESS'
export const RESTORE_USERS_ERROR = 'RESTORE_USERS_ERROR'

export const GET_POSITION_REQUEST = 'GET_POSITION_REQUEST'
export const GET_POSITION_SUCCESS = 'GET_POSITION_SUCCESS'
export const GET_POSITION_ERROR = 'GET_POSITION_ERROR'

export const GET_USERS_TO_FILTER_SUCCESS = 'GET_USERS_TO_FILTER_SUCCESS'
export const GET_USERS_TO_FILTER_ERROR = 'GET_USERS_TO_FILTER_ERROR'

export const GET_ALL_TEAM_MEMBERS = 'GET_ALL_TEAM_MEMBERS'
