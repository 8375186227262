import { Dispatch } from 'redux'
import {
  policyRequest,
  getPolicySuccess,
  getPolicyError,
  comparePolicyError,
  postPolicySuccess,
  setAcceptPolicy
} from './Policy.actions'
import { api } from 'services/api'
import toastr from 'utils/toastr'
import history from 'utils/history'
import { TState } from 'store'
import { getUserProfileInfoSuccess } from '../Profile/Profile.actions'

export const getPolicy = (path: string) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(policyRequest())
  try {
    const index = path.lastIndexOf('/')
    const name = path.substring(index + 1)
    const response: any = await api.get(`/policy/policy?name=${name}&deleted=null&single=true`)
    dispatch(getPolicySuccess(response.data.payload))
  } catch (error) {
    toastr('error', 'Get Policy Error')
    dispatch(getPolicyError(error))
  }
}

export const postPolicy = (file: any) => async (dispatch: Dispatch): Promise<void> => {
  dispatch(policyRequest())

  try {
    const formData = new FormData()
    formData.append('name', file.name)
    if (file) {
      // formData.append(`${file.name}`, file.file)
      formData.append(`${file.name}`, file.file)
    }

    await api.post(`/policy/policy`, formData)
    dispatch(postPolicySuccess())
  } catch (error) {
    toastr('error', 'Post Policy Error')
    dispatch(getPolicyError(error))
  }
}

export const comparePolicy = (policy: any, onCompared: any) => async (
  dispatch: Dispatch
): Promise<void> => {
  dispatch(policyRequest())

  try {
    const formData = new FormData()
    if (policy) {
      formData.append(`policy`, JSON.stringify(policy))
    }

    const response: any = await api.post(`/policy/compare`, formData)
    if (response.status === 200) {
      onCompared()
    }
  } catch (error) {
    toastr('error', 'Compared, is not same')
    dispatch(comparePolicyError(error))
  }
}

export const setAcceptPolicyThunk = (status: boolean) => async (
  dispatch: Dispatch,
  getData: () => TState
): Promise<void> => {
  dispatch(policyRequest())
  const user = getData().client.profile.user

  try {
    await api.put(`/policy/accept`, { acceptPolicy: status })
    dispatch(getPolicySuccess(getData().client.policy.data))
    dispatch(getUserProfileInfoSuccess({ ...user, acceptPolicy: true }))
    dispatch(setAcceptPolicy(true))
    history.push('/dashboard/profile')
  } catch (error) {
    toastr('error', 'Error, not accepted')
    dispatch(getPolicyError(error))
  }
}
