import { BaseButton } from 'components/UiKit/Button/BaseButton'
import React, { FC } from 'react'
import cls from 'classnames'
import styles from './VotingListItem.module.scss'
import { TVotingListItemProps } from './VotingListItem.model'
import { useSelector } from 'react-redux'
import { TState } from 'store'
import { TYPE_OF_CURRENCY } from 'globalConfigs'
import moment from 'moment'
import { categoryOptions } from 'components/Subcontract/SubcontractTasksFilter/SubcontractTasksFilter'
import { UserAvatar } from 'components/UserAvatar'

const VotingListItem: FC<TVotingListItemProps> = ({
  history,
  votingElement: { task, ...voting }
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const handleClick = () => {
    history.push(`/dashboard/subcontract/voting/${voting.id}`)
  }

  return (
    <div className={styles['list-item']}>
      <div className={styles['list-item-body']}>
        <div>{task.description}</div>
        <BaseButton className={styles['button']} onClick={handleClick}>
          {words['user.voting.vote']}
        </BaseButton>
      </div>
      <div className={styles['list-item-footer']}>
        <div className={styles['category']}>
          <div className={styles['category-label']}>{words['user.subcontract.task.customer']}:</div>
          <div className={styles['avatar-wrapper']}>
            <UserAvatar className={styles['avatar']} photo={task.customer.photo} />
            <div className={styles['value']}>{task.customer.fullName}</div>
          </div>
        </div>
        <div className={styles['category']}>
          <div className={styles['category-label']}>{words['user.taskPayment.performer']}:</div>
          <div className={styles['avatar-wrapper']}>
            <UserAvatar className={styles['avatar']} photo={task.executor.photo} />
            <div className={styles['value']}>{task.executor && task.executor.fullName}</div>
          </div>
        </div>
        <div className={styles['category']}>
          <div className={styles['category-label']}>
            {words['user.subcontract.header.taskType']}:
          </div>
          <div className={styles['value']}>{task.type && categoryOptions[+task.type].label}</div>
        </div>
        <div className={styles['category']}>
          <div className={styles['category-label']}>{words['user.voting.endOfVoting']}:</div>
          <div className={styles['value']}>
            {moment(voting.endOfVoting).format(`DD.MM.YYYY | HH:mm`)}
          </div>
        </div>
        <div className={styles['category']}>
          <div className={styles['category-label']}>{words['user.subcontract.task.budget']}:</div>
          <div className={cls(styles['value'], styles['value-price'])}>
            {task.price} {TYPE_OF_CURRENCY[+task.currency]}
          </div>
        </div>
      </div>
    </div>
  )
}

export { VotingListItem }
