import { Dispatch } from 'redux'
import { getDeal } from './deal.actions'
import getWeb3 from 'getWeb3'
import { AbiItem } from 'web3-utils'
import { EContractAddress } from 'globalConfigs'
import { getContractAbi } from 'utils/getContractAbi'

export const getDealThunk = (taskHash: string) => async (dispatch: Dispatch) => {
  try {
    const web3 = await getWeb3()

    const contractAbi: AbiItem[] = await getContractAbi(EContractAddress.FIX_DEAL_ESCROW)
    const contractInstance = new web3.eth.Contract(contractAbi, EContractAddress.FIX_DEAL_ESCROW)

    const deal = await contractInstance.methods.deals(taskHash).call()

    dispatch(getDeal(deal))
  } catch (error) {
    console.log('get deal error:', error)
  }
}
