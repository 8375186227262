import React, { FC, useEffect, useMemo, useState } from 'react'
import { Redirect } from 'react-router-dom'
import queryString from 'query-string'
import styles from './AchievementsAdm.module.scss'
import { TAchievementsAdmProps } from './AchievementsAdm.model'
import { IToggleBarData, ToggleBar } from 'components/ToggleBar'
import history from 'utils/history'
import { Button } from 'components/UiKit/Button'
import { Filter } from 'components/UiKit/Filter'
import { TGoodsCategories } from '../GoodsItem/GoodsItem.model'
import { TSelectOption } from 'components/Select'
import { getFilterConfig } from './AchievementsAdm.filter.config'
import { getColumns } from './AchievementsAdm.columns'
import { TableNew } from 'components/UiKit/Table'
import { ERowSize } from '../../../globalConfigs'
import { useLoadDateFortTable } from '../../../hooks/useLoadDateFortTable'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export const AchievementsAdm: FC<TAchievementsAdmProps> = ({
  data,
  categories,
  total,
  minPrice,
  maxPrice,
  getAchievements,
  getGoodsCategories,
  deleteAchievement,
  restoreAchievement,
  clearAchievementsData
}) => {
  const words = useSelector((state: TState) => state.global.language.words)
  const parsStr = JSON.stringify(queryString.parse(history.location.search))
  const parsed = useMemo(() => queryString.parse(history.location.search), [parsStr])
  const toggleBarOptions = useMemo(
    () => [
      {
        value: 'active',
        title: words['admin.users.toggle.active']
      },
      {
        value: 'deleted',
        title: words['admin.users.toggle.inactive']
      }
    ],
    [words]
  )
  const { setCurrentPage, setLoadedPages } = useLoadDateFortTable(getAchievements)
  const [forceUpd, setForceUpd] = useState(Date.now())
  const [selectedType, setSelectedType] = useState<IToggleBarData>(
    parsed.deleted === 'true' ? toggleBarOptions[1] : toggleBarOptions[0]
  )

  const clearAllData = () => {
    clearAchievementsData()
    setCurrentPage(1)
    setLoadedPages([])
    setForceUpd(Date.now())
  }

  // Clear data if change filters
  useEffect(() => {
    if (parsStr) {
      clearAllData()
    }
  }, [parsStr])

  useEffect(() => {
    return () => {
      clearAllData()
    }
  }, [])

  useEffect(() => {
    if (parsed.deleted) {
      getGoodsCategories()
    }
  }, [parsed.deleted])

  const categoriesData: TSelectOption[] = useMemo(
    () =>
      categories.map((item: TGoodsCategories) => ({
        value: `${item.id}`,
        label: item.name
      })),
    [categories]
  )

  const columns = history.location.search.match(/deleted=true/)
    ? getColumns(
        {
          onEdit: id => history.push(`/dashboard/point-system/achievements/${id}/edit`),
          onRestore: id => restoreAchievement(id)
        },
        words
      )
    : getColumns(
        {
          onEdit: id => history.push(`/dashboard/point-system/achievements/${id}/edit`),
          onRemove: id => deleteAchievement(id)
        },
        words
      )
  const columnsMemoized = useMemo(() => columns, [parsStr])

  if (!parsed.deleted) {
    return <Redirect to={{ search: 'deleted=false', pathname: history.location.pathname }} />
  }
  const handleCreateNewAchievement = (event: any) => {
    event.preventDefault()
    history.push('/dashboard/point-system/achievements/create')
  }

  return (
    <section className={styles.container}>
      <section className={styles.header}>
        <h1>{words['admin.settings.pointsSystem.achivements']}</h1>
      </section>
      <section className={styles['toggle-container']}>
        <div className={styles.toggle}>
          <ToggleBar
            name="active-achievements-no-active"
            data={toggleBarOptions}
            defaultChecked={selectedType}
            onChange={event => {
              history.push(
                `${history.location.pathname}?${queryString.stringify({
                  deleted: event.value === 'deleted'
                })}`
              )
              setSelectedType(event)
            }}
          />
        </div>
        <Button className={styles['btn-added']} onClick={handleCreateNewAchievement}>
          {words['admin.productionCalendar.buttons.add']}
        </Button>
      </section>
      <section>
        <Filter
          config={getFilterConfig(
            {
              categories: categoriesData,
              minPrice,
              maxPrice
            },
            words
          )}
          defaultOpened={true}
          withButton={true}
        />
      </section>
      <section>
        <TableNew
          key={forceUpd}
          total={total}
          data={data}
          noData={words['noData']}
          setCurrentPage={setCurrentPage}
          columns={columnsMemoized}
          rowSize={ERowSize.MIDDLE}
        />
      </section>
    </section>
  )
}
