export enum Elanguages {
  UA = 'ua',
  RU = 'ru',
  EN = 'en'
}
export interface IInitialState {
  words: {}
  error: string
  loading: boolean
  languages: { [key in Elanguages]: string }
  currentLanguage: Elanguages
}
