import { IInitialState, IRequestData, TRequestsDetailsItem } from './Requests.model'
import { IAction } from 'models'
import {
  GET_REQUESTS_ERROR,
  GET_REQUESTS_REQUEST,
  GET_REQUESTS_SUCCESS,
  ACTION_REQUESTS_REQUEST,
  ACTION_REQUESTS_SUCCESS,
  ACTION_REQUESTS_ERROR,
  CLEAR_REQUESTS_DATA
} from './Requests.constants'

export const initialState: IInitialState = {
  data: [],
  total: 0,
  error: '',
  loading: false,
  minFromDate: '',
  maxToDate: '',
  minPrice: 0,
  maxPrice: 0
}
interface IResponse {
  id: number
  status: number
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_REQUESTS_REQUEST:
    case ACTION_REQUESTS_REQUEST:
      return {
        ...state,
        loading: true
      }

    case GET_REQUESTS_SUCCESS:
      return {
        ...state,
        data: [...state.data, ...action.payload.results],
        minFromDate: action.payload.minDate,
        maxToDate: action.payload.maxDate,
        minPrice: action.payload.minPrice,
        maxPrice: action.payload.maxPrice,
        total: action.payload.total,
        loading: false
      }

    case ACTION_REQUESTS_SUCCESS:
      const response = action.payload.payload
      const reqData = [...state.data]
      const updatedData = [...state.data]

      reqData.map((stateData: IRequestData, i: number) => {
        stateData.details.map((detail: TRequestsDetailsItem, ind: number) => {
          if (response.length) {
            response.map((item: { result: IResponse[] }) => {
              if (item.result.length) {
                item.result.map((res: IResponse) => {
                  if (res.id === detail.id) {
                    updatedData[i].details[ind].status = res.status
                    updatedData[i].requestStatus = 5
                  }
                })
              }
            })
          }
        })
      })

      return {
        ...state,
        data: [...updatedData],
        loading: false
      }

    case CLEAR_REQUESTS_DATA:
      return {
        ...initialState
      }

    case GET_REQUESTS_ERROR:
    case ACTION_REQUESTS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    default:
      return state
  }
}
