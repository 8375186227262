import { IAction } from 'models'

import {
  SET_TIME_OFF_PERIOD_REQUEST,
  SET_TIME_OFF_PERIOD_SUCCESS,
  SET_TIME_OFF_PERIOD_ERROR,
  GET_MANAGER_ERROR,
  GET_MANAGER_SUCCESS,
  GET_MANAGER_REQUEST,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERROR,
  GET_CURRENT_REQUEST_SUCCESS,
  GET_CURRENT_REQUEST_REQUEST,
  GET_CURRENT_REQUEST_ERROR,
  GET_BALANCE_ERROR,
  GET_BALANCE_REQUEST,
  GET_BALANCE_SUCCESS,
  UPDATE_CURRENT_TIME_BALANCE_REQUEST,
  UPDATE_CURRENT_TIME_BALANCE_SUCCESS,
  UPDATE_CURRENT_TIME_BALANCE_ERROR,
  GET_PRODUCT_CALENDAR_DAYS_ERROR,
  GET_PRODUCT_CALENDAR_DAYS_REQUEST,
  GET_PRODUCT_CALENDAR_DAYS_SUCCESS,
  GET_ACTIVE_TEAM_MEMBERS_LIST
} from './NewRequestTimeOff.constants'
import { AxiosError } from 'axios'
import {
  TErrorGetBalance,
  TErrorGetCurrentRequest,
  TErrorGetManagers,
  TSuccessGetManagers
} from './NewRequestTimeOff.actions'
import { TSettingsTimeOfRequestTableData } from '../../admin/Settings/components/SettingsTimeOfRequest/SettingsTimeOfRequest.reducer'
import { TBalance } from '../../../utils/balance'
import { TSelectOption } from 'components/Select'
import { ISelectOptions } from '../EditProfile/EditProfile.model'

export interface TSocialNetworks {
  linkedin: string
  facebook: string
  instagram: string
}

export interface TManagersData {
  id: number
  fullName: string
  rewieverId: number
  adminName: string
  hashPassword: string
  email: string
  city: number | TSelectOption | string
  balance: number
  photo: string
  roles: string[]
  deletedRoles: string[]
  children: any
  dateOfBirth: any
  firstTerminatingDay: string
  firstWorkingDay: string
  hobby: string
  hometown: string
  lifeGoals: string
  phoneNumber: string
  socialNetworks: TSocialNetworks
  files: any
  university: string
  workingHoursPerDay: number
  technologies: string
  careerGoal: string
  previousJob: string
  salary: number
  probationEndDate: number
  rank: ISelectOptions
  position: ISelectOptions
  academicDegree: ISelectOptions
  lifePriorities: any
  maritalStatus: ISelectOptions
  department: ISelectOptions
  typeOfEmployment: ISelectOptions
  englishLevel: ISelectOptions
  terminationInitiator: ISelectOptions
}
export interface TNewRequestTimeOffInitialState {
  users: {
    results: TManagersData[]
    total: number
  }
  managers: {
    results: TManagersData[]
    total: number
  }
  productCalendar: {
    results: TSettingsTimeOfRequestTableData
    total: number
    loading: boolean
    error: AxiosError | null
  }
  balance: TBalance
  currentRequest: any // TODO --- types
  error: AxiosError | null
  loading: boolean
  activeTeamMembersList: IActiveTeammembers[]
}
export interface IActiveTeammembers {
  id: number
  fullName: string
}

export const initialState: TNewRequestTimeOffInitialState = {
  users: {
    results: [],
    total: 0
  },
  managers: {
    results: [],
    total: 0
  },
  productCalendar: {
    results: {
      workingDays: [],
      nonWorkingDays: []
    },
    total: 0,
    error: null,
    loading: false
  },
  currentRequest: {},
  balance: {
    balance: 0,
    debtBalance: 0,
    dayOffBalance: 0,
    dayOffLimit: 0,
    totalRemote: 0,
    remoteLimit: 0,
    totalSick: 0,
    sickLimit: 0,
    workingHoursPerDay: 0
  },
  error: null,
  loading: false,
  activeTeamMembersList: []
}

export default (state = initialState, action: IAction) => {
  switch (action.type) {
    case GET_USER_REQUEST:
    case GET_MANAGER_REQUEST:
    case SET_TIME_OFF_PERIOD_REQUEST:
    case GET_CURRENT_REQUEST_REQUEST:
    case UPDATE_CURRENT_TIME_BALANCE_REQUEST:
    case GET_BALANCE_REQUEST: {
      return {
        ...state,
        loading: true
      }
    }

    case GET_PRODUCT_CALENDAR_DAYS_REQUEST:
      return {
        ...state,
        table: {
          ...state,
          loading: true
        }
      }

    case SET_TIME_OFF_PERIOD_SUCCESS: {
      return {
        ...state,
        loading: false
      }
    }

    case SET_TIME_OFF_PERIOD_ERROR: {
      return {
        ...state,
        error: action.payload.error,
        loading: false
      }
    }

    case GET_MANAGER_SUCCESS: {
      return {
        ...state,
        managers: {
          ...state.managers,
          ...(action as TSuccessGetManagers).payload
        }
      }
    }

    case GET_USER_SUCCESS: {
      return {
        ...state,
        users: {
          ...state.users,
          ...(action as TSuccessGetManagers).payload
        }
      }
    }

    case GET_CURRENT_REQUEST_SUCCESS: {
      return {
        ...state,
        currentRequest: action.payload, // TODO --- types
        loading: false
      }
    }

    case GET_BALANCE_SUCCESS: {
      return {
        ...state,
        balance: action.payload,
        loading: false
      }
    }

    case UPDATE_CURRENT_TIME_BALANCE_SUCCESS: {
      return {
        ...state,
        balance: action.payload,
        loading: false
      }
    }

    case GET_PRODUCT_CALENDAR_DAYS_SUCCESS:
      return {
        ...state,
        productCalendar: {
          results: {
            workingDays: action.payload.workingDays,
            nonWorkingDays: action.payload.nonWorkingDays
          },
          total: action.payload.total,
          error: null,
          loading: false
        }
      }

    case GET_MANAGER_ERROR: {
      return {
        ...state,
        error: (action as TErrorGetManagers).payload,
        loading: false
      }
    }

    case GET_USER_ERROR: {
      return {
        ...state,
        error: (action as TErrorGetManagers).payload,
        loading: false
      }
    }

    case GET_CURRENT_REQUEST_ERROR: {
      return {
        ...state,
        error: (action as TErrorGetCurrentRequest).payload,
        loading: false
      }
    }

    case GET_BALANCE_ERROR: {
      return {
        ...state,
        error: (action as TErrorGetBalance).payload,
        loading: false
      }
    }

    case UPDATE_CURRENT_TIME_BALANCE_ERROR: {
      return {
        ...state,
        error: (action as TErrorGetBalance).payload,
        loading: false
      }
    }

    case GET_PRODUCT_CALENDAR_DAYS_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    case GET_ACTIVE_TEAM_MEMBERS_LIST:
      return {
        ...state,
        activeTeamMembersList: action.payload
      }

    default:
      return state
  }
}
