import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react'
import styles from './CreateTask.module.scss'
import queryString from 'query-string'
import { Form } from 'react-final-form'
import { FileView } from 'components/Profile/PersonalInfo/FileView'
import { Icons, TIcon } from 'components/Icons'
import FieldFactory from 'components/UiKit/FieldFactory/FieldFactory'
import { useDispatch, useSelector } from 'react-redux'
import { TState } from 'store'
import { BaseButton } from 'components/UiKit/Button/BaseButton'
import { TCreateTaskProps } from './CreateTask.model'
import { getInitialValues, getCardArray, getDefaultWeek } from './initialvalues'
import { TypeCard } from 'components/Subcontract/TypeCard/TypeCard'
import { getSelectOptions } from 'screens/client/EditProfile/EditProfile.thunx'
import { CreateType } from './CreateType'
import Loading from '../../../../../components/Spinner'
import { sortDays } from 'helpers/helpers'

export type TInputArg = {
  name: string
  onBlur: (event?: React.FocusEvent<any> | undefined) => void
  onChange: (event: React.ChangeEvent<any>) => void
  onFocus: (event?: React.FocusEvent<any> | undefined) => void
  value: any
  checked?: boolean | undefined
}

const CreateTask: FC<TCreateTaskProps> = ({
  history,
  taskCreatorReq,
  getTask,
  updateTaskInfo,
  taskInfo,
  level,
  loading,
  taskLoading,
  signTaskThunk,
  selectedToken
}) => {
  const positions = useSelector((state: TState) => state.client.editProfile.positions)
  const words = useSelector((state: TState) => state.global.language.words)
  const dispatch = useDispatch()
  const parsed = queryString.parse(history.location.search)
  const DefaultWeek = getDefaultWeek(words)
  const cardArray = getCardArray(words)
  const taskStatus = '1'

  useEffect(() => {
    dispatch(getSelectOptions())
    if (parsed.taskId) {
      getTask(+parsed.taskId, false)
    }
  }, [])

  const [isDraft, setIsDraft] = useState(true)
  const [files, setFiles] = useState([])
  const [deletedFilesId, setDeletedFilesId] = useState([])
  const [avatar, setAvatar] = useState([] as any)
  const [selectedType, setSelectedType] = useState(0)
  const [dataForTableDefault, setDataForTableDefault] = useState<any>(
    parsed.taskId
      ? taskInfo.schedule && taskInfo.schedule !== undefined
        ? sortDays(taskInfo.schedule)
        : {} || DefaultWeek
      : DefaultWeek
  )

  const deleteFile = (file: any, idx: number, choosen: any = files, setChoosen: any = setFiles) => {
    const newFilesArr = [...choosen]
    newFilesArr.splice(idx, 1)
    setChoosen(newFilesArr)

    if (file.id) {
      const newDeletedFilesId: any = [...deletedFilesId]
      newDeletedFilesId.push(file.id)
      setDeletedFilesId(newDeletedFilesId)
    }
  }

  const taskData: any = useMemo(() => {
    const initialValues = {} as any
    if (taskInfo && history.location.pathname.match(/edittask/gi)) {
      const newFile: any = []
      taskInfo.taskFiles &&
        taskInfo.taskFiles.length &&
        taskInfo.taskFiles.map((file: any) => {
          newFile.push({ id: file.id, name: file.name, link: file.link })
        })
      setFiles(newFile)
      return getInitialValues(taskInfo)
    } else {
      return (initialValues.dataForTable = dataForTableDefault)
    }
  }, [taskInfo])

  const onSelectFile = (e: any, choosen: any, setChoosen: any) => {
    if (e.target.files[0].size > 10485760) {
      alert('File is too big!')
      return
    }
    const f = e.target.files[0]
    if (f) {
      const newFiles = [...choosen]
      const blob = new Blob([f], { type: f.type })
      const url = URL.createObjectURL(blob)
      newFiles.push({ name: f.name, link: url, file: f })
      setChoosen(newFiles)
    }
  }
  const taskCreationSub = async (values: any): Promise<any> => {
    const publish = new Date().toISOString()
    const request = new FormData()
    request.append('title', String(values.title))
    request.append('description', String(values.description))
    values.technologies && request.append('technologies', String(values.technologies))
    values.level && request.append('levelId', String(values.level))
    request.append(
      'specializations',
      values.specialization === null || !values.specialization
        ? '[{}]'
        : JSON.stringify(
            values.specialization.map((posi: any) => {
              for (let i = 0; i < positions.length; i++) {
                if (posi.value === positions[i].value) {
                  return positions[i]
                }
              }
            })
          )
    )
    request.append('fixedDeadline', String(values.deadline || values.fixedDeadline ? true : false))
    if (values.deadline) {
      if (!values.fixedDeadline) {
        request.append('deadline', new Date(values.deadline).toISOString())
      }
    }
    values.startProjectDate &&
      request.append('startProjectDate', new Date(values.startProjectDate).toISOString())
    values.teamStructure && request.append('teamStructure', values.teamStructure)
    values.devStage && request.append('devStage', values.devStage)
    values.techStack && request.append('techStack', values.techStack)
    values.companyName && request.append('companyName', values.companyName)
    values.companyNumber && request.append('companyNumber', values.companyNumber)
    values.companyAddress && request.append('companyAddress', values.companyAddress)
    values.companyEmail && request.append('companyEmail', values.companyEmail)
    request.append('isCustomerPrice', String(values.isCustomerPrice === true ? true : false))
    values.planning && request.append('planning', String(values.planning))
    values.isRepeatable && request.append('isRepeatable', String(values.isRepeatable))

    if (selectedToken) {
      request.append('currency', selectedToken.id)
      request.append('token', selectedToken.id)
    }

    request.append('statusId', String(taskStatus))
    request.append('publishedAt', publish)
    request.append('type', String(parsed.taskType))
    if (files.length > 0) {
      files.map((item: any) => {
        if (item.file) {
          request.append(`taskFiles`, item.file)
        }
      })
    }
    if (avatar.length > 0) {
      avatar.map((item: any) => {
        if (item.file) {
          request.append(`avatar`, item.file)
        }
      })
    }
    if (values.workingHours) {
      request.append('workingHours', String(values.workingHours))
    }
    if (values.isCustomerPrice !== true) {
      if (values.pricePerHours) {
        request.append('price', String(values.pricePerHours))
      } else {
        values.price && request.append('price', String(values.price || 0))
      }
    }
    if (deletedFilesId.length > 0) {
      request.append('deletedFilesList', JSON.stringify(deletedFilesId))
    }
    if (
      Object.entries(sortDays(dataForTableDefault)).filter((item: any) => {
        if (item[1].active) {
          return item
        }
      }).length > 0
    ) {
      request.append('schedule', JSON.stringify(dataForTableDefault))
    }
    window.scrollTo(0, 0)
    if (taskInfo.id && history.location.pathname.match(/edittask/gi)) {
      return updateTaskInfo(
        taskInfo.id,
        request,
        history,
        typeof parsed.redirectUrl === 'string' ? parsed.redirectUrl : ''
      )
    } else {
      return taskCreatorReq(
        request,
        history,
        typeof parsed.redirectUrl === 'string' ? parsed.redirectUrl : ''
      )
    }
  }

  const handleSignTask = async (formValues: any): Promise<void> => {
    const { payload } = await taskCreationSub(formValues)
    const task = payload.result
    try {
      signTaskThunk(task, history)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      <div className={styles.container}>
        {taskLoading ? (
          <div className={styles['modal-loader']}>
            <Loading />
          </div>
        ) : null}
        <section className={styles.header}>
          <h1
            onClick={() => {
              history.goBack()
            }}
          >
            {!parsed.taskType ? <Icons icon="backArrow" className={styles['back-arrow']} /> : null}
            {parsed.taskType
              ? words['user.header.subcontract']
              : words['user.header.subcontract.taskType']}
          </h1>
        </section>

        {parsed.taskType ? (
          <Form
            initialValues={taskData}
            onSubmit={(values: any) => {
              if (isDraft) {
                taskCreationSub(values)
              } else {
                handleSignTask(values)
              }
            }}
          >
            {({ form, handleSubmit }) => {
              const formState = form.getState()
              return (
                <form onSubmit={handleSubmit} className={styles.form}>
                  {parsed.taskType === '4' ? (
                    <CreateType
                      form={form}
                      history={history}
                      dataForTableDefault={sortDays(dataForTableDefault)}
                      setDataForTableDefault={setDataForTableDefault}
                      formState={formState}
                      taskLoading={taskLoading}
                      files={files}
                      setFiles={setFiles}
                      avatar={avatar}
                      setAvatar={setAvatar}
                      specialization={positions ? positions : []}
                      level={level ? level : [{ value: '1', label: 'Loading...' }]}
                      onSelectFile={onSelectFile}
                      deleteFile={deleteFile}
                    />
                  ) : (
                    <section className={styles['personal-info']}>
                      <h1>{words['user.subcontract.task.newTask']}</h1>
                      <FieldFactory
                        // currentLanguage={currentLanguage}
                        form={form}
                        config={[
                          {
                            items: [
                              {
                                name: 'title',
                                label: words['user.subcontract.task.orderTitle'],
                                required: true,
                                inputWrapperClassName: styles['head-task'],
                                component: () => {
                                  return {
                                    type: 'input',
                                    props: {
                                      variant: 'outlined'
                                    }
                                  }
                                }
                              }
                            ]
                          }
                        ]}
                        words={words}
                      />
                      <div className={styles.rlevel}>
                        <div style={{ width: '55%' }}>
                          <FieldFactory
                            form={form}
                            config={[
                              {
                                style: {
                                  marginBottom: '0px'
                                },
                                items: [
                                  {
                                    name: 'specialization',
                                    label:
                                      words['user.subcontract.task.specializationOfThePerformer'],
                                    required: true,
                                    inputWrapperClassName: styles['specials'],
                                    component: ({ meta }: any) => {
                                      return {
                                        type: 'multiSelect',
                                        props: {
                                          options: positions || [],
                                          isCheckboxesStyle: true,
                                          isMulti: true,
                                          closeMenuOnSelect: false,
                                          hideSelectedOptions: false,
                                          errorMessage: meta.error,
                                          placeholder: words['user.editProfile.notSelected'],
                                          styles: {
                                            placeholder: (provided: CSSProperties) => ({
                                              ...provided,
                                              color: '#333333',
                                              fontSize: '12px',
                                              lineHeight: '14px'
                                            }),
                                            valueContainer: (provided: CSSProperties) => ({
                                              ...provided,
                                              verticalAlign: 'middle',
                                              minHeight: '27px'
                                            }),
                                            container: (provided: CSSProperties) => ({
                                              ...provided,
                                              verticalAlign: 'middle',
                                              height: 'unset'
                                            }),
                                            input: (provided: CSSProperties) => ({
                                              ...provided,
                                              position: 'absolute',
                                              bottom: '0px',
                                              right: 0
                                            })
                                          }
                                        }
                                      }
                                    }
                                  }
                                ]
                              }
                            ]}
                            words={words}
                          />
                        </div>
                        <div style={{ width: '40%' }}>
                          <FieldFactory
                            // currentLanguage={currentLanguage}
                            form={form}
                            config={[
                              {
                                style: {
                                  marginBottom: '0px'
                                },
                                items: [
                                  {
                                    name: 'level',
                                    label: words['user.subcontract.task.performerLevel'],
                                    required: true,
                                    inputWrapperClassName: styles['level'],
                                    component: () => {
                                      return {
                                        type: 'select',
                                        props: {
                                          placeholder: '',
                                          options: level
                                            ? level
                                            : [{ value: '1', label: 'Loading...' }]
                                        }
                                      }
                                    }
                                  }
                                ]
                              }
                            ]}
                            words={words}
                          />
                        </div>
                      </div>
                      <FieldFactory
                        // currentLanguage={currentLanguage}
                        form={form}
                        config={[
                          {
                            items: [
                              {
                                name: 'description',
                                label: words['user.subcontract.task.taskDescription'],
                                required: true,
                                inputWrapperClassName: styles['description-task'],
                                component: () => {
                                  return {
                                    type: 'textarea',
                                    props: {}
                                  }
                                }
                              }
                            ]
                          }
                        ]}
                        words={words}
                      />
                      <FieldFactory
                        // currentLanguage={currentLanguage}
                        form={form}
                        config={[
                          {
                            items: [
                              {
                                name: 'technologies',
                                label: words['user.subcontract.task.technology'],
                                required: true,
                                inputWrapperClassName: styles['technologies'],
                                component: () => {
                                  return {
                                    type: 'textarea',
                                    props: {}
                                  }
                                }
                              }
                            ]
                          }
                        ]}
                        words={words}
                      />
                      <section className={styles['add-files-block']}>
                        <h3>{words['user.subcontract.task.files']}</h3>
                        {files &&
                          files.length > 0 &&
                          files.map((item: any, idx: number) => (
                            <FileView
                              isEdit
                              item={item}
                              idx={idx}
                              deleteFile={deleteFile}
                              key={String(idx)}
                            />
                          ))}
                        <label htmlFor="addFile" className={styles['add-file-arria']}>
                          <label htmlFor="addFile">
                            <Icons icon="add" />
                            <input
                              id="addFile"
                              type="file"
                              // accept="image/*, .pdf"
                              onChange={(e: any) => onSelectFile(e, files, setFiles)}
                            />
                          </label>
                        </label>
                      </section>
                    </section>
                  )}
                  {parsed.taskType && parsed.taskType !== '4' ? (
                    <CreateType
                      form={form}
                      history={history}
                      dataForTableDefault={sortDays(dataForTableDefault)}
                      setDataForTableDefault={setDataForTableDefault}
                      formState={formState}
                      onSelectFile={onSelectFile}
                    />
                  ) : null}
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}
                  >
                    <BaseButton
                      children={words['user.subcontract.cancel']}
                      type="button"
                      className={styles['cancel-btn']}
                      disabled={loading}
                      onClick={() => history.goBack()}
                    />
                    <div style={{ display: 'flex' }}>
                      <BaseButton
                        children={words['user.subcontract.saveDraft']}
                        type="submit"
                        className={styles['cancel-btn']}
                        disabled={loading}
                      />
                      <div className={styles['accept-btn']}>
                        <BaseButton
                          children={words['user.subcontract.signAndPublish']}
                          size="percent"
                          type="submit"
                          disabled={loading}
                          onClick={() => setIsDraft(false)}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              )
            }}
          </Form>
        ) : (
          <>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
              <div>
                {cardArray.map((card: any, i: number) => (
                  <TypeCard
                    index={i}
                    key={i}
                    img={card.img.src}
                    title={card.title}
                    description={card.description}
                    query={card.query}
                    history={history}
                    active={selectedType === i}
                    onCli={() => {
                      setSelectedType(i)
                    }}
                  />
                ))}
              </div>
              <div className={styles['type-description']}>
                <div className={styles['about-type']}>
                  <h2>{cardArray[selectedType].title}</h2>
                  <p>{cardArray[selectedType].description}</p>
                </div>
                <div>
                  <h2 className={styles['block-about']}>{cardArray[selectedType].advantages}</h2>
                  <div className={styles['advantages']}>
                    {cardArray[selectedType].advantagesArray.map((advan, i) => {
                      return (
                        <div key={i}>
                          <Icons icon={advan.icon as TIcon} />
                          <p>{advan.text}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div>
                  <h2 className={styles['block-about']}>
                    {cardArray[selectedType].howItWorks.title}
                  </h2>
                  <div className={styles['how']}>
                    {cardArray[selectedType].howItWorks.step.map((step, i) => {
                      return (
                        <div key={i}>
                          <p className={styles.step}>
                            {words['user.subcontract.task.step'] + (i + 1)}
                          </p>
                          <p>{step}</p>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className={styles['create-btn']}>
                  <BaseButton
                    className={styles.btn}
                    children={words['user.subcontract.createTask']}
                    size="lgs"
                    type="submit"
                    onClick={() =>
                      history.push(
                        `${history.location.pathname}?${queryString.stringify({
                          ...parsed,
                          taskType: cardArray[selectedType].query
                        })}`
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default CreateTask
