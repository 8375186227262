/* eslint-disable no-console */
import { Dispatch } from 'redux'
import { pollingSuccess, setUserAvatar, fillUserData } from './Login.actions'
import { API, api } from 'services/api'
import { PERIOD_OF_POLLING } from 'globalConfigs'
import toastr from 'utils/toastr'
import { AuthException } from 'elements/AuthRoute/AuhtRoute.constants'
import { isAdmin, isSuperAdmin } from 'utils/user'
import history from 'utils/history'
import { ADMIN_PATH, USER_PATH } from 'elements/App/App.constants'
import { TOASTR_DEFAULT_MESSAGES } from 'utils/toastrMessages'

export const login = (role: string, logout: any) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const { data } = await api.post(API.LOGIN)
    const result = data && data.payload && data.payload.result
    if (result) {
      dispatch(fillUserData(result))
    }
    if (!result) {
      logout()
    }
    const successPath = isSuperAdmin(role) || isAdmin(role) ? ADMIN_PATH : USER_PATH
    history.push(successPath)
  } catch (err) {
    const errorResponse = (err as any).response

    if (errorResponse) {
      const textErr = errorResponse.message || TOASTR_DEFAULT_MESSAGES.NO_PERMISSIONS
      toastr('error', textErr)
      logout()
    }
  }
}

export const getCurrentUserData = () => async (dispatch: Dispatch): Promise<void> => {
  try {
    const { data } = await api.get(API.USER_DATA)

    dispatch(fillUserData(data.payload.result))
  } catch (err) {
    const errorResponse = (err as any).response
    if (!errorResponse) {
    } else {
      if (errorResponse.data.error === AuthException.FORBIDDEN) {
        toastr('error', TOASTR_DEFAULT_MESSAGES.NO_PERMISSIONS)
        return
      }
      toastr('error', errorResponse)
    }
  }
}

let timer: any
export const getTimer = () => timer

export const pulling: any = () => async (dispatch: Dispatch) => {
  clearTimeout(timer)
  try {
    const { data } = await api.get(`${API.CLIENT}${API.POLLING}`)
    dispatch(pollingSuccess(data.payload))

    timer = setTimeout(() => {
      pulling()(dispatch)
    }, PERIOD_OF_POLLING)
  } catch (err) {
    // dispatch(pollingError(err))
    console.error(err)
  }
}

export const getUserPhoto = (id: number) => async (dispatch: Dispatch): Promise<void> => {
  try {
    const { data } = await api.get(`${API.USER_PHOTO}/${id}`)
    dispatch(setUserAvatar(data.payload))
  } catch (error) {
    console.error(error)
  }
}

// export const signup = (body: Record<string, any>) => async (dispatch: Dispatch): Promise<void> => {
//   try {
//     const { data } = await api.post(API.SIGNUP, body)
//     dispatch(signInTokenSuccess(data.payload.result))
//   } catch (err) {
//     const errorResponse = (err as any).response
//     if (!errorResponse) {
//       console.log(err)
//     } else {
//       toastr('error', errorResponse.data.message)
//     }
//   }
// }
