import { Column } from 'react-table'
import React, { useEffect, useMemo } from 'react'
import Checkbox from 'components/UiKit/Checkbox/Checkbox'
import { TChecked, TInvoice } from '../ProjectInvoices.model'
import { useSelector } from 'react-redux'
import { TState } from 'store'

export type TEntrepreneursColumns = {
  project: string
  invoiceNumber: string
  durationFromDate: Date
  durationToDate: Date
  team: number
  totalAmount: number
  formationDate: Date
  paymentDate: Date
  discount: string
  discountAmount: string
  check: boolean
  buttons: React.ReactNode
  download: any
}

type TColumnsManagerOptions = {
  role: number
  invoices: TInvoice[]
  checkedInvoices: TChecked
  setCheckedInvoices: (value: TChecked) => void
  isAllChecked: boolean
  setIsAllChecked: (value: boolean) => void
  currentCheckedInvoices: TInvoice[]
}
type TColumnsManager = (options: TColumnsManagerOptions) => Array<Column<TEntrepreneursColumns>>

export const Columns: TColumnsManager = ({
  invoices,
  checkedInvoices,
  setCheckedInvoices,
  isAllChecked,
  setIsAllChecked,
  currentCheckedInvoices
}) => {
  const words = useSelector((state: TState) => state.global.language.words)

  const checkedMemo = useMemo(() => {
    if (isAllChecked) {
      return invoices.reduce((accumulator: TChecked, currentItem: TInvoice) => {
        return {
          ...accumulator,
          [currentItem.number + currentItem.id]: {
            ...currentItem,
            checked: true
          }
        }
      }, {})
    } else if (currentCheckedInvoices.length < invoices.length) {
      const checkedUsers = Object.values(checkedInvoices)
      const filteredCheckedUsers = checkedUsers
        .filter(user => user && user.checked === true)
        .map(user => ({ ...user }))

      return filteredCheckedUsers.reduce((accumulator: TChecked, currentItem: TInvoice) => {
        return {
          ...accumulator,
          [currentItem.number + currentItem.id]: {
            ...currentItem,
            checked: true
          }
        }
      }, {})
    } else {
      return {}
    }
  }, [isAllChecked, invoices, checkedInvoices, currentCheckedInvoices])

  const handleCheckboxChange = () => {
    setIsAllChecked(!isAllChecked)
  }

  useEffect(() => {
    setCheckedInvoices(checkedMemo)
  }, [isAllChecked, invoices])

  const columnsManager: Array<Column<TEntrepreneursColumns>> = [
    {
      Header: () => {
        return <Checkbox name="check" checked={isAllChecked} onChange={handleCheckboxChange} />
      },
      id: 'check',
      accessor: d => d.check,
      width: 150
    },
    {
      Header: words['projectInvoices.project'] || 'Проект',
      id: 'project',
      accessor: d => d.project,
      width: 600
    },
    {
      Header: words['projectInvoices.invoiceNumber'],
      id: 'invoiceNumber',
      accessor: d => d.invoiceNumber,
      width: 600
    },
    {
      Header: words['projects.invoices.duration'],
      id: 'durationFromDate',
      accessor: d => d.durationFromDate,
      width: 600
    },
    {
      Header: words['projectInvoices.numbersOfTeam'] || 'Кол-во человек',
      id: 'team',
      accessor: d => d.team,
      width: 600
    },
    {
      Header: words['projects.invoices.totalAmount'],
      id: 'totalAmount',
      accessor: d => d.totalAmount,
      width: 600
    },
    {
      Header: words['projects.invoices.formationDate'],
      id: 'formationDate',
      accessor: d => d.formationDate,
      width: 600
    },
    {
      Header: words['projects.invoices.paymentDate'],
      id: 'paymentDate',
      accessor: d => d.paymentDate,
      width: 600
    },
    {
      Header: words['projects.invoices.discount'],
      id: 'discount',
      accessor: d => d.discount,
      width: 600
    },
    {
      Header: words['projects.invoices.discountAmount'],
      id: 'discountAmount',
      accessor: d => d.discountAmount,
      width: 600
    },
    {
      Header: '',
      id: 'download',
      accessor: d => d.download,
      width: 200
    },
    {
      Header: '',
      id: 'buttons',
      accessor: d => d.buttons,
      width: 200
    }
  ]

  return columnsManager
}
